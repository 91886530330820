/** @format */

import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {FixedSizeList} from 'react-window';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DriverForm from '../DriverForm/DriverForm';
import Button from '@material-ui/core/Button';
import {Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: 410,
  },
  addDriverItem: {
    margin: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function renderRow(props) {
  const {
    index,
    style,
    data: {drivers, onButtonClick},
  } = props;
  return (
    <ListItem button style={style} key={index} onClick={() => onButtonClick({rowId: index})}>
      <ListItemText primary={drivers[index].name} />
    </ListItem>
  );
}

renderRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
};

export default function VirtualizedList(props) {
  const inputEl = useRef(null);
  const classes = useStyles();
  const [activeDriver, setActiveDriver] = useState(0);
  const [drivers, setdrivers] = useState([...props.drivers]);

  const onButtonClick = ({rowId}) => {
    setActiveDriver(rowId);
    // `current` points to the mounted text input element
    //inputEl.current.scrollToItem(16);
  };

  React.useEffect(() => {
    setdrivers([...props.drivers]);
  }, [props.drivers]);

  const addDriver = () => {
    setdrivers([
      {
        assetId: null,
        companyId: null,
        email: '',
        id: null,
        name: 'Nový řidič',
        phone: '',
        rfid: '',
        tachoCard: '',
      },
      ...drivers,
    ]);
    setActiveDriver(0);
  };

  if (drivers.length === 0) {
    addDriver();
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={10} sm={3} lg={2}>
          <Paper className={classes.paper}>
            <Typography>Řidiči:</Typography>
            <FixedSizeList
              ref={inputEl}
              height={360}
              width={'100%'}
              itemSize={46}
              itemCount={drivers.length}
              itemData={{drivers: drivers, onButtonClick}}
              scrollToItem={10}>
              {renderRow}
            </FixedSizeList>
          </Paper>
        </Grid>
        <Grid item xs={11} sm={7} lg={5}>
          <Paper className={classes.paper}>
            <DriverForm driver={drivers[activeDriver]} drivers={drivers} />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={10} sm={3} lg={2}>
          <div className={classes.addDriverItem}>
            <Button variant="contained" color="secondary" onClick={addDriver}>
              Přidat dalšího řidiče
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
