/** @format */

import React, {Component} from 'react';
import {Logo} from '../components/Icons';
import Btn from '../components/Btn';
import {Link} from 'react-router-dom';
import {loginHandler} from '../apollo/client';

export class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: false,
      loading: false,
      key: Math.random(),
    };
  }

  handleChange(event) {
    const {name, value} = event.target;
    this.setState({[name]: value});
  }

  handleClick() {
    this.setState({key: Math.random(), error: false, loading: true});
    const {email, password} = this.state;
    loginHandler.login(email, password).then(ok => {
      if (ok) {
        this.props.onLogin();
      } else {
        this.setState({error: true, loading: false});
      }
    });
  }

  hideError(event) {
    event.preventDefault();
    this.setState({email: '', password: '', error: false});
  }

  // should add listener to handle enter keypress
  handleKeyPress(event) {
    if (event && event.code === 'Enter') {
      this.handleClick();
    }
  }

  componentDidMount() {
    this.listener = this.handleKeyPress.bind(this);
    document.addEventListener('keypress', this.listener);
  }
  // should remove listener to handle enter and space keypress
  componentWillUnmount() {
    document.removeEventListener('keypress', this.listener);
  }

  render() {
    const {email, password, error, loading} = this.state;

    return (
      <div className="login-wrapper">
        <Logo />
        <div
          key={this.state.key}
          className={'login-form center-block' + (error ? ' shake login-form--border-error' : '')}>
          <h1 className="login-form--h1">Přihlášení</h1>
          <div className="input-field">
            <input
              id="email"
              name="email"
              className="input--white"
              value={email}
              type="email"
              style={{width: '100%'}}
              onChange={this.handleChange.bind(this)}
              required
            />
            <label htmlFor="email">Email</label>
            <label htmlFor="email" className="error">
              zadejte platný email
            </label>
          </div>

          <div className="input-field">
            <input
              id="password"
              name="password"
              className="input--white"
              value={password}
              style={{width: '100%'}}
              type="password"
              onChange={this.handleChange.bind(this)}
              required
            />
            <label htmlFor="password">Heslo</label>
          </div>
          <Btn handleClick={this.handleClick.bind(this)} btnName="Přihlásit" loading={loading} />

          <br />
          <br />
          <Link className="login-form--a" to={`./password-reset`}>
            Zapomenuté heslo
          </Link>
        </div>
        {error ? (
          <div
            className="login-form login-form--error-box center-block login-form--border-error"
            onClick={this.hideError.bind(this)}>
            Údaje nejsou platné!
          </div>
        ) : null}
      </div>
    );
  }
}
