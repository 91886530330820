/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withApollo } from '@apollo/client/react/hoc';
import GroupForm from './Group/GroupForm';
import Container from '@material-ui/core/Container';
import { groupsEnabledVar } from '../../../apollo/client';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
  },
});

class GroupSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enabledGroups: (() => (localStorage.getItem('enabledGroups') === 'true' ? true : false))(),
    };
  }

  async handleEnableGroups() {
    await localStorage.setItem('enabledGroups', !this.state.enabledGroups);
    groupsEnabledVar(!this.state.enabledGroups);
    //await this.props.client.mutate({mutation: GROUPS, variables: {enabledGroups: !this.state.enabledGroups}});
    this.setState({ enabledGroups: !this.state.enabledGroups });
  }

  render() {
    return (
      <Container>
        <FormControl component="fieldset">
          <FormGroup aria-label="position">
            <FormControlLabel
              value="start"
              control={<Switch color="primary" />}
              label="Aktivovat skupiny"
              labelPlacement="start"
              checked={this.state.enabledGroups}
              onClick={this.handleEnableGroups.bind(this)}
            />
          </FormGroup>
        </FormControl>
        {this.state.enabledGroups ? <GroupForm /> : ''}
      </Container>
    );
  }
}

GroupSetting.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withApollo(GroupSetting));
