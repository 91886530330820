/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {DISABLE_ICONS, DISABLE_STOPS} from '../../../apollo/mutation/CardMutation';
import {withApollo} from '@apollo/client/react/hoc';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
  },
});

class MapSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableIcons: (() => (localStorage.getItem('disableIcons') === 'true' ? true : false))(),
      disableStops: (() => (localStorage.getItem('disableStops') === 'true' ? true : false))(),
    };
  }

  async handleDisableIcons() {
    await localStorage.setItem('disableIcons', !this.state.disableIcons);
    await this.props.client.mutate({mutation: DISABLE_ICONS, variables: {disableIcons: !this.state.disableIcons}});
    this.setState({disableIcons: !this.state.disableIcons});
  }

  async handleDisableStops() {
    await localStorage.setItem('disableStops', !this.state.disableStops);
    await this.props.client.mutate({mutation: DISABLE_STOPS, variables: {disableStops: !this.state.disableStops}});
    this.setState({disableStops: !this.state.disableStops});
  }

  render() {
    return (
      <div style={{display: 'flex', flexWrap: 'wrap'}}>
        <FormControl component="fieldset">
          <FormGroup aria-label="position">
            <FormControlLabel
              value="start"
              control={<Switch color="primary" />}
              label="Zobrazovat ikony"
              labelPlacement="start"
              checked={!this.state.disableIcons}
              onClick={this.handleDisableIcons.bind(this)}
            />
            <FormControlLabel
              value="start"
              control={<Switch color="primary" />}
              label="Zobrazovat zastávky"
              labelPlacement="start"
              checked={!this.state.disableStops}
              onClick={this.handleDisableStops.bind(this)}
            />
          </FormGroup>
        </FormControl>
      </div>
    );
  }
}

MapSetting.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withApollo(MapSetting));
