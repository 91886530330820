/** @format */

import React from 'react';
import DateForm from './BodyComponents/DateForm';
import Button from '@material-ui/core/Button';
import { withApollo } from '@apollo/client/react/hoc';
import { LOG_BOOK_TRIPS } from '../../../apollo/query/LogBook';
import DayBlock from './BodyComponents/DayBlock';
import LocalCafe from '@material-ui/icons/LocalCafe';
import LocalGasStation from '@material-ui/icons/LocalGasStation';
import AccessTime from '@material-ui/icons/AccessTime';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { MUTATE_TRIP_TYPE } from '../../../apollo/mutation/LogBookMutation';
import 'moment/locale/cs';
import moment from 'moment';
import { DriversDialog } from './DriversDialog/DriversDialog';


moment.locale('cs');

const styles = theme => ({
  root: { margin: theme.spacing(1) },
  buttonItem: {
    display: 'flex',
    justifyContent: 'center',
  },
});

class Body extends React.Component {
  state = {
    data: { logBookTrips: [] },
    loading: false,
    checkedTripRows: {}, // keys in format startUtime.stopUtime
    driversDialogOpen: false,
  };

  handleCheckTripRow = ({ e, arrayStartStopUtime, forceState }) => {
    e.preventDefault();
    e.stopPropagation();

    let newCheckedTripRows = { ...this.state.checkedTripRows };

    if (arrayStartStopUtime.length > 0) {
      for (let i = 0; i < arrayStartStopUtime.length; i++) {
        let { startUtime, stopUtime } = arrayStartStopUtime[i];

        let newState = !newCheckedTripRows[`${startUtime}.${stopUtime}`];

        if (forceState === false || forceState === true) {
          newState = forceState;
        }

        newCheckedTripRows[`${startUtime}.${stopUtime}`] = newState;
      }
    }

    this.setState({ checkedTripRows: newCheckedTripRows });
  };

  handleDate(start, stop) {
    this._lastStart = start;
    this._lastStop = stop;

    this.setState({ loading: true });

    if (this.querySubscription && this.querySubscription.unsubscribe) this.querySubscription.unsubscribe();

    if (start > 0 && stop > 0 && stop > start) {
      this.querySubscription = this.props.client
        .watchQuery({
          query: LOG_BOOK_TRIPS,
          fetchPolicy: 'network-only',
          pollInterval: 60000,
          variables: {
            assetId: this.props.assetId,
            startUtime: parseInt(start, 10),
            stopUtime: parseInt(stop, 10),
          },
        })

        .subscribe(({ data }) => {
          this.setState({ data, loading: false });
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assetId !== this.props.assetId) {
      this.handleDate(this._lastStart, this._lastStop);
    }
  }

  handleButtonAllPrivate = () => {
    const entries = Object.entries(this.state.checkedTripRows);

    for (const [startStopUtime, checked] of entries) {
      let startStopUtimeSplitted = startStopUtime.split('.');

      if (checked === true) {
        this.props.client.mutate({
          mutation: MUTATE_TRIP_TYPE,
          variables: {
            tripType: 'soukromá',
            assetId: this.props.assetId,
            startUtime: parseInt(startStopUtimeSplitted[0]),
            stopUtime: parseInt(startStopUtimeSplitted[1]),
            refetchStartUtime: parseInt(startStopUtimeSplitted[0]),
            refetchStopUtime: parseInt(startStopUtimeSplitted[1]),
          },
        });
      }
    }
  };

  handleButtonSetDriver = () => {
    /*  const entries = Object.entries(this.state.checkedTripRows);
  
      for (const [startStopUtime, checked] of entries) {
        let startStopUtimeSplitted = startStopUtime.split('.');
  
        if (checked === true) {
          this.props.client.mutate({
            mutation: MUTATE_TRIP_TYPE,
            variables: {
              tripType: 'soukromá',
              assetId: this.props.assetId,
              startUtime: parseInt(startStopUtimeSplitted[0]),
              stopUtime: parseInt(startStopUtimeSplitted[1]),
              refetchStartUtime: parseInt(startStopUtimeSplitted[0]),
              refetchStopUtime: parseInt(startStopUtimeSplitted[1]),
            },
          });
        }
      }*/
    this.setState({ driversDialogOpen: !this.state.driversDialogOpen });

  };

  handleButtonAllFiremni = () => {
    const entries = Object.entries(this.state.checkedTripRows);

    for (const [startStopUtime, checked] of entries) {
      let startStopUtimeSplitted = startStopUtime.split('.');

      if (checked === true) {
        this.props.client.mutate({
          mutation: MUTATE_TRIP_TYPE,
          variables: {
            tripType: 'firemní',
            assetId: this.props.assetId,
            startUtime: parseInt(startStopUtimeSplitted[0]),
            stopUtime: parseInt(startStopUtimeSplitted[1]),
            refetchStartUtime: parseInt(startStopUtimeSplitted[0]),
            refetchStopUtime: parseInt(startStopUtimeSplitted[1]),
          },
        });
      }
    }
  };

  render() {
    let timeInRun = 0;
    let totalDistance = 0;
    let totalIdle = 0;
    let { pathname } = this.props.location;
    let { classes } = this.props;

    if (this.state.data.logBookTrips.length > 0) {
      this.state.data.logBookTrips.map(x => {
        timeInRun += x.stopUtime - x.startUtime;
        totalDistance += Number(x.distance);
        totalIdle += Number(x.idle);
        return null;
      });
    }

    //convert to hours
    timeInRun = this.convertToHours(timeInRun);
    totalIdle = this.convertToHours(totalIdle);

    let daysBox;
    // reset constuption data
    let consuptionTotal = 0;
    let consuptionDistance = 0;

    if (this.state.loading) {
      daysBox = <div className="log-book__day-section log-book__day-section--error">probíhá načítání dat...</div>;
    } else if (this.state.data.logBookTrips && this.state.data.logBookTrips.length > 0) {
      let menu = [];

      if (this.state.data.logBookTrips[0].startLoc === null && this.state.data.logBookTrips.length === 1) {
        //no trips in this date
        daysBox = (
          <div className="log-book__day-section log-book__day-section--error">Bez provozu v tomto rozsahu datumů.</div>
        );
      } else {
        //there are some trips in reply from backend

        this.state.data.logBookTrips.map(x => {
          const momentStart = moment.unix(x.startUtime);

          var index = menu.findIndex(x => x.date === momentStart.format('MM-DD-YYYY'));

          if (index === -1) {
            index =
              menu.push({
                date: momentStart.format('MM-DD-YYYY'),
                trips: [],
                moment: momentStart,
              }) - 1;
          }

          menu[index].trips.push(x);
          return null;
        });

        let changeSelectedRowsButtons = null;

        const values = Object.values(this.state.checkedTripRows);
        for (let i = 0; i < values.length; i++) {
          if (values[i] === true) {
            changeSelectedRowsButtons = (
              <Grid container spacing={2} direction="row" justify="space-around" alignContent="space-around">
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.root}
                    onClick={this.handleButtonAllPrivate}>
                    Změnit vše na soukromé
                  </Button>
                </Grid>
                <Grid item xs={4} className={classes.buttonItem}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.root}
                    onClick={this.handleButtonAllFiremni}>
                    Změnit vše na firemní
                  </Button>
                </Grid>
                <Grid item xs={4} className={classes.buttonItem}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.root}
                    onClick={this.handleButtonSetDriver}>
                    Nastavit řidiče
                  </Button>
                </Grid>
              </Grid>
            );
          }
        }

        daysBox = (
          <div className="log-book__day-section">
            {changeSelectedRowsButtons}
            {menu.map((x, key) => {
              if (x.trips && x.trips.length > 0) {
                for (var i = 0, len = x.trips.length; i < len; i++) {
                  let consuptionFloat = parseFloat(x.trips[i].consuption);
                  let distanceFloat = parseFloat(x.trips[i].distance);

                  if (consuptionFloat > 0 && distanceFloat > 0) {
                    consuptionDistance += distanceFloat;
                    consuptionTotal += (distanceFloat / 100) * consuptionFloat;
                  }
                }
              }

              return (
                <DayBlock
                  assetId={this.props.assetId}
                  logBookWidth={this.props.logBookWidth}
                  key={key}
                  moment={x.moment}
                  trips={x.trips}
                  closeAllOnSmall={this.props.closeAllOnSmall}
                  lastStartUtime={this._lastStart}
                  lastStopUtime={this._lastStop}
                  allTrips={this.state.data}
                  handleCheckTripRow={this.handleCheckTripRow}
                  checkedTripRows={this.state.checkedTripRows}
                  assetDrivers={this.props.assetDrivers}
                />
              );
            })}
          </div>
        );
      }
    }

    if (pathname !== '/logBook') {
      return null;
    }

    return (
      <div className="log-book" style={{ overflowY: 'scroll' }}>
        <DateForm handleDate={this.handleDate.bind(this)} assetId={this.props.assetId} />
        <div>
          <div className="log-book__activity-summary-box">
            <div className="log-book__activity-item log-book__activity-item--1">
              <div className="line-1">
                <AccessTime className="icon" />
                <p>Počet jízd</p>
              </div>
              <div className="line-2">
                {this.state.data.logBookTrips.length > 0 && this.state.data.logBookTrips[0].startLoc != null
                  ? this.state.data.logBookTrips.length
                  : '0'}
              </div>
            </div>
            <div className="log-book__activity-item log-book__activity-item--2">
              <div className="line-1">
                <AccessTime className="icon" />
                <p>Najeto</p>
              </div>
              <div className="line-2">{Number(totalDistance / 1000).toFixed(1)} km</div>
            </div>
            <div className="log-book__activity-item log-book__activity-item--3">
              <div className="line-1">
                <AccessTime className="icon" />
                <p>Doba provozu</p>
              </div>
              <div className="line-2">{timeInRun}</div>
            </div>
            <div className="log-book__activity-item log-book__activity-item--4">
              <div className="line-1">
                <LocalGasStation className="icon" />
                <p>Spotřeba</p>
              </div>
              <div className="line-2">
                {consuptionTotal > 0 ? Math.round((consuptionTotal / consuptionDistance) * 100 * 100) / 100 : 'NaN'}
                <span className="units"> /100km</span>
              </div>
            </div>
            <div className="log-book__activity-item log-book__activity-item--5">
              <div className="line-1">
                <LocalCafe className="icon" />
                <p>Prostoj</p>
              </div>
              <div className="line-2">{totalIdle}</div>
            </div>
          </div>
          {daysBox}
        </div>
        {this.state.driversDialogOpen && <DriversDialog open={this.state.driversDialogOpen} onClose={this.handleButtonSetDriver} assetId={this.props.assetId} checkedTripRows={this.state.checkedTripRows} />}
      </div>
    );
  }

  convertToHours(tripSeconds) {
    let tripMins = Math.floor(tripSeconds / 60);
    let tripHours = '';

    if (tripMins > 60) {
      tripHours = Math.floor(tripMins / 60);
      tripMins -= tripHours * 60;
    }

    return (tripHours !== '' && tripHours !== 0 ? `${tripHours}h ` : '') + tripMins + 'min';
  }

  componentWillUnmount() {
    if (this.querySubscription && this.querySubscription.unsubscribe) {
      this.querySubscription.unsubscribe();
      this.querySubscription = null;
    }
  }
}

export default React.memo(withRouter(withApollo(withStyles(styles)(Body))));
