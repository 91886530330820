/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    flex: 1,
    backgroundColor: '#eeee',
    borderRadius: '.3rem',
  },
});

function DatePickers(props) {
  const {classes} = props;

  return (
    <form className={classes.container + ' input-box'} noValidate>
      <TextField
        id={props.name}
        name={props.name}
        type="datetime-local"
        value={props.defaultValue}
        className={classes.textField + ' input-box__field'}
        onChange={props.handleDatePickerClick}
      />
    </form>
  );
}

DatePickers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DatePickers);
