/** @format */

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DriversAlert({resolve, reject, open, text, heading}) {
  return (
    <Dialog
      open={open}
      onClose={reject}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{heading}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={resolve} color="primary">
          Potvrdit
        </Button>
        <Button onClick={reject} color="primary" autoFocus>
          Storno
        </Button>
      </DialogActions>
    </Dialog>
  );
}
