/** @format */

import React, {Component} from 'react';

class Btn extends Component {
  preventDefaults(event) {
    event.preventDefault();
    this.props.handleClick();
  }

  render() {
    const {btnName, loading} = this.props;
    return (
      <div role="button" className="btn" onClick={this.preventDefaults.bind(this)}>
        <span className={loading === true ? 'btn__text btn__text--transparent' : 'btn__text'}>{btnName}</span>
        {loading === true ? <span className="btn__loader-text">{btnName}</span> : ''}
        {loading === true ? <span className="btn__loader" /> : ''}
      </div>
    );
  }
}

export default Btn;
