/** @format */

import React from 'react';
import ReactDOM from 'react-dom';
import { initAssets } from './requests/initAssetsQuery.js';
import AssetLogic from './asset/AssetLogic';
import Progress from '../components/Progress';
import { ALL_ASSETS_MEM } from '../apollo/query/AssetQuery';
import cloneDeep from 'lodash/cloneDeep';

/**

 * @file GPSON main Asset class Logic

 * @copyright Filip Kroca 2018 filipkroca@gmail.com

 */

class Logic {
  constructor({ client }) {
    this.client = client;
    this.init();
    ReactDOM.render(<Progress trim="loading" />, document.getElementById('progress-wrapper'));
  }

  async init() {
    this.assetArray = [];
    const that = this;

    // LOAD data from GraphQL
    let data = cloneDeep(await initAssets());
    //

    // sort by name
    data?.sort(function (a, b) {
      if (a && a.name && b && b.name) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      }
      // names must be equal
      return 0;
    });

    const iconVisible = localStorage.getItem('disableIcons') === 'true' ? false : true;
    const stopsVisible = localStorage.getItem('disableStops') === 'true' ? false : true;
    for (let i = 0; i < data?.length; i++) {
      data[i].__typename = 'AssetInMem';
      data[i].markerVisible = true;
      data[i].iconVisible = iconVisible;
      data[i].stopsVisible = stopsVisible;
      data[i].trigged = false;
    }

    await this.client.writeQuery({ query: ALL_ASSETS_MEM, data: { assetsInMem: data } });

    // create object for every asset
    for (let i = 0; i < data?.length; i++) {
      that.assetArray.push(new AssetLogic({ data: data[i], client: this.client }));
    }

    //ReactDOM.unmountComponentAtNode(document.getElementById('progress-wrapper'));
    //ReactDOM.render(<Progress trim="loading" />, document.getElementById('progress-wrapper'));
    setTimeout(() => ReactDOM.unmountComponentAtNode(document.getElementById('progress-wrapper')), 100);

    document.addEventListener('resume', this.handleResume.bind(this), false);
    // The pause event fires when the native platform puts the application into
    // the background, typically when the user switches to a different
    // application.
    document.addEventListener('pause', this.handlePause.bind(this), false);

    return null;
  }

  // handle cordova resume from background (user went background by home buttton
  // etc. and now is back)
  handleResume() {
    let that = this;
    setTimeout(async function () {
      // REFETCH data from GraphQL
      let data = [...(await initAssets({ client: that.client }))];
      //

      let oldData = await that.client.query({ query: ALL_ASSETS_MEM });

      // sort by name
      data.sort(function (a, b) {
        if (a && a.name && b && b.name) {
          var nameA = a.name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        }
        // names must be equal
        return 0;
      });

      for (let i = 0; i < data.length; i++) {
        data[i].__typename = 'AssetInMem';

        let index = oldData.data.assetsInMem.findIndex(x => x.id === data[i].id);
        if (index > -1) {
          let newData = { ...oldData.data.assetsInMem[index], ...data[i] };
          data[i] = newData;
        }
      }

      that.client.writeQuery({ query: ALL_ASSETS_MEM, data: { assetsInMem: data } });

      data.map(x => {
        // find existing object and do update
        let index = that.assetArray.findIndex(y => y.id === x.id);
        if (index === -1) {
          that.assetArray.push(new AssetLogic({ data: x, client: that.client }));
          return null;
        }
        that.assetArray[index].reSubscribe();
        return null;
      });
    }, 0);
  }

  // The pause event fires when the native platform puts the application into
  // the background, typically when the user switches to a different
  // application.
  handlePause() {
    let that = this;
    setTimeout(async function () {
      // stop all subscriptions
      that.assetArray.map(x => {
        if (x.unsubscribe) x.unsubscribe();
        return null;
      });
    }, 0);
  }

  // this is needed when main is component will unmout
  unsubscribe() {
    this.assetArray.map(x => {
      if (x.unsubscribe) x.unsubscribe();
      return null;
    });
    this.assetArray = [];
  }
}

export default Logic;
