/** @format */

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/performance';

var firebaseConfig = {
  apiKey: 'AIzaSyBUIuPJNXopioYyyB-IkS-wOROfwzW3LsA',
  authDomain: 'gpson-176022.firebaseapp.com',
  databaseURL: 'https://gpson-176022.firebaseio.com',
  projectId: 'gpson-176022',
  storageBucket: 'gpson-176022.appspot.com',
  messagingSenderId: '421033511233',
  appId: '1:421033511233:web:ddde583c0723a683f639c0',
  measurementId: 'G-QNHRM0LMTS',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.performance();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
