/** @format */

import { gql } from '@apollo/client';

const GROUPS = gql`
  query GROUPS {
    groups {
      name
      assets
      icon
      __typename
    }
  }
`;

const MUTATE_GROUPS = gql`
  mutation MUTATE_GROUPS($groupsInput: [GroupsInput!]) {
    mutateGroups(groupsInput: $groupsInput) {
      name
      icon
      assets
    }
  }
`;

export { GROUPS, MUTATE_GROUPS };
