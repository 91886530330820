/** @format */

import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextFieldWithSaveButton from '../../../../components/TextFieldWithSaveButton';
import { DRIVER_MUTATION } from '../../../../apollo/mutation/DriverMutation';
import { useMutation } from '@apollo/client';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { ALL_ASSETS_MEM_LIGHT } from '../../../../apollo/query/AssetQuery';
import { Query } from '@apollo/client/react/components';
import DriverAlert from '../DriverAlert/DriverAlert';
import { ASSET_DRIVERS } from '../../../../apollo/query/AssetDriverQuery';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import WorkingHoursForm from '../WorkingHoursForm/WorkingHoursForm';
import client from '../../../../apollo/client';
import Places from '../Places/Places';

const useStyles = makeStyles(theme => ({
  h4: {
    paddingBottom: theme.spacing(2),
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  rowFabDelete: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  rowItem: {
    marginRight: theme.spacing(2),
  },
  rowItemRight: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
    textAlign: 'right',
  },
  fabDelete: {
    margin: theme.spacing(1),
    alignSelf: 'flex-end',
    flexDirection: 'row',
    color: 'white',
  },
}));

const DriverForm = props => {
  const [mutateDriver] = useMutation(DRIVER_MUTATION, { client });
  const classes = useStyles();
  const [alert, setAlert] = useState({ open: false, resolve: null, reject: null, text: null, heading: null });
  const [workingHoursFormOpen, openWorkingHoursForm] = useState(false);
  const [placesOpen, openPlaces] = useState(false);

  let { driver } = props;
  let { drivers } = props;

  const mutateDriverHandler = (property, newValue) => {
    let newDriver = { ...driver };
    delete newDriver.companyId;
    delete newDriver.__typename;

    newDriver[property] = newValue;
    newDriver.id ? (newDriver.action = 'update') : (newDriver.action = 'insert');

    mutateDriver({
      variables: { assetDriverInput: newDriver },
      refetchQueries: [{ query: ASSET_DRIVERS }],
    });
  };

  const deleteDriverHandler = async (property, newValue) => {
    await new Promise((resolve, reject) => {
      setAlert({
        open: true,
        resolve,
        reject,
        text: `Opravdu chcete smazat řidiče ${driver.name}?`,
        heading: 'Akce je nevratná!',
      });
    })
      .then(() => {
        let newDriver = { ...driver };
        delete newDriver.companyId;
        delete newDriver.__typename;

        newDriver[property] = newValue;
        newDriver.action = 'delete';
        mutateDriver({
          variables: { assetDriverInput: newDriver },
          refetchQueries: [{ query: ASSET_DRIVERS }],
        });
      })
      .catch(() => {
        // promt decided
        return null;
      });
    setAlert({ open: false, resolve: null, reject: null, text: null, heading: null });
  };

  const handleDriverAssetIdChange = async event => {
    // open alert modal
    await new Promise((resolve, reject) => {
      setAlert({
        open: true,
        resolve,
        reject,
        text: 'Potvrzení této akce odstraní původního řidiče z dané jednotky!',
        heading: 'Opravdu chcete přiřadit řidiče?',
      });
    })
      .then(() => {
        let newDriver = { ...driver };
        delete newDriver.companyId;
        delete newDriver.__typename;

        newDriver.assetId = event.target.value;

        newDriver.action = 'update';
        mutateDriver({ variables: { assetDriverInput: newDriver } });
      })
      .catch(() => {
        // promt decided
        return null;
      });
    setAlert({ open: false, resolve: null, reject: null, text: null, heading: null });
  };

  return (
    <React.Fragment>
      <DriverAlert {...alert} />
      {workingHoursFormOpen ? (
        <WorkingHoursForm open={workingHoursFormOpen} handleClose={() => openWorkingHoursForm(false)} driver={driver} />
      ) : null}
      {placesOpen ? <Places open={placesOpen} handleClose={() => openPlaces(false)} driver={driver} /> : null}
      <Typography variant="h4" component="h4" className={classes.h4}>
        Karta řidiče {driver.name}
      </Typography>
      <form className={classes.root} noValidate autoComplete="off">
        <div className={classes.row}>
          <div className={classes.rowItem}>Jméno:</div>
          <div className={classes.rowItem}>
            <TextFieldWithSaveButton
              key={driver.name + driver.id}
              value={driver.name}
              callBack={mutateDriverHandler.bind(null, 'name')}
              label="Jméno"
            />
          </div>
          {props.driver && props.driver.id ? (
            <div className={classes.rowItemRight}>
              <Button variant="contained" color="primary" onClick={() => openWorkingHoursForm(true)}>
                Pracovní doba
              </Button>
            </div>
          ) : null}
        </div>
        <div className={classes.row}>
          <div className={classes.rowItem}>Email:</div>
          <div className={classes.rowItem}>
            <TextFieldWithSaveButton
              value={driver.email}
              callBack={mutateDriverHandler.bind(null, 'email')}
              label="Email"
            />
          </div>
          {props.driver && props.driver.id ? (
            <div className={classes.rowItemRight}>
              <Button variant="contained" color="primary" onClick={() => openPlaces(true)}>
                Známá místa
              </Button>
            </div>
          ) : null}
        </div>
        <div className={classes.row}>
          <div className={classes.rowItem}>Telefonní číslo:</div>
          <div className={classes.rowItem}>
            <TextFieldWithSaveButton
              value={driver.phone}
              callBack={mutateDriverHandler.bind(null, 'phone')}
              label="Telefonní číslo"
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.rowItem}>Číslo karty tachografu:</div>
          <div className={classes.rowItem}>
            <TextFieldWithSaveButton
              value={driver.tachoCard ? driver.tachoCard : ''}
              callBack={mutateDriverHandler.bind(null, 'tachoCard')}
              label="Číslo karty tachografu"
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.rowItem}>Číslo RFID čipu:</div>
          <div className={classes.rowItem}>
            <TextFieldWithSaveButton
              value={driver.rfid ? driver.rfid : ''}
              callBack={mutateDriverHandler.bind(null, 'rfid')}
              label="Číslo RFID čipu"
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.rowItem}>Přiřazení:</div>
          <div className={classes.rowItem}>
            <Query query={ALL_ASSETS_MEM_LIGHT}>
              {({ data, error }) => {
                console.log(driver);
                if (data && data.assetsInMem && driver.companyId) {
                  let rows = data.assetsInMem.map((x, key) => {
                    if (x.companyId !== driver.companyId) return null;
                    let driversText = 'řidič nepřiřazen';
                    if (drivers) {
                      let currentDriver = drivers.find(y => y.assetId === x.id);
                      if (currentDriver && currentDriver.id) {
                        driversText = 'přiřazeno ' + currentDriver.name;
                      }
                    }

                    return (
                      <MenuItem value={x.id} key={key}>
                        {`${x.sticker}, ${x.name}, ${driversText}`}
                      </MenuItem>
                    );
                  });

                  rows.unshift(
                    <MenuItem value="none" key="none">
                      nepřiřazeno
                    </MenuItem>
                  );
                  return (
                    <Select value={driver.assetId ? driver.assetId : 'none'} onChange={handleDriverAssetIdChange}>
                      {rows}
                    </Select>
                  );
                }
                return null;
              }}
            </Query>
          </div>
        </div>

        {driver.id ? (
          <div className={classes.rowFabDelete}>
            <Fab color="secondary" aria-label="smazat" className={classes.fabDelete} onClick={deleteDriverHandler}>
              <DeleteIcon />
            </Fab>
          </div>
        ) : null}
      </form>
    </React.Fragment>
  );
};

export default DriverForm;
