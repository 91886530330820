/** @format */

import React, {Component} from 'react';
import MapContainer from './Map/MapContainer';
import Logic from '../logic/Logic';
import NavHeader from './NavHeader/NavHeader';
import {SIDE_NAVS} from '../apollo/query/SideNav';
import SideNavs from './SideNavs/SideNavs';
import {withApollo} from '@apollo/client/react/hoc';
import {HashRouter, Route, withRouter, Switch} from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import Maintenance from './FunctionPanels/Maintenance/Maintenance';

class Main extends Component {
  constructor(props) {
    super(props);

    //load user setting from local storage
    let storageSideNavs = localStorage.getItem('sideNavs');

    if (storageSideNavs) {
      //load user settings
      this.sideNavs = JSON.parse(storageSideNavs);

      //close all when initializing
      this.sideNavs.forEach(x => {
        x.visible = false;
      });
    } else {
      //default flexBox settings
      this.sideNavs = [
        {
          name: 'collapsible',
          width: '34rem',
          minWidth: '24rem',
        },
        {
          name: 'logBook',
          width: '50rem',
          minWidth: '30rem',
        },
        {
          name: 'fuelPage',
          width: '50rem',
          minWidth: '40rem',
        },
        {
          name: 'overlay',
          width: '100%',
          minWidth: '100%',
          visible: document.documentElement.clientWidth < 400,
        },
      ];
    }

    this.sideNavs[0].visible = this.collapsibleDefault();

    if (document.documentElement.clientWidth < 400) {
      //calc 80%
      let navWidth = document.documentElement.clientWidth * 0.8;
      let rem = navWidth / parseFloat(getComputedStyle(document.documentElement).fontSize);

      //setting for mobiles
      this.sideNavs[0].width = `${rem}rem`;
    }

    this.sideNavs.map(x => {
      if (x.name === 'overlay' && this.props.location.pathname !== `/`) {
        x.visible = document.documentElement.clientWidth < 400;
      }
      return null;
    });

    this.sideNavs.map(x => {
      if (x.visible === true && this.props.location.pathname !== `/${x.name}` && x.name !== 'overlay') {
        this.props.history.push(`/${x.name}`);
      }
      return null;
    });
  }

  render() {
    return (
      <React.Fragment>
        <NavHeader handleLogout={this.props.handleLogout} />
        <main className="content-container">
          <SideNavs />
          <HashRouter>
            <Switch>
              <Route exact path="/maintenance/:assetId" component={Maintenance} />
            </Switch>
          </HashRouter>
          <div className="full-width-layer">
            <MapContainer className="map-container" />
          </div>
        </main>
      </React.Fragment>
    );
  }

  componentDidMount() {
    this.props.client.writeQuery({
      query: SIDE_NAVS,
      data: {sideNavs: cloneDeep(this.sideNavs)},
    });

    this.logicObject = new Logic({client: this.props.client});
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    if (this.logicObject && this.logicObject.unsubscribe) this.logicObject.unsubscribe();

    this.logicObject = null;
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  updateDimensions() {
    //listen to width change
    //mutate sidenavs
    if (this.props.location.pathname === '/' && document.documentElement.clientWidth > 600) {
      this.props.history.push(`/collapsible`);
    }

    if (
      (this.props.location.pathname === '/collapsible' || this.props.location.pathname === '/logBook') &&
      document.documentElement.clientWidth < 600
    ) {
      this.props.history.push(`/`);
    }

    //force react component update, it is React build-in method
    this.forceUpdate();
  }

  //on viewboxes over 900px show collapsible side nav in default
  collapsibleDefault() {
    if (document.documentElement.clientWidth > 900) {
      return true;
    }
    return false;
  }
}

export default withRouter(withApollo(Main));
