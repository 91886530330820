/** @format */

import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVert from '@material-ui/icons/MoreVert';
import {IconCar} from '../../../components/Icons';
import {withApollo} from '@apollo/client/react/hoc';
import {CARD_ACTION} from '../../../apollo/mutation/CardMutation';

class AssetMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      active: this.props.assetId,
    };
  }

  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = assetId => {
    //trigger asset
    this.props.client.mutate({
      mutation: CARD_ACTION,
      variables: {id: assetId, action: 'trigged'},
    });

    this.setState({anchorEl: null});
  };

  render() {
    const index = this.props.assets.findIndex(x => x.id === this.props.assetId);
    const {anchorEl} = this.state;
    const items = this.props.assets.map(x => {
      return (
        <MenuItem className="log-book__menu-item" key={x.id} onClick={() => this.handleClose(x.id)}>
          {x.name}
        </MenuItem>
      );
    });

    const button = (() => {
      return (
        <Button aria-owns={anchorEl ? 'simple-menu' : null} aria-haspopup="true" onClick={this.handleClick}>
          <div className="log-book__asset-selection__asset-icon">
            {this.props.assets[index] && (
              <IconCar iconType={this.props.assets[index].marker} iconColor="white" className="" />
            )}
          </div>
          <div className="menu-button__name-box">
            <div className="menu-button__asset-name">
              {this.props.assets[index] ? this.props.assets[index].name : ''}
            </div>
            <div className="menu-button__asset-sticker">
              {this.props.assets[index] ? this.props.assets[index].sticker : ''}
            </div>
          </div>
          <div className="menu-button__icon-open">
            <MoreVert className="menu-button__icon-open__svg" />
          </div>
        </Button>
      );
    })();

    return (
      <React.Fragment>
        <div className={this.props.className}>
          {button}
          <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
            <MenuList className="log-book__menu-list">{items}</MenuList>
          </Menu>
        </div>
      </React.Fragment>
    );
  }
}

export default withApollo(AssetMenu);
