/** @format */

import React, {useState} from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PanelContentAndMenu from './PanelContentAndMenu';
import {Query} from '@apollo/client/react/components';
import {ALL_ASSETS_MEM_STATIC} from '../../../apollo/query/AssetQuery';

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

// this is an univerzal function full-screen component
const AlarmPanel = ({classes, history}) => {
  function handleClose(history, e) {
    e.preventDefault();
    // close the tab
    setOpen(false);

    // setTimeout because of transition animation on closing
    setTimeout(() => history.push(`/collapsible`), 200);
  }

  const [opened, setOpen] = useState(true);
  return (
    <div>
      <Dialog fullScreen open={opened} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={e => handleClose(history, e)} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Panel Alarmu
            </Typography>
          </Toolbar>
        </AppBar>
        <Query query={ALL_ASSETS_MEM_STATIC}>
          {({data, loading, error}) => {
            if (error) return 'Při načítání nastala chyba';
            if (loading) return null;
            return <PanelContentAndMenu data={data} />;
          }}
        </Query>
      </Dialog>
    </div>
  );
};

AlarmPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(AlarmPanel));
