/** @format */

import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

function TextFieldWithSaveButton({value, callBack, label, required}) {
  const [isEditing, setIsEditing] = useState(false);

  const [editedValue, setEditedValue] = useState(value);

  console.log(isEditing);

  if (isEditing) {
    return (
      <ClickAwayListener
        onClickAway={() => {
          setEditedValue(value);
          setIsEditing(false);
        }}>
        <div style={{flexGrow: 1, flexWrap: 'nowrap', display: 'flex'}}>
          <TextField
            label={label}
            id="outlined-size-small"
            variant="outlined"
            size="small"
            type="email"
            value={editedValue}
            onChange={e => setEditedValue(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                callBack(editedValue);
                setIsEditing(false);
              }
            }}
            autoFocus={true}
            required={required}
          />
          <Button
            color="secondary"
            onClick={() => {
              callBack(editedValue);
              setIsEditing(false);
            }}>
            <SaveIcon />
          </Button>
        </div>
      </ClickAwayListener>
    );
  }

  return (
    <Typography variant="body1" onClick={() => setIsEditing(true)}>
      {value}
      <Button color="primary" onClick={() => setIsEditing(true)}>
        <EditIcon />
      </Button>
    </Typography>
  );
}

export default TextFieldWithSaveButton;
