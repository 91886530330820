/** @format */

import React from 'react';
import Button from '@material-ui/core/Button';
import Downloader from 'js-file-downloader';
var moment = require('moment-timezone');

const Header = ({ url, driver, range }) => {
  const download = () => {
    new Downloader({
      url,
      filename: `náhrady_${driver.name}_${moment(range.startDate).format('DD.MM.YYYY')}-${moment(range.endDate).format(
        'DD.MM.YYYY'
      )}.pdf`,
    })
      .then(function () {
        // Called when download ended
      })
      .catch(function (error) {
        // Called when an error occurred
      });
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: '10px',
          marginBottom: '10px',
        }}>
        {/* <Button variant="contained" color="primary">
          Předchozí
      </Button>*/}
        {url ? (
          <Button variant="contained" color="secondary" onClick={download}>
            Stáhnout
          </Button>
        ) : null}
        {/*<Button variant="contained" color="primary">
          Další
        </Button>*/}
      </div>
      {url ? null : <span style={{ flexBasis: 0.1 }}>Probíhá generování 1/1</span>}
    </React.Fragment>
  );
};

export default Header;
