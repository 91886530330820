import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import { blue } from '@material-ui/core/colors';
import { ASSET_DRIVERS } from '../../../../apollo/query/AssetDriverQuery';
import { useQuery, useMutation } from '@apollo/client';
import { MUTATE_TRIP_DRIVER } from '../../../../apollo/mutation/LogBookMutation';
import { LOG_BOOK_TRIPS } from '../../../../apollo/query/LogBook';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function DriversDialog(props) {
  const classes = useStyles();
  const { onClose, open } = props;
  const { data } = useQuery(ASSET_DRIVERS);
  const [changeDriver] = useMutation(MUTATE_TRIP_DRIVER);

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = async (key) => {

    const entries = Object.entries(props.checkedTripRows);

    let promises = [];
    let driverId = '0';
    if (data.getAssetDrivers[key]) {
      driverId = data.getAssetDrivers[key].id;
    }

    for (const [startStopUtime, checked] of entries) {
      let startStopUtimeSplitted = startStopUtime.split('.');
      let refetchStartUtime = parseInt(startStopUtimeSplitted[0]);
      let refetchStopUtime = parseInt(startStopUtimeSplitted[1]);

      if (checked === true) {
        promises.push(new Promise(resolve => {
          changeDriver({
            variables: {
              driver: driverId,
              assetId: props.assetId,
              startUtime: parseInt(startStopUtimeSplitted[0]),
              stopUtime: parseInt(startStopUtimeSplitted[1]),
            },
            refetchQueries: [{ query: LOG_BOOK_TRIPS, variables: { startUtime: refetchStartUtime, stopUtime: refetchStopUtime, assetId: props.assetId } }]
          }).then(x => {
            resolve();
          });
        }));
      }

    }
    Promise.all(promises).then(() => {
      console.log('close');
      onClose();
    });

  };

  let assetDrivers = [];

  if (data && data.getAssetDrivers && data.getAssetDrivers.length > 0) {
    assetDrivers = [...data.getAssetDrivers];
  }
  assetDrivers.push({ name: 'nepřiřazeno', id: '0' })

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Nastavit řidiče</DialogTitle>
      <List>
        {assetDrivers.map((x, key) => (
          <ListItem button onClick={() => handleListItemClick(key)} key={key}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={x.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

DriversDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export { DriversDialog };