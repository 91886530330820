/** @format */

import { gql } from '@apollo/client';

const TRAVEL_EXPENSES = gql`
  query TRAVEL_EXPENSES($driverId: String!, $start: String, $stop: String) {
    travelExpenses(driverId: $driverId, start: $start, stop: $stop)
  }
`;

export { TRAVEL_EXPENSES };
