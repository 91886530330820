import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import { ALL_ASSETS_MEM_STATIC } from '../../../../apollo/query/AssetQuery';
import { useQuery, useMutation } from '@apollo/client';
import { GROUPS, MUTATE_GROUPS } from '../../../../apollo/query/Group';
import cloneDeep from 'lodash/cloneDeep';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 200,
    height: 230,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function GroupTransferList({ groups, activeGroup }) {
  const { loading, data } = useQuery(ALL_ASSETS_MEM_STATIC);
  const [mutateGroup] = useMutation(MUTATE_GROUPS, { refetchQueries: [{ query: GROUPS }] });
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [doneOpen, setDoneOpen] = React.useState(false);

  const leftAssets = groups[activeGroup].assets.map(x => data.assetsInMem.find(y => x === y.id));
  const rightAssets = data.assetsInMem.filter(x => groups[activeGroup].assets.findIndex(y => y === x.id) === -1);

  const leftChecked = intersection(checked, leftAssets);
  const rightChecked = intersection(checked, rightAssets);

  console.log(doneOpen);
  const handleDoneClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setDoneOpen(false);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };


  // moves items from left to right
  const handleCheckedRight = (allRight) => {

    let leftToMove = leftChecked;
    if (allRight === 'all') {
      leftToMove = leftAssets;
    }

    let newGroups = cloneDeep(groups);
    if (newGroups && newGroups.length > 0) {
      newGroups.forEach(x => { delete x.__typename });
    } else {
      newGroups = [];
    }

    // clean all groups from selected devices
    newGroups.forEach(groupItem => {
      let newAssets = [];
      groupItem.assets.forEach(x => {
        let index = leftToMove.findIndex(y => y.id === x);
        if (index === -1) {
          newAssets.push(x);
        }
      });
      groupItem.assets = newAssets;
    });

    mutateGroup({ variables: { groupsInput: newGroups } }).then(({ error }) => {
      handleDoneClose(setChecked(not(checked, leftChecked)));
      if (!error) setDoneOpen(true);
    });
  }
  // moves items from right to left
  const handleCheckedLeft = (allLeft) => {

    let rightToMove = rightChecked;
    if (allLeft === 'all') {
      rightToMove = rightAssets;
    }

    let newGroups = cloneDeep(groups);
    if (newGroups && newGroups.length > 0) {
      newGroups.forEach(x => { delete x.__typename });
    } else {
      newGroups = [];
    }

    // clean all groups from selected devices
    newGroups.forEach(groupItem => {
      let newAssets = [];
      groupItem.assets.forEach(x => {
        let index = rightToMove.findIndex(y => y.id === x);
        if (index === -1) {
          newAssets.push(x);
        }
      });
      groupItem.assets = newAssets;
    });


    newGroups[activeGroup].assets = newGroups[activeGroup].assets.concat(rightToMove.map(x => x.id));
    console.log(newGroups);
    mutateGroup({ variables: { groupsInput: newGroups } }).then(({ error }) => {
      setChecked(not(checked, rightChecked));
      if (!error) setDoneOpen(true);
    });

  };


  const customList = (title, items) => {
    return (
      <Card>
        <CardHeader
          className={classes.cardHeader}
          title={title}
        />
        <Divider />
        <List className={classes.list} dense component="div" role="list">
          {items.map((value) => {
            const labelId = `transfer-list-item-${value}-label`;

            return (
              <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value.name} ${value.sticker}`} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    )
  };

  if (loading) {
    return;
  }

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>{customList('Ve skupině', leftAssets)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={() => handleCheckedRight('all')}
            disabled={leftAssets.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={() => handleCheckedLeft('all')}
            disabled={rightAssets.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList('Dostupné', rightAssets)}</Grid>
      <Snackbar open={doneOpen} autoHideDuration={3000} onClose={handleDoneClose}>
        <Alert onClose={handleDoneClose} severity="success">
          Uloženo
        </Alert>
      </Snackbar>
    </Grid>
  );
}