/** @format */

import {ALL_ASSETS} from '../../apollo/query/AssetQuery';
import client from '../../apollo/client';

async function initAssets() {
  const {data} = await client.query({query: ALL_ASSETS, fetchPolicy: 'no-cache'});
  return data.allAssets;
}

export {initAssets};
