/** @format */

import L from 'leaflet';

const AnimatedMarker = L.Marker.extend({
  options: {
    clickable: false,
  },

  initialize: function (latlngs, options, lastUtime, assetId, refetch) {
    this._lastUtime = lastUtime;
    this._latLngGrid = latlngs;
    this._assetId = assetId;
    this._map = {};
    this._isZooming = false;
    this.timeout = null;
    this.refetch = refetch;
    this._id = assetId;

    let currentPosition = this.currentPosition(latlngs);

    L.Marker.prototype.initialize.call(this, [currentPosition.lat, currentPosition.lng], options);

    if (currentPosition.inMotion === true) {
      this.snakeIt.bind(this);
    }
  },

  onAdd: function (map) {
    L.Marker.prototype.onAdd.call(this, map);
    this._map = map;
    map.addEventListener('zoomstart', () => {
      this._isZooming = true;
    });
    map.addEventListener('zoomend', () => {
      this._isZooming = false;
    });

    // Start animating when added to the map
    this.snakeIt();
  },

  removeMarker: function () {
    if (this._startMarker) {
      this._startMarker.remove();
      this._startMarker = null;
    }
  },

  mutate: function (latLngGrid) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this._latLngGrid = latLngGrid;
    this.snakeIt(latLngGrid);
  },

  snakeIt: function (latLngGrid) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (!this._map) {
      return null;
    }

    let latLngSeed = this._latLngGrid;
    if (latLngGrid) {
      latLngSeed = latLngGrid;
    }

    const currentPosition = this.currentPosition(latLngSeed);
    let that = this;

    if (this._isZooming === false) {
      this.setLatLng(currentPosition);
    }

    if (currentPosition.inMotion === true) {
      //set interval, no animation is needed when zoom is smaller than 12
      let interval = 2000;

      if (that._map && that._map.getZoom && that._map.getZoom() >= 12) {
        //test if marker is visible
        if (that._map.getBounds().contains(currentPosition)) {
          interval = 30;
        }
      }

      that.timeout = setTimeout(() => {
        that.snakeIt(latLngSeed);
      }, interval);
    } else {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
    }

    return null;
  },
  currentPosition: function (latLngSeed) {
    if (!latLngSeed) latLngSeed = this._latLngGrid;
    // current time - 18 sec
    const now = new Date();
    let currentTime = now.getTime() / 1000 - 18;
    let index = 0;

    while (latLngSeed[index + 1] && latLngSeed[index].utime > currentTime) {
      index++;
    }

    //gps is actual and it is possible to animate the marker
    if (index > 0) {
      let diff = {
        lat: latLngSeed[index - 1].lat - latLngSeed[index].lat,
        lng: latLngSeed[index - 1].lng - latLngSeed[index].lng,
        utime: latLngSeed[index - 1].utime - latLngSeed[index].utime,
      };

      //calc speed
      diff.lat = diff.lat / diff.utime;
      diff.lng = diff.lng / diff.utime;

      //how far is current time
      diff.currentTimeDiff = currentTime - latLngSeed[index].utime;

      //calc position
      diff.currentLat = Number(latLngSeed[index].lat) + diff.lat * diff.currentTimeDiff;
      diff.currentLng = Number(latLngSeed[index].lng) + diff.lng * diff.currentTimeDiff;

      //return current state
      return {lat: diff.currentLat, lng: diff.currentLng, inMotion: true, utime: latLngSeed[index].utime};
    }

    //return when nothing to animate (gps time is not actual)
    return {lat: latLngSeed[0].lat, lng: latLngSeed[0].lng, inMotion: false, utime: latLngSeed[index].utime};
  },
  getAngle: function () {
    let current = this.currentPosition();
    let next = {
      lat: this._latLngGrid[0].lat,
      lng: this._latLngGrid[0].lng,
      utime: this._latLngGrid[0].utime,
    };

    if (current.utime >= next.utime && this._latLngGrid && this._latLngGrid.length > 1) {
      current = {
        lat: this._latLngGrid[1].lat,
        lng: this._latLngGrid[1].lng,
        utime: this._latLngGrid[1].utime,
      };
    }

    // angle in degrees
    var angleDeg = (Math.atan2(next.lng - current.lng, next.lat - current.lat) * 180) / Math.PI;

    if (angleDeg < 0) {
      angleDeg = 360 + angleDeg;
    }

    return angleDeg;
  },
});

export {AnimatedMarker};
