/** @format */

import {gql} from '@apollo/client';

const USER_INFO = gql`
  query USER_INFO {
    userInfo {
      satApi
    }
  }
`;

export {USER_INFO};
