/** @format */
import React, {Component} from 'react';
import './sass/main.scss';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import {LoginForm} from './login/LoginForm';
import {PasswordReset} from './login/PasswordReset';
import Main from './containers/Main';
import {ApolloProvider} from '@apollo/client';
import client, {loginHandler} from './apollo/client';
import DialogsDriver from './components/DialogsDriver/DialogsDriver';
import * as Sentry from '@sentry/browser';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
import {csCZ} from '@material-ui/core/locale';
import Rollbar from 'rollbar';

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#039be6',
      },
      secondary: orange,
    },
    status: {
      danger: 'orange',
    },
    typography: {
      useNextVariants: true,
      fontFamily: ['Roboto', 'sans-serif'].join(','),
      fontSize: 22,
    },
    overrides: {
      MuiInputBase: {
        root: {
          paddingLeft: '10px',
          paddingRight: '10px',
        },
      },
    },
  },
  csCZ
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {loggedIn: loginHandler.isLoggedIn()};
  }

  handleLogin() {
    this.setState({loggedIn: true});
    this.router.history.replace('/');
  }

  handleLogout() {
    console.log('logout');
    loginHandler.logout({config: 'no-reload'});
    this.setState({loggedIn: false});
    this.router.history.replace('/');
    client.resetStore();
  }

  render() {
    const {loggedIn} = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <DialogsDriver />
          <Router ref={router => (this.router = router)}>
            <Switch>
              <Route exact path="/login" component={() => <LoginForm onLogin={this.handleLogin.bind(this)} />} />
              <Route exact path="/password-reset/:token?" component={({match}) => <PasswordReset match={match} />} />
              <Route
                path="/"
                component={
                  loggedIn
                    ? () => <Main handleLogout={this.handleLogout.bind(this)} />
                    : () => <LoginForm onLogin={this.handleLogin.bind(this)} />
                }
              />
            </Switch>
          </Router>
        </ApolloProvider>
      </MuiThemeProvider>
    );
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== 'development') {
      var _rollbarConfig = {
        accessToken: 'f3cc73e164cd487bab52dfcd7daa8c16',
        captureUncaught: true,
        captureUnhandledRejections: true,
        reportLevel: 'error',
        payload: {
          environment: 'production',
          person: {
            id: loginHandler.getEmail(),
          },
        },
      };

      new Rollbar(_rollbarConfig);
      // set error reporting context
      if (this.state.loggedIn) {
        Sentry.setUser({username: loginHandler.getEmail()});
      }

      Sentry.init({
        dsn: 'https://d7de715dc2784027999260ef030ee327@sentry.io/1376248',
        // ...
      });
    }
  }
}

export default App;
