/** @format */

import React, {useState} from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import InnerTable from './InnerTable';
//import Tooltip from '@material-ui/core/Tooltip';
//import AddIcon from '@material-ui/icons/Add';
//import Fab from '@material-ui/core/Fab';
//import {makeStyles} from '@material-ui/core/styles';
import {Query} from '@apollo/client/react/components';
import {ASSET_MEM} from '../../../apollo/query/AssetQuery';

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
};

/*const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));*/

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const Maintenance = props => {
  function handleClose(props, e) {
    e.preventDefault();
    // close the tab
    setOpen(false);

    // setTimeout because of transition animation on closing
    setTimeout(() => props.history.push(`/collapsible`), 200);
  }

  const [opened, setOpen] = useState(true);

  // const classes = useStyles();
  return (
    <Query query={ASSET_MEM} variables={{id: props.match.params.assetId}}>
      {({data}) => {
        return (
          <div>
            <Dialog fullScreen open={opened} onClose={handleClose} TransitionComponent={Transition}>
              <AppBar className={props.classes.appBar}>
                <Toolbar>
                  <IconButton color="inherit" onClick={e => handleClose(props, e)} aria-label="Close">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" color="inherit" className={props.classes.flex}>
                    Servis / STK / Připomínky - {data.assetInMem.name}
                  </Typography>
                </Toolbar>
              </AppBar>
              {/*  <Tooltip title="Přidat" aria-label="Přidat">
                <Fab color="primary" className={classes.absolute}>
                  <AddIcon />
                </Fab>
        </Tooltip>*/}
              <InnerTable
                assetId={props.match.params.assetId}
                tachoTotal={
                  data.assetInMem.totalKmCanBus && data.assetInMem.totalKmCanBus > 0
                    ? data.assetInMem.totalKmCanBus
                    : data.assetInMem.totalKm
                }
              />
            </Dialog>
          </div>
        );
      }}
    </Query>
  );
};

Maintenance.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Maintenance));
