/** @format */

import React from 'react';
import {Logo, BinocularsIcon, CalendarIcon} from '../../components/Icons';
//, GasStationIcon, DrawerIcon
import Share from './Share';
import UserSettingMenu from './UserSettingMenu';
import {SIDE_NAVS} from '../../apollo/query/SideNav';
import {withApollo} from '@apollo/client/react/hoc';
import {withRouter} from 'react-router';

class NavHeader extends React.Component {
  handleClick(name) {
    //mutate sidenavs
    this.props.client.query({query: SIDE_NAVS}).then(async ({data}) => {
      if (data && data.sideNavs) {
        let newState = data.sideNavs.map(x => {
          let newItem = {...x};
          if (newItem.name === name) {
            newItem.visible = !newItem.visible;
          } else {
            newItem.visible = false;
          }
          return newItem;
        });

        if (newState[0].visible === true && document.documentElement.clientWidth < 900) {
          newState.forEach(x => {
            if (x.name === 'overlay') x.visible = true;
          });
        }

        //write to store
        await this.props.client.writeQuery({
          query: SIDE_NAVS,
          data: {sideNavs: newState},
        });
        if (this.props.location.pathname === `/${name}`) {
          this.props.history.push(`/`);
        } else {
          this.props.history.push(`/${name}`);
        }
      }
    });
  }

  render() {
    let {pathname} = this.props.location;

    let iconArray = [
      <div
        key="0"
        className={
          'header-container__menu-item-ico' +
          (pathname === '/collapsible' ? ' header-container__menu-item-ico--active' : '')
        }
        onClick={() => this.handleClick('collapsible')}>
        <BinocularsIcon />
      </div>,
      <div
        key="1"
        className={
          'header-container__menu-item-ico' +
          (pathname === '/logBook' ? '  header-container__menu-item-ico--active' : '')
        }
        onClick={() => this.handleClick('logBook')}>
        <CalendarIcon />
      </div>,
    ];
    /*
,
                            <div key='2' 
                                className={"header-container__menu-item-ico" + (pathname==='/fuelPage'?"  header-container__menu-item-ico--active":"")} 
                                onClick= {()=>this.handleClick('fuelPage')}>
                                <GasStationIcon />
                            </div>,
                             <div key='3' 
                                className={"header-container__menu-item-ico" + (pathname==='/inbox'?"  header-container__menu-item-ico--active":"")} 
                                onClick= {()=>this.handleClick('inbox')}>
                                <DrawerIcon />
                            </div>
                            */

    return (
      <header className="logged-header">
        <div className="header-container">
          <div className="header-container__menu-items-box">{iconArray}</div>
          <div className="header-container__logo">
            <Logo className="cerulean-white-medium" />
          </div>
          <div className="header-container__right-box">
            <Share />
            <UserSettingMenu handleLogout={this.props.handleLogout} />
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(withApollo(React.memo(NavHeader)));
