/** @format */

// NOTE: this keeps the access token in LocalStorage just because it's simpler
// but in a real application you may want to use cookies instead for better security

let loginUrl;

if (process.env.NODE_ENV === 'development') {
  loginUrl = `https://gql.gpson.cz:3000/login`;
} else {
  loginUrl = `https://gql.gpson.cz:3000/login`;
}

class LoginHandler {
  getAccessToken({reset}) {
    if (this.accessToken && reset !== true) return this.accessToken;
    let token = localStorage.getItem('accessToken');
    if (token) this.accessToken = token;
    return token;
  }

  getEmail() {
    let email = localStorage.getItem('email');
    return email;
  }

  async login(email, password) {
    let that = this;
    const response = await fetch(loginUrl, {
      method: 'POST',
      headers: {'content-type': 'application/json'},
      body: JSON.stringify({email, password}),
    });
    if (response.ok) {
      const {token} = await response.json();
      that.accessToken = token;
      localStorage.setItem('accessToken', token);
      localStorage.setItem('email', email);
    }
    return response.ok;
  }

  isLoggedIn() {
    return !!localStorage.getItem('accessToken');
  }

  async logout({config}) {
    this.accessToken = null;
    await localStorage.removeItem('accessToken');

    if (config && config === 'no-reload') {
      return;
    }
    window.location.replace('/#/');
    window.location.reload(true);
  }
}

export default LoginHandler;
