/** @format */

import {gql} from '@apollo/client';

const PASSWORD_RESET = gql`
  query PASSWORD_RESET($email: String!) {
    passwordReset(email: $email) {
      status
      token
      __typename
    }
  }
`;

const CHECK_STATUS = gql`
  query CHECK_STATUS($email: String!, $token: String!) {
    passwordResetStatus(email: $email, token: $token) {
      status
      token
      __typename
    }
  }
`;

const CONFIRM_LINK = gql`
  query CONFIRM_LINK($emailToken: String!) {
    confirmPasswordReset(emailToken: $emailToken) {
      status
      token
      __typename
    }
  }
`;

export {PASSWORD_RESET, CHECK_STATUS, CONFIRM_LINK};
