/** @format */
import {gql} from '@apollo/client';

const DELETE_MAINTENANCE = gql`
  mutation DELETE_MAINTENANCE($assetId: String!, $id: String!) {
    deleteMaintenance(assetId: $assetId, id: $id) {
      id
      name
      taskText
      utimeAlarm
      distanceAlarm
      distanceAlarmTotal
      created
      __typename
    }
  }
`;

const PUT_MAINTENANCE = gql`
  mutation putMaintenance($maintenanceInput: MaintenanceInput!) {
    putMaintenance(maintenanceInput: $maintenanceInput) {
      id
      name
      taskText
      utimeAlarm
      distanceAlarm
      distanceAlarmTotal
      created
      __typename
    }
  }
`;

export {DELETE_MAINTENANCE, PUT_MAINTENANCE};
