/** @format */

import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MySettings from '../FunctionPanels/Settings/MySettings';
import Temperatures from '../FunctionPanels/Temperatures/Temperatures';
import Voltages from '../FunctionPanels/Voltages/Voltages';
import TripAnalysis from '../FunctionPanels/TripAnalysis/TripAnalysis';
import Exports from '../FunctionPanels/Exports/Exports';
import Drivers from '../FunctionPanels/Drivers/Drivers';
import FuelAnalysis from '../FunctionPanels/FuelAnalysis/FuelAnalysis';
import { ALL_MODULES } from '../../apollo/query/AssetQuery';
import { Query } from '@apollo/client/react/components';
import { withRouter } from 'react-router';
import AlarmPanel from '../FunctionPanels/AlarmPanel/AlarmPanel';
import TravelExpenses from '../FunctionPanels/TravelExpenses/TravelExpenses';

class UserSettingMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  openMySettings = () => {
    this.handleClose();
    this.props.history.push(`/my-settings/`);
  };

  openDrivers = () => {
    this.handleClose();
    this.props.history.push(`/drivers/`);
  };

  openTripAnalysis = () => {
    this.handleClose();
    this.props.history.push(`/trip-analysis/`);
  };

  openFuelAnalysis = () => {
    this.handleClose();
    this.props.history.push(`/fuel-analysis/`);
  };

  openTemperatures = () => {
    this.handleClose();
    this.props.history.push(`/temperatures/`);
  };

  openAlarmPanel = () => {
    this.handleClose();
    this.props.history.push(`/alarm-panel/`);
  };

  openVoltages = () => {
    this.handleClose();
    this.props.history.push(`/voltages/`);
  };

  openExports = () => {
    this.handleClose();
    this.props.history.push(`/exports/`);
  };

  openTravelExpenses = () => {
    this.handleClose();
    this.props.history.push(`/travel-expenses/`);
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <Query query={ALL_MODULES}>
        {({ data }) => {
          // search if one or more asset has activated temperatures functions
          let temperatures = false;
          if (data && data.assetsInMem && data.assetsInMem.length > 0) {
            temperatures = Boolean(data.assetsInMem.find(x => x.modules.search('temperature') !== -1));
          }

          // search if one or more asset has activated tripAnalysis functions
          let tripAnalysis = false;
          if (data && data.assetsInMem && data.assetsInMem.length > 0) {
            tripAnalysis = Boolean(data.assetsInMem.find(x => x.modules.search('tripAnalysis') !== -1));
          }

          let fuels = false;
          if (data && data.assetsInMem && data.assetsInMem.length > 0) {
            fuels = Boolean(data.assetsInMem.find(x => x.modules.search('fuel') !== -1));
          }

          return (
            <React.Fragment>
              <div className="button">
                <Button
                  aria-owns={anchorEl ? 'simple-menu' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleClick}
                  className="login-button">
                  <AccountCircle className="login-button__setting-icon" />
                </Button>
                <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
                  {
                    //<MenuItem onClick={this.handleClose}>Nastavení jednotek</MenuItem>
                  }
                  {temperatures && <MenuItem onClick={this.openTemperatures}>Grafy teplot</MenuItem>}
                  <MenuItem onClick={this.openVoltages}>Grafy napětí</MenuItem>
                  {tripAnalysis && <MenuItem onClick={this.openTripAnalysis}>Analýza jízd</MenuItem>}
                  {fuels && <MenuItem onClick={this.openFuelAnalysis}>Stav paliva</MenuItem>}
                  <MenuItem onClick={this.openExports}>Exporty</MenuItem>
                  <MenuItem onClick={this.openDrivers}>Řidiči</MenuItem>
                  <MenuItem onClick={this.openMySettings}>Můj účet</MenuItem>
                  <MenuItem onClick={this.openAlarmPanel}>Panel alarmu</MenuItem>

                  <MenuItem onClick={this.openTravelExpenses}>Podklady diet</MenuItem>

                  <MenuItem onClick={this.props.handleLogout}>Odhlásit</MenuItem>
                </Menu>
              </div>
              <MySettings />
              {this.props.location.pathname === '/exports/' ? <Exports /> : null}
              {this.props.location.pathname === '/drivers/' ? <Drivers /> : null}
              {temperatures && <Temperatures data={data.assetsInMem} />}
              <Voltages data={data.assetsInMem} />
              <TripAnalysis data={data.assetsInMem} />
              {fuels && <FuelAnalysis data={data.assetsInMem} />}
              {this.props.location.pathname === '/alarm-panel/' ? <AlarmPanel data={data.assetsInMem} /> : null}
              {this.props.location.pathname.includes('/travel-expenses/') ? <TravelExpenses /> : null}
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

export default withRouter(UserSettingMenu);
