/** @format */

import React from 'react';

//this component should mount using ReactDOM
class Progress extends React.Component {
  render() {
    if (this.props.trim === 'loading') {
      return (
        <React.Fragment>
          <div className="progress-circle"></div>
          <div className="progress-section progress-section--left"></div>

          <div className="progress-section progress-section--right"></div>
        </React.Fragment>
      );
    }

    if (this.props.trim === 'loaded') {
      return (
        <React.Fragment>
          <div className="progress-section progress-section--left progress-section--left--go-away"></div>

          <div className="progress-section progress-section--right progress-section--right--go-away"></div>
        </React.Fragment>
      );
    }

    return null;
  }
}

export default Progress;
