/** @format */

import React, {Component} from 'react';
import {Logo} from '../components/Icons';
import Btn from '../components/Btn';
import {Link} from 'react-router-dom';
import {SET_PASSWORD} from '../apollo/mutation/SetPassword';
import {withApollo} from '@apollo/client/react/hoc';
import {loginHandler} from '../apollo/client';

class FinalResetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      newPasswordConf: '',
      errorForm: false,
      loadingProgress: false,
      key: Math.random(),
      validToken: this.props.token,
      passwordHasBeenChanged: false,
    };
    loginHandler.logout({config: 'no-reload'});
  }

  handleChange(event) {
    const {name, value} = event.target;
    this.setState({[name]: value});
  }

  handleClick() {
    if (this.state.newPassword.length < 5) {
      this.setState({errorForm: 'Zadané heslo je příliš krátké!'});
      return;
    }

    if (this.state.newPassword !== this.state.newPasswordConf) {
      this.setState({errorForm: 'Zadaná hesla nejsou stejná!'});
      return;
    }

    // try to change password
    this.props.client
      .mutate({
        mutation: SET_PASSWORD,
        variables: {
          newPassword: this.state.newPassword,
          token: this.props.token,
        },
      })
      .then(({data, error, loading}) => {
        if (loading) return null;
        if (error) console.log(error);
        console.log(data);
        if (!data) return null;

        if (data.setPassword === 'tokenAlreadyUsed') {
          this.props.goBackWithUsedToken('Tento formulář již nebyl platný.');
        }

        if (data.setPassword === 'linkIsNotValid') {
          this.props.goBackWithUsedToken('Vypršela platnost odkazu!');
        }

        if (data.setPassword === 'error') {
          this.props.goBackWithUsedToken('Nastala neznámá chyba.');
        }

        if (data.setPassword === 'done') {
          this.setState({passwordHasBeenChanged: true});
        }
      });
  }

  hideError(event) {
    event.preventDefault();
    this.setState({errorForm: false});
  }

  render() {
    const PasswordLabel = () => {
      if (this.state.newPassword.length > 0 && this.state.newPassword.length < 5) {
        return (
          <label htmlFor="password" className="error" style={{opacity: 1}}>
            Zadané heslo je přiliš krátké
          </label>
        );
      } else if (this.state.newPassword.length > 4 && this.state.newPassword.length < 7) {
        return (
          <label htmlFor="password" className="warning" style={{opacity: 1}}>
            Slabé heslo
          </label>
        );
      } else if (this.state.newPassword.length > 6) {
        return (
          <label htmlFor="password" className="info" style={{opacity: 1}}>
            Silné heslo
          </label>
        );
      }

      return null;
    };

    // password has been changed, go to login page
    if (this.state.passwordHasBeenChanged) {
      return (
        <div className="login-wrapper" key={this.state.key}>
          <Logo />
          <div
            key={this.state.key}
            className={'login-form center-block' + (this.state.errorForm ? ' shake login-form--border-error' : '')}>
            <br />
            <Link className="login-form--a" to={`/login`}>
              Přihlásit
            </Link>
          </div>
          <div className="login-form login-form--info-box center-block login-form--border-info">
            Heslo bylo změněno.
          </div>
        </div>
      );
    }

    return (
      <div className="login-wrapper" key={this.state.key}>
        <Logo />
        <div
          key={this.state.key}
          className={'login-form center-block' + (this.state.errorForm ? ' shake login-form--border-error' : '')}>
          <h1 className="login-form--h1">Zadejte nové heslo</h1>
          <div className="input-field">
            <input
              id="newPassword"
              name="newPassword"
              className="input--white"
              value={this.state.newPassword}
              type="password"
              onChange={this.handleChange.bind(this)}
              required
            />
            <label htmlFor="newPassword">Heslo</label>
            {<PasswordLabel />}
          </div>

          <div className="input-field">
            <input
              id="newPasswordConf"
              name="newPasswordConf"
              className="input--white"
              value={this.state.newPasswordConf}
              type="password"
              onChange={this.handleChange.bind(this)}
              required
            />
            <label htmlFor="newPasswordConf">Heslo znovu</label>

            {this.state.newPassword !== this.state.newPasswordConf && (
              <label htmlFor="newPasswordConf" className="error" style={{opacity: 1}}>
                Zadaná hesla se neshodují
              </label>
            )}
          </div>

          <Btn
            handleClick={this.handleClick.bind(this)}
            btnName="Resetovat heslo"
            loading={this.state.loadingProgress}
          />

          <br />
          <br />
        </div>
        {this.state.errorForm ? (
          <div
            className="login-form login-form--error-box center-block login-form--border-error"
            onClick={this.hideError.bind(this)}>
            {this.state.errorForm}
          </div>
        ) : null}
      </div>
    );
  }
}

export default withApollo(FinalResetForm);
