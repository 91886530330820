/** @format */

import React from 'react';
import {withApollo} from '@apollo/client/react/hoc';
import {ASSET_MEM} from '../../../../apollo/query/AssetQuery';
import {CARD_ACTION} from '../../../../apollo/mutation/CardMutation';
import Header from './Header';
import Body from './Body';
import Collapsible from 'react-collapsible';
import scrollIntoView from 'scroll-into-view-if-needed';

class AssetCard extends React.Component {
  constructor(props) {
    super(props);
    this.prevTrigged = false;
    this.state = {
      data: {},
    };
  }

  render() {
    if (!this.state.data || !this.state.data.assetInMem) return null;

    if (this.prevTrigged === false && this.state.data.assetInMem.trigged === true) {
      this.scrollCollapsible();
    }
    this.prevTrigged = this.state.data.assetInMem.trigged;

    return (
      <li className="asset-card" ref={node => (this.node = node)}>
        <Header
          trigged={this.state.data.assetInMem.trigged}
          markerVisible={this.state.data.assetInMem.markerVisible}
          assetInMem={this.state.data.assetInMem}
          handleClick={this.handleClick.bind(this)}
        />
        <Collapsible open={this.state.data.assetInMem.trigged} easing="ease-out" transitionTime={300}>
          {this.state.data.assetInMem.trigged ? <Body assetInMem={this.state.data.assetInMem} /> : ''}
        </Collapsible>
      </li>
    );
  }

  componentDidMount() {
    this.subscription = this.props.client
      .watchQuery({query: ASSET_MEM, variables: {id: this.props.assetId}})
      .subscribe(({data}) => {
        this.setState({data});
      });
  }

  componentWillUnmount() {
    if (this.subscription && this.subscription.unsubscribe) this.subscription.unsubscribe();
  }

  handleClick({variables}) {
    this.props.client.mutate({mutation: CARD_ACTION, variables});
  }

  completed(data) {
    console.log('xxx', data);
  }

  scrollCollapsible() {
    //scroll collapsible menu to the card (marker was clicked)

    setTimeout(() => {
      scrollIntoView(this.node, {
        behavior: 'auto',
        scrollMode: 'if-needed',
        block: 'start',
        inline: 'start',
      });
    }, 300);
  }
}

export default React.memo(withApollo(AssetCard));
