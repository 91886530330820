/** @format */

import React, {useEffect} from 'react';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import {TachometerIcon} from '../../../../components/Icons';
import Button from '@material-ui/core/Button';
import {FunctionComponent} from 'react';
import {ASSET_MUTATION} from '../../../../apollo/mutation/AssetMutation';
import {useMutation} from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import {green} from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    cardRoot: {
      minWidth: 150,
      minHeight: 150,
    },
    saveButton: {justifyContent: 'center'},
    cardContent: {
      fontSize: 14,
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

const MotohoursPopover: FunctionComponent<{assetInMem: any}> = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [motohours, setMotohours] = React.useState<number>(props.assetInMem.motohours);

  const [mutateMotohours, {loading}] = useMutation(ASSET_MUTATION, {
    onCompleted: () => {
      setAnchorEl(null);
    },
    update(cache, {data: {mutateAsset}}) {
      cache.modify({
        id: cache.identify({id: mutateAsset.id, __typename: 'AssetInMem'}),
        fields: {
          motohours() {
            return mutateAsset.motohours;
          },
        },
      });
    },
  });

  useEffect(() => {
    if (Boolean(anchorEl) === false) {
      setMotohours(props.assetInMem.motohours);
    }
  }, [props.assetInMem.motohours, anchorEl]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMotohoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMotohours(parseInt(event.target.value));
  };

  const handleButtonClick = () => {
    mutateMotohours({
      variables: {
        asset: {
          id: props.assetInMem.id,
          motohours: motohours,
        },
      },
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (props.assetInMem.motohours === -1) return null;
  return (
    <div>
      <div className="asset-card__info-row" onClick={handleClick}>
        <TachometerIcon />
        <p>Motohodiny: {motohours} h</p>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Card className={classes.cardRoot}>
          <CardContent className={classes.cardContent}>
            <Typography color="textSecondary" gutterBottom>
              Korekce motohodin:
            </Typography>
            <FormGroup row>
              <TextField
                id="standard-number"
                label="Počet motohodin"
                type="number"
                value={motohours}
                onChange={handleMotohoursChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">Mth</InputAdornment>,
                }}
              />
            </FormGroup>
            <FormGroup row className={classes.saveButton}>
              <FormGroup row className={classes.saveButton}>
                <div className={classes.wrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.saveButton}
                    disabled={loading}
                    onClick={handleButtonClick}>
                    Provést
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </FormGroup>
            </FormGroup>
          </CardContent>
        </Card>
      </Popover>
    </div>
  );
};

export default MotohoursPopover;
