/** @format */

import {gql} from '@apollo/client';

const CARD_ACTION = gql`
  mutation($id: String!, $action: String!) {
    mutateCard(id: $id, action: $action) @client {
      status
    }
  }
`;

const SHOW_HIDE_ALL_MARKERS = gql`
  mutation($state: Boolean!) {
    showHideAllMarkers(state: $state) @client {
      status
    }
  }
`;

const DISABLE_ICONS = gql`
  mutation($disableIcons: Boolean!) {
    disableIcons(disableIcons: $disableIcons) @client {
      status
    }
  }
`;

const DISABLE_STOPS = gql`
  mutation($disableStops: Boolean!) {
    disableStops(disableStops: $disableStops) @client {
      status
    }
  }
`;

export {CARD_ACTION, SHOW_HIDE_ALL_MARKERS, DISABLE_ICONS, DISABLE_STOPS};
