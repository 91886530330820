/** @format */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {withApollo} from '@apollo/client/react/hoc';
import {Query} from '@apollo/client/react/components';
import {HISTORY_POLYLINES_SETTING} from '../../../../apollo/query/AssetQuery';
import {ASSET_DRIVER} from '../../../../apollo/query/AssetDriverQuery';
import {TimeSpentIcon, DistanceIcon, CarBatteryIcon, GasStationIcon, RepairIcon} from '../../../../components/Icons';
import {GET_ADDRESS, FIT_BOUNDS} from '../../../../apollo/query/MapQuery';
import {SIDE_NAVS} from '../../../../apollo/query/SideNav';
import {withRouter} from 'react-router';
import copy from 'copy-to-clipboard';
import {MODAL_EVENT} from '../../../../apollo/query/ModalEvent';
import 'moment/locale/cs';
import moment from 'moment';
import TachoPopover from './TachoPopover.tsx';
import MotohoursPopover from './MotohoursPopover';
import {TachometerIcon} from '../../../../components/Icons';

moment.locale('cs');
var now = moment();

const styles = theme => ({
  button: {
    justifyContent: 'flex-start',
    flex: '1 0 100%',
    width: '100%',
    borderRadius: '2rem',
    color: 'white',
    fontSize: '1.4rem',
    margin: '-.5rem -1rem -.5rem -.7rem',
    paddingLeft: '1rem',
    background: 'rgba(3, 155, 230, 1)',
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: 'rgba(3, 155, 230, .6)',
      color: 'white',
    },
  },
  location: {
    background: 'rgba(234, 85, 111, 1)',
    '&:hover': {
      background: 'rgba(234, 85, 111, .6)',
    },
  },
  copyAddress: {
    background: 'rgba(255, 152, 0, 1)',
    '&:hover': {
      background: 'rgba(255, 152, 0, .6)',
    },
  },
  input: {
    display: 'none',
  },
});

const stylesModal = () => ({
  button: {
    borderRadius: '2rem',
    color: '#03a9f4',
    fontSize: '1.4rem',
    minWidth: '30%',
    borderColor: '#03a9f4',
    '&:hover': {
      backgroundColor: '#03a9f4',
      color: 'white',
    },
  },
  input: {
    display: 'none',
  },
});

const ModalNs = props => {
  const {classes} = props;
  return (
    <div
      className="modal-driver"
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
      }}>
      <div className="modal-driver__close" onClick={props.callBack} />
      <div className="modal-driver__row-contact">Kontaktovat řidiče {props.driver.name}</div>
      <div className="modal-driver__row-call-and-mail">
        <Button
          variant="outlined"
          className={classes.button}
          onClick={() => window.open('mailto:' + props.driver.email)}>
          Email
        </Button>
        <Button variant="outlined" className={classes.button} onClick={() => window.open('tel:' + props.driver.phone)}>
          Telefon
        </Button>
      </div>
    </div>
  );
};

const ModalWS = withStyles(stylesModal)(ModalNs);

/// this component is needed, because it is neccesary to mount modal to another
/// div than app
class Modal extends React.Component {
  componentDidMount() {
    ReactDOM.render(
      <ModalWS driver={this.props.data.assetDriver} callBack={this.props.openModal} />,
      document.getElementById('modal')
    );
  }

  componentWillUnmount() {
    let element = document.getElementsByClassName('modal-driver')[0];
    if (element) element.classList.add('modal-driver--closing');

    setTimeout(() => ReactDOM.unmountComponentAtNode(document.getElementById('modal')), 300);
  }

  render() {
    return null;
  }
}

class Body extends React.Component {
  state = {
    modal: false,
    tachoCorrection: false,
    anchorEl: null,
    checkedA: false,
  };

  openModal() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  fitMarker() {
    this.props.client.writeQuery({
      query: FIT_BOUNDS,
      data: {
        fitBounds: [
          {
            ...this.currentPosition(this.props.assetInMem.latLngGrid),
            __typename: 'LatLng',
          },
        ],
      },
    });

    this.closeSideNavsOnMobs();
  }

  closeSideNavsOnMobs() {
    if (document.documentElement.clientWidth < 600) {
      // hide all navs if vp < 600px
      this.props.client
        .query({
          query: SIDE_NAVS,
        })
        .then(async ({data}) => {
          if (data && data.sideNavs) {
            let newState = data.sideNavs.map(x => {
              let newItem = {
                ...x,
              };
              newItem.visible = false;

              return newItem;
            });

            // write to store
            await this.props.client.writeQuery({
              query: SIDE_NAVS,
              data: {
                sideNavs: newState,
              },
            });

            this.props.history.push(`/`);
          }
        });
    }
  }

  generateLatLngToGoogleMapsURL() {
    const latLng = this.currentPosition(this.props.assetInMem.latLngGrid);
    return `${latLng.lat},${latLng.lng}`;
  }

  render() {
    const {props} = this;
    const {classes} = this.props;
    const lastDate = moment.unix(props.assetInMem.lastUtime);
    let latLngInput = {
      ...props.assetInMem.latLngGrid[0],
    };
    delete latLngInput.__typename;
    delete latLngInput.id;

    return (
      <div
        style={{
          ...props.style,
        }}
        className="asset-card__body">
        <div className="asset-card__info-row">
          <TimeSpentIcon />
          <p>
            Dnes v provozu:&nbsp;
            <strong>
              {Math.floor(props.assetInMem.todayTime / 60)} h {props.assetInMem.todayTime % 60} min
            </strong>
          </p>
        </div>
        <Query query={HISTORY_POLYLINES_SETTING}>
          {
            // this handle if the today button is clicked or not, it is handled by
            // apollo cache because there is many scenarios where it can be turned
            // off, so it shouldn't be done by state
            ({data}) => {
              let active = false;
              if (data && data.historyPolylinesSetting && data.historyPolylinesSetting.length > 0) {
                data.historyPolylinesSetting.map(x => {
                  if (
                    x.startUtime === moment(now.format('YYYY-MM-DD') + 'T00:00').unix() &&
                    x.assetId === this.props.assetInMem.id &&
                    x.stopUtime === moment(now.format('YYYY-MM-DD') + 'T23:59').unix()
                  ) {
                    active = true;
                  }
                  return null;
                });
              }
              return (
                <div
                  className={`asset-card__info-row asset-card__info-row--active${
                    active ? ' asset-card__info-row--hovered' : ''
                  }`}
                  onClick={this.fireTodayRoute.bind(this, !active)}>
                  <DistanceIcon />
                  <p>
                    Dnes ujeto:&nbsp;
                    <strong>{props.assetInMem.todayKm}&nbsp; km</strong>
                  </p>
                </div>
              );
            }
          }
        </Query>
        <div className="asset-card__info-row">
          <CarBatteryIcon />
          <p>
            Napětí:&nbsp;
            <strong>{(props.assetInMem.externalVoltage / 1000).toFixed(2)} V</strong>
          </p>
        </div>
        {props.assetInMem.fuelPercent > -1 ? (
          <div className="asset-card__info-row">
            <GasStationIcon />
            <p>
              Paliva v nádrži: <strong> {props.assetInMem.fuelPercent} % </strong>
            </p>
          </div>
        ) : (
          ''
        )}
        <TachoPopover assetInMem={props.assetInMem} />
        <MotohoursPopover assetInMem={props.assetInMem} />
        {props.assetInMem.weight > 1 ? (
          <div className="asset-card__info-row">
            <TachometerIcon />
            <p>
              Hmotnost: <strong> {props.assetInMem.weight} kg</strong>
            </p>
          </div>
        ) : null}
        {props.assetInMem.axleWeight1 > 1 ? (
          <div className="asset-card__info-row">
            <TachometerIcon />
            <p>
              Náprava 1: <strong> {props.assetInMem.axleWeight1} kg</strong>
            </p>
          </div>
        ) : null}
        {props.assetInMem.axleWeight2 > 1 ? (
          <div className="asset-card__info-row">
            <TachometerIcon />
            <p>
              Náprava 2: <strong> {props.assetInMem.axleWeight2} kg</strong>
            </p>
          </div>
        ) : null}
        {props.assetInMem.axleWeight1 > 1 && props.assetInMem.axleWeight2 > 1 ? (
          <div className="asset-card__info-row">
            <TachometerIcon />
            <p>
              Hmotnost celkem: <strong> {props.assetInMem.axleWeight1 + props.assetInMem.axleWeight2} kg</strong>
            </p>
          </div>
        ) : null}
        <div
          className="asset-card__info-row asset-card__info-row--active"
          onClick={() => props.history.push(`/maintenance/${props.assetInMem.id}`)}>
          <RepairIcon />
          <p>Servis / STK / Úkoly</p>
        </div>
        <Query
          query={ASSET_DRIVER}
          variables={{
            assetId: props.assetInMem.id,
          }}>
          {({loading, error, data}) => {
            if (loading) return null;
            if (error) return null;
            if (data.assetDriver && data.assetDriver.name) {
              return (
                <div className="asset-card__info-row">
                  <Button
                    className={classes.button + ' asset-card__location-button'}
                    onClick={this.openModal.bind(this)}>
                    <div className="asset-card__location-button__row">
                      <i className="material-icons"> person_pin </i>
                      <p>
                        Řídí: <strong> {data.assetDriver.name} </strong>
                      </p>
                    </div>
                  </Button>
                  {this.state.modal ? <Modal data={data} openModal={this.openModal.bind(this)} /> : ''}
                </div>
              );
            }
            return (
              <div className="asset-card__info-row">
                <Button
                  className={classes.button + ' asset-card__location-button'}
                  onClick={() => this.props.history.push(`/drivers/`)}>
                  <div className="asset-card__location-button__row">
                    <i className="material-icons"> person_pin </i>
                    <p>Přiřadit řidiče</p>
                  </div>
                </Button>
              </div>
            );
          }}
        </Query>
        <div className="asset-card__info-row">
          <Button
            className={`${classes.button} ${classes.location} asset-card__location-button`}
            onClick={this.fitMarker.bind(this)}>
            <div className="asset-card__location-button__row">
              <i className="material-icons"> gps_fixed </i>
              <p>
                {props.assetInMem.ignition ? 'V pohybu ' : 'Stojí '}
                <strong>
                  {lastDate.calendar(null, {
                    sameElse: 'DD.MM.YYYY HH:mm',
                  })}
                </strong>
                <br />
                <Query
                  query={GET_ADDRESS}
                  fetchPolicy="no-cache"
                  variables={{
                    latLng: latLngInput,
                  }}>
                  {({data}) => {
                    if (data && data.getAddress)
                      return (
                        <React.Fragment>
                          {data.getAddress} <br />
                        </React.Fragment>
                      );
                    return null;
                  }}
                </Query>
              </p>
            </div>
          </Button>
        </div>
        <div className="asset-card__info-row">
          <Query
            query={GET_ADDRESS}
            fetchPolicy="no-cache"
            variables={{
              latLng: latLngInput,
            }}>
            {({data}) => {
              if (data && data.getAddress)
                return (
                  <Button
                    className={`${classes.button} ${classes.copyAddress} asset-card__location-button`}
                    onClick={this.handleCopyAddressButton.bind(this, data.getAddress)}>
                    <div className="asset-card__location-button__row">
                      <i className="material-icons"> file_copy </i>
                      <p>Zkopírovat adresu + GPS </p>
                    </div>
                  </Button>
                );
              return null;
            }}
          </Query>
        </div>
        <div
          className="asset-card__info-row"
          onClick={() =>
            window.open(`https://www.google.com/maps/dir/?api=1&origin=${this.generateLatLngToGoogleMapsURL()}`)
          }>
          <Button className={`${classes.button} ${classes.copyAddress} asset-card__location-button`}>
            <div className="asset-card__location-button__row">
              <i className="material-icons"> file_copy </i>
              <p>Plánování trasy</p>
            </div>
          </Button>
        </div>
        <div
          className="asset-card__info-row"
          onClick={() =>
            window.open(
              `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${this.generateLatLngToGoogleMapsURL()}`
            )
          }>
          <Button className={`${classes.button} ${classes.copyAddress} asset-card__location-button`}>
            <div className="asset-card__location-button__row">
              <i className="material-icons"> file_copy </i>
              <p>Street view</p>
            </div>
          </Button>
        </div>
        <div
          className="asset-card__info-row"
          onClick={() =>
            window.open(`https://www.google.com/maps/search/?api=1&query=${this.generateLatLngToGoogleMapsURL()}`)
          }>
          <Button className={`${classes.button} ${classes.copyAddress} asset-card__location-button`}>
            <div className="asset-card__location-button__row">
              <i className="material-icons"> file_copy </i>
              <p>Mapy Google </p>
            </div>
          </Button>
        </div>
      </div>
    );
  }

  componentDidMount() {
    // disable all polylines
    this.fireTodayRoute(false);
  }

  // handle click on today distance button
  fireTodayRoute(active) {
    // write values to the store
    this.props.client
      .query({
        query: HISTORY_POLYLINES_SETTING,
      })
      .then(({data}) => {
        const newData = {
          ...data,
        };
        // initialize empty array (this discard all setted polylines)
        newData.historyPolylinesSetting = [
          {
            __typename: 'HistoryPolylinesSetting',
          },
        ];
        newData.historyPolylinesSetting[0].startUtime = moment(now.format('YYYY-MM-DD') + 'T00:00').unix();
        newData.historyPolylinesSetting[0].stopUtime = moment(now.format('YYYY-MM-DD') + 'T23:59').unix();
        newData.historyPolylinesSetting[0].active = active;
        newData.historyPolylinesSetting[0].assetId = this.props.assetInMem.id;

        // write empty array if is disable
        if (active === false) {
          newData.historyPolylinesSetting = [];
        }

        this.props.client.writeQuery({
          query: HISTORY_POLYLINES_SETTING,
          data: newData,
        });

        // mutate sidenavs
        if (active) this.closeSideNavsOnMobs();
      });
  }

  // handle copy address button
  handleCopyAddressButton(address) {
    const latLng = this.currentPosition(this.props.assetInMem.latLngGrid);

    let text = convertDMS(latLng.lat, latLng.lng) + '\n';
    text += latLng.lat + ', ' + latLng.lng + '\n';
    text += address;

    // try to copy inside the clipboard
    if (copy(text)) {
      this.props.client.writeQuery({
        query: MODAL_EVENT,
        data: {
          modalsDriver: {
            id: 0,
            type: 'smallSuccess',
            title: '',
            rand: Math.random(),
            text: 'Zkopírováno do schránky!',
            __typename: 'ModalEvent',
          },
        },
      });
    } else {
      this.props.client.writeQuery({
        query: MODAL_EVENT,
        data: {
          modalsDriver: {
            id: 0,
            type: 'smallError',
            rand: Math.random(),
            title: '',
            text: 'Nelze zkopírovat!',
            __typename: 'ModalEvent',
          },
        },
      });
    }
  }

  currentPosition(latLngSeed) {
    // current time - 20 sec
    const now = new Date();
    let currentTime = now.getTime() / 1000 - 20;
    let index = 0;

    while (latLngSeed[index + 1] && latLngSeed[index].utime > currentTime) {
      index++;
    }

    // gps is actual and it is possible to animate the marker
    if (index > 0) {
      let diff = {
        lat: latLngSeed[index - 1].lat - latLngSeed[index].lat,
        lng: latLngSeed[index - 1].lng - latLngSeed[index].lng,
        utime: latLngSeed[index - 1].utime - latLngSeed[index].utime,
      };

      // calc speed
      diff.lat = diff.lat / diff.utime;
      diff.lng = diff.lng / diff.utime;

      // how far is current time
      diff.currentTimeDiff = currentTime - latLngSeed[index].utime;

      // calc position
      diff.currentLat = Number(latLngSeed[index].lat) + diff.lat * diff.currentTimeDiff;
      diff.currentLng = Number(latLngSeed[index].lng) + diff.lng * diff.currentTimeDiff;

      // return current state
      return {
        lat: diff.currentLat,
        lng: diff.currentLng,
        inMotion: true,
        utime: latLngSeed[index].utime,
      };
    }

    // return when nothing to animate (gps time is not actual)
    return {
      lat: latLngSeed[0].lat,
      lng: latLngSeed[0].lng,
      inMotion: false,
      utime: latLngSeed[index].utime,
    };
  }
}

/*
            <div className="asset-card__info-row"><i
            className="material-icons">local_gas_station</i>
            Dnešní spotřeba: 0</div>
            */

function toDegreesMinutesAndSeconds(coordinate) {
  var absolute = Math.abs(coordinate);
  var degrees = Math.floor(absolute);
  var minutesNotTruncated = (absolute - degrees) * 60;
  var minutes = Math.floor(minutesNotTruncated);
  var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return degrees + '°' + minutes + "'" + seconds + '"';
}

function convertDMS(lat, lng) {
  var latitude = toDegreesMinutesAndSeconds(lat);
  var latitudeCardinal = lat >= 0 ? 'N' : 'S';

  var longitude = toDegreesMinutesAndSeconds(lng);
  var longitudeCardinal = lng >= 0 ? 'E' : 'W';

  return latitude + ' ' + latitudeCardinal + ', ' + longitude + ' ' + longitudeCardinal;
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(withApollo(Body)));
