/** @format */

import {HISTORY_POLYLINES_SETTING} from '../../../apollo/query/AssetQuery';
import {ASSET_ROUTE, ASSET_ROUTE_UPDATES} from '../../../apollo/query/LogBook';
import * as L from 'leaflet';
import 'leaflet-polylinedecorator';
import 'leaflet-touch-helper';
import 'moment/locale/cs';
import moment from 'moment';

moment.locale('cs');

class PolylinesContainerObject {
  constructor({client, map, fitBounds}) {
    this.client = client;
    this.map = map;
    this.fitBounds = fitBounds;
    this.polylines = [];
    this.routeUpdateSubscriptions = [];
    this.polylinePrecision = this.map.getZoom();
    this.lastRefetch = new Date();
    this.watchQueries = [];

    //stop reloading when the script is runnig (avoid multiple runs), functions inside the array can be invoked
    this.reloadPolylinesFunctions = [];

    //lister for zoomchange and redraw the polylines (adjust the resolution)
    this.map.on('zoomend', this.redrawPolylines.bind(this));

    //subscribe to the store
    this.initSubscription();
  }

  //init polylines
  initSubscription() {
    let that = this;

    //wait until assets will be loaded into the cache
    this.subscriptionPolylines = this.client
      .watchQuery({
        query: HISTORY_POLYLINES_SETTING,
      })
      .subscribe(({data}) => {
        that.clearAll();
        let waits = [];

        //an asset should be trriggered to display polyline
        if (data.historyPolylinesSetting && data.historyPolylinesSetting.length > 0) {
          //count all routes
          let routesCounterTotal = 0;

          //go throw array with all start and stop times
          for (let i = 0; i < data.historyPolylinesSetting.length; i++) {
            //init empty function
            that.reloadPolylinesFunctions[i] = () => null;

            that.polylines[routesCounterTotal] = [];

            //go throw active sections
            if (data.historyPolylinesSetting[i].active === true && data.historyPolylinesSetting[i].startUtime > 0) {
              let {assetId} = data.historyPolylinesSetting[i];

              let taskStopTime = data.historyPolylinesSetting[i].stopUtime;

              //need to save value into the let scope because of sync
              let routesCounter = routesCounterTotal;

              waits.push(
                new Promise(resolve => {
                  let watchQuery = that.client.watchQuery({
                    query: ASSET_ROUTE,
                    //this query is going to be refetched when something is missing
                    fetchPolicy: 'cache-first',
                    pollInterval: 60000,
                    variables: {
                      assetId,
                      startUtime: data.historyPolylinesSetting[i].startUtime,
                      stopUtime: data.historyPolylinesSetting[i].stopUtime,
                    },
                  });

                  //this should be driven from watchQuery.subscribe()
                  let watchQueryUpdatesSubscription = null;

                  that.watchQueries.push(
                    watchQuery.subscribe(({data}) => {
                      let currentDividerIndex = null;

                      that.reloadPolylinesFunctions[i] = () => {
                        // if many poly, divider it
                        let {dividerIndex, divider} = that.getDivider({assetRoute: data.assetRoute});

                        if (dividerIndex !== currentDividerIndex) {
                          currentDividerIndex = dividerIndex;
                          let newPoly = [];
                          let {stopsVisible} = data.assetInMem;

                          //clean old poly
                          for (
                            let i = 0;
                            that.polylines[routesCounter] && i < that.polylines[routesCounter].length;
                            i++
                          ) {
                            if (that.polylines[routesCounter][i].assetId === assetId) {
                              if (that.polylines[routesCounter][i].arrow) {
                                that.polylines[routesCounter][i].arrow.remove();
                                that.polylines[routesCounter][i].arrow = null;
                              }
                              if (that.polylines[routesCounter][i].stopEvent) {
                                that.polylines[routesCounter][i].stopEvent.remove();
                                that.polylines[routesCounter][i].stopEvent = null;
                              }
                              that.polylines[routesCounter][i].remove();
                              that.polylines[routesCounter][i] = null;
                              //console.log('r');
                            } else {
                              newPoly.push(that.polylines[routesCounter][i]);
                            }
                          }

                          that.polylines[routesCounter] = newPoly;

                          //if there is an active subsribtion (watchQuery is refetched), unsubsribe
                          if (watchQueryUpdatesSubscription) {
                            if (watchQueryUpdatesSubscription.unsubscribe) {
                              watchQueryUpdatesSubscription.unsubscribe();
                            }
                            watchQueryUpdatesSubscription = null;
                          }

                          if (data && data.assetRoute && data.assetRoute.route && data.assetRoute.route.length > 0) {
                            let lastZeroSpeedIndex = -1;

                            //to be able generate stop event, some ignition should be fired (prevent generating false stop when on stop at the start of assetRoute)
                            let firstIgnited = false;

                            //there should be i>0 because we are using i-1
                            //go throw gps data and draw a polylines

                            // because some langlngs might be skipped, save max speeds
                            let lastBlockMaxSpeed = 0;

                            for (let i = data.assetRoute.route.length - 2; i > 0; i--) {
                              // WARNING: THIS LOOP IS GOING FROM THE LAST TO THE FIRST, see i values

                              //on every cycle, set event to a null
                              let stopEvent = null;

                              //fire first ignition and start looking for a stop events
                              if (firstIgnited === false && data.assetRoute.route[i].ignition === true)
                                firstIgnited = true;

                              //the asset saw on a stop, set start index
                              if (
                                firstIgnited === true &&
                                i > 2 &&
                                lastZeroSpeedIndex === -1 &&
                                data.assetRoute.route[i] &&
                                data.assetRoute.route[i].speed === 0
                              ) {
                                for (let a = i; a < data.assetRoute.route.length; a++) {
                                  if (data.assetRoute.route[a] && data.assetRoute.route[a].speed > 0) {
                                    lastZeroSpeedIndex = a;
                                    break;
                                  }
                                }
                              }

                              //stop ends, set stop index and event, reset counters
                              if (
                                lastZeroSpeedIndex > 0 &&
                                data.assetRoute.route[i] &&
                                data.assetRoute.route[i].speed > 0 &&
                                data.assetRoute.route[i].ignition === true
                              ) {
                                if (
                                  data.assetRoute.route[lastZeroSpeedIndex] &&
                                  data.assetRoute.route[lastZeroSpeedIndex].lat &&
                                  Math.abs(
                                    data.assetRoute.route[lastZeroSpeedIndex].lat - data.assetRoute.route[i].lat
                                  ) < 0.008 &&
                                  Math.abs(
                                    data.assetRoute.route[i].utime - data.assetRoute.route[lastZeroSpeedIndex].utime
                                  ) > 60
                                ) {
                                  //signalise end of the pause
                                  stopEvent = {
                                    stopUtime: data.assetRoute.route[i].utime,
                                    startUtime: data.assetRoute.route[lastZeroSpeedIndex].utime,
                                    latLng: data.assetRoute.route[lastZeroSpeedIndex],
                                  };
                                }
                                lastZeroSpeedIndex = -1;
                              }

                              //check if the polyline can be showed with 15s idle
                              const now = new Date();
                              if (now.getTime() / 1000 - 18 >= data.assetRoute.route[i].utime) {
                                // check absolute max speed in the block
                                if (data.assetRoute.route[i].speed > lastBlockMaxSpeed) {
                                  lastBlockMaxSpeed = data.assetRoute.route[i].speed;
                                }
                                //drive resolution of polyline because of performace
                                if (stopEvent || i % divider === 0) {
                                  that.addPolyline.call(that, {
                                    assetId,
                                    startLatLng: data.assetRoute.route[i],
                                    stopLatLng: data.assetRoute.route[i - 1],
                                    routesCounter,
                                    stopEvent,
                                    stopsVisible,
                                    maxSpeed: lastBlockMaxSpeed,
                                  });
                                  lastBlockMaxSpeed = 0;
                                }
                              }
                            }

                            //is this live subscription??? should have stop time higher then current time
                            if (taskStopTime > new Date().getTime() / 1000) {
                              //subsribe to the store for latlng updates
                              let watchQueryUpdates = that.client.watchQuery({
                                query: ASSET_ROUTE_UPDATES,
                                variables: {id: assetId},
                              });

                              watchQueryUpdatesSubscription = watchQueryUpdates.subscribe(({data}) => {
                                //here is stored a time to determine gap between the coordinates, because of refetch
                                let lastRunUtimes = 0;
                                if (data && data.assetInMem) {
                                  let {latLngGrid} = data.assetInMem;
                                  let {stopsVisible} = data.assetInMem;
                                  //check if the polyline can be showed with 15s idle
                                  const now = new Date();
                                  let maxDataMissing = 0;

                                  for (let i = latLngGrid.length - 1; i > 0; i--) {
                                    //there should be i>0 because we are using i-1
                                    if (now.getTime() / 1000 - 17 >= latLngGrid[i].utime) {
                                      //add polyline
                                      that.addPolyline.call(that, {
                                        assetId,
                                        startLatLng: latLngGrid[i],
                                        stopLatLng: latLngGrid[i - 1],
                                        routesCounter,
                                        stopsVisible,
                                      });

                                      if (
                                        Math.abs(latLngGrid[i - 1].utime - lastRunUtimes) > maxDataMissing &&
                                        lastRunUtimes > 0
                                      ) {
                                        maxDataMissing = latLngGrid[i - 1].utime - lastRunUtimes;
                                      }

                                      lastRunUtimes = latLngGrid[i - 1].utime;
                                    }
                                  }

                                  //if there is a time gap, it is neccesary to refetch from the  memory
                                  if (maxDataMissing > 280) {
                                    if (new Date() - that.lastRefetch > 60000) {
                                      that.lastRefetch = new Date();
                                      watchQuery.refetch();
                                    }
                                  }
                                }
                              });

                              that.routeUpdateSubscriptions.push(watchQueryUpdatesSubscription);
                            }

                            resolve();
                          }
                        }
                      };
                      //fist init
                      that.reloadPolylinesFunctions[i]();
                    })
                  );
                })
              );

              //wait until all polylines are loaded and then fitBounds
              Promise.all(waits).then(async () => {
                await that.fitBounds({bounds: that.polylines});
              });

              //increase main array counter
              routesCounterTotal++;
            }
          }
        }
      });
  }

  addPolyline({assetId, startLatLng, stopLatLng, stopEvent, routesCounter, stopsVisible, maxSpeed}) {
    let color = '#4caf50';
    let speed = stopLatLng.speed;
    if (maxSpeed) speed = maxSpeed;

    if (speed) {
      if (speed > 50) color = '#039be6';
      if (speed > 90) color = '#EA556F';
      if (speed > 130) color = '#e22ddf';
    }

    //search for duplicity
    let a = this.polylines[routesCounter].findIndex(
      x => x._latlngs[0].utime >= stopLatLng.utime && x.assetId === assetId
    );
    if (a !== -1) {
      return null;
    }

    //glue the polylines
    for (let i = this.polylines[routesCounter].length - 1; i >= 0; i--) {
      if (this.polylines[routesCounter][i].assetId === assetId) {
        if (
          this.polylines[routesCounter][i]._latlngs[0].lat &&
          this.polylines[routesCounter][i]._latlngs[0].utime !== startLatLng.utime &&
          this.polylines[routesCounter][i]._latlngs[0].utime < startLatLng.utime
        ) {
          startLatLng = this.polylines[routesCounter][i]._latlngs[1];
        }
        //end the loop
        i = -1;
      }
    }

    let index = this.polylines[routesCounter].push(
      L.polyline([startLatLng, stopLatLng], {color})
        .bindTooltip(
          speed +
            ' km/h<br/>' +
            moment.unix(stopLatLng.utime).calendar(null, {
              sameElse: 'DD.MM.YYYY HH:mm',
            })
        )
        .addTo(this.map)
    );

    //add touch helper
    L.path.touchHelper(this.polylines[routesCounter][index - 1]).addTo(this.map);

    this.polylines[routesCounter][index - 1].assetId = assetId;
    this.polylines[routesCounter][index - 1]._latlngs[0].utime = stopLatLng.utime;
    this.polylines[routesCounter][index - 1]._latlngs[1].utime = startLatLng.utime;

    //search for last utime latlng with the arrow, is is neccessary to have a distance between arrows
    let hasArrow = true;
    for (let i = index - 2; i >= 0; i--) {
      if (
        this.polylines[routesCounter][i].arrow &&
        Math.abs(this.polylines[routesCounter][i].arrow.utime - stopLatLng.utime) < 60
      ) {
        hasArrow = false;
        break;
      }
    }
    if (hasArrow) {
      this.polylines[routesCounter][index - 1].arrow = L.polylineDecorator(this.polylines[routesCounter][index - 1], {
        patterns: [
          {
            offset: '100%',
            repeat: 0,
            symbol: L.Symbol.arrowHead({pixelSize: 10, polygon: false, pathOptions: {color: color}}),
          },
        ],
      })
        .bindTooltip(
          speed +
            ' km/h<br/>' +
            moment.unix(stopLatLng.utime).calendar(null, {
              sameElse: 'DD.MM.YYYY HH:mm',
            })
        )
        .addTo(this.map);

      this.polylines[routesCounter][index - 1].arrow.utime = stopLatLng.utime;
    }

    if (stopEvent && stopEvent.startUtime && stopEvent.latLng && stopsVisible === true) {
      let time = stopEvent.stopUtime - stopEvent.startUtime;

      if (time < 60) {
        if (time === 1 || time >= 5) {
          time += ' sekund';
        } else if (time < 5) {
          time += ' sekundy';
        }
      } else if (time < 3600) {
        time = Math.ceil(time / 60);
        if (time === 1) {
          time += ' minuta';
        } else if (time < 5) {
          time += ' minuty';
        } else if (time >= 5) {
          time += ' minut';
        }
      } else {
        time = Math.round(time / 60 / 60);
        if (time === 1) {
          time += ' hodina';
        } else if (time < 5) {
          time += ' hodiny';
        } else if (time >= 5) {
          time += ' hodin';
        }
      }

      const html = `<div class="marker-map"><div class="marker-map__sticker-plate">zastávka ${time}</div></div>`;

      var myIcon = L.divIcon({html, className: 'marker-container'});

      //Create Leaflet marker
      this.polylines[routesCounter][index - 1].stopEvent = L.marker(stopEvent.latLng, {icon: myIcon})
        .addTo(this.map)
        .bindTooltip(
          moment.unix(stopEvent.startUtime).calendar() +
            ' - ' +
            moment.unix(stopEvent.stopUtime).calendar(null, {sameElse: 'DD.MM.YYYY HH:mm'})
        );
    }

    return true;
  }

  getDivider = ({assetRoute}) => {
    let divider = {dividerIndex: 0, divider: 1};
    let zoom = this.map.getZoom();
    let that = this;
    let zoomLevels = [
      {
        from: 20,
        to: 13,
        divider: null,
      },
      {
        from: 12,
        to: 9,
        divider: 200,
      },
      {
        from: 8,
        to: 0,
        divider: 20,
      },
    ];
    zoomLevels.forEach((x, key) => {
      if (zoom <= x.from && zoom >= x.to) {
        divider = {
          dividerIndex: key,
          divider: x.divider === null ? 1 : Math.round(assetRoute.route.length / x.divider),
        };
        that.lastDividerLevel = key;
      }
    });
    //set minimum to 1
    if (divider.divider < 1) divider.divider = 1;

    return divider;
  };

  //this method is used for redrawing polyline resolution after zoomend
  redrawPolylines() {
    let that = this;
    if (
      this.reloadPolylinesFunctions &&
      this.reloadPolylinesFunctions.length &&
      this.reloadPolylinesFunctions.length > 0
    ) {
      for (let i = 0; i < this.reloadPolylinesFunctions.length; i++) {
        //redraw
        that.reloadPolylinesFunctions[i]();
      }
    }
  }

  clearAll() {
    //remove all polylines
    if (this.polylines && this.polylines.length) {
      for (let i = 0; i < this.polylines.length; i++) {
        for (let e = 0; e < this.polylines[i].length; e++) {
          if (this.polylines[i] && this.polylines[i][e] && this.polylines[i][e].arrow) {
            this.polylines[i][e].arrow.remove();
            this.polylines[i][e].arrow = null;
          }

          if (this.polylines[i] && this.polylines[i][e] && this.polylines[i][e].stopEvent) {
            this.polylines[i][e].stopEvent.remove();
            this.polylines[i][e].stopEvent = null;
          }
          if (this.polylines[i] && this.polylines[i][e]) this.polylines[i][e].remove();
        }
      }
    }
    this.polylines = [];

    //remove all redraw functions
    this.reloadPolylinesFunctions = [];

    for (let i = 0; i < this.routeUpdateSubscriptions.length; i++) {
      if (this.routeUpdateSubscriptions[i] && this.routeUpdateSubscriptions[i].unsubscribe)
        this.routeUpdateSubscriptions[i].unsubscribe();
    }
    this.routeUpdateSubscriptions = [];

    for (let i = 0; i < this.watchQueries.length; i++) {
      if (this.watchQueries[i] && this.watchQueries[i].unsubscribe) this.watchQueries[i].unsubscribe();
    }
    this.watchQueries = [];
  }
}

export default PolylinesContainerObject;
