/** @format */
import {gql} from '@apollo/client';

const SET_ALARMS = gql`
  mutation SET_ALARMS($alarmInput: [AlarmInput!]) {
    setAlarms(alarmInput: $alarmInput) {
      id
      assetId
      isArmed
      hasArmedDoors
      hasArmedSignalLost
      hasArmedMotion
      lastLatLng {
        lat
        lng
      }
      smsNumber
      isSmsEnabled
      email
      isEmailEnabled
      created
      trigged
      __typename
    }
  }
`;

export {SET_ALARMS};
