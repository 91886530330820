/** @format */

import {gql} from '@apollo/client';

const ECO = gql`
  query ECO($assetId: String!, $startUtime: Int, $stopUtime: Int) {
    ecoData(assetId: $assetId, startUtime: $startUtime, stopUtime: $stopUtime) {
      assetId
      utime
      x
      y
      z
      speed
      __typename
    }
  }
`;

export {ECO};
