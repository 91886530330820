/** @format */

import React from 'react';
import generatePDFTravelExpenses from './generatePDFTravelExpenses';
import Header from '../Header';

var pdfjsLib = require('pdfjs-dist');
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

class PDFTravelExpenses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: null,
    };
  }
  render() {
    return (
      <React.Fragment>
        <Header url={this.state.url} driver={this.props.driver} range={this.props.range} />
        <div style={{ display: 'block', position: 'relative', maxHeight: '80%' }}>
          <div style={{ width: '30%', height: '30%', overflowY: 'scroll' }}>
            Náhled:
            <canvas id="the-canvas" style={{ width: '100%', border: '1px solid black', direction: 'ltr' }}></canvas>
          </div>
        </div>
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    console.log('umount');
  }

  async renderPDF() {
    let url = await generatePDFTravelExpenses({ driver: this.props.driver, range: this.props.range });
    this.setState({ url });

    // Using DocumentInitParameters object to load binary data.
    var loadingTask = pdfjsLib.getDocument(url);
    loadingTask.promise.then(
      function (pdf) {
        console.log('PDF loaded');

        // Fetch the first page
        var pageNumber = 1;
        pdf.getPage(pageNumber).then(function (page) {
          var canvas = document.getElementById('the-canvas');
          console.log(canvas);
          if (canvas) {
            console.log('Page loaded');

            var scale = 1;
            var viewport = page.getViewport({ scale: scale });

            // Prepare canvas using PDF page dimensions
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            var renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
              console.log('Page rendered');
            });
          }
        });
      },
      function (reason) {
        // PDF loading error
        console.error(reason);
      }
    );
  }

  componentDidMount() {
    this.renderPDF();
  }
}

export default PDFTravelExpenses;
