/** @format */

import React from 'react';
import {ALL_HISTORY_LOADING} from '../../../apollo/query/HistoryButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

const styles = () => ({
  fabProgress: {
    color: 'green',
    position: 'relative',
    top: -1,
    left: -1,
    zIndex: 1,
    marginRight: '1rem',
  },
});

class Loader extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.observableQuery = this.props.client.watchQuery({query: ALL_HISTORY_LOADING}).subscribe({
      next: ({data}) => {
        if (data.historyButtonLoader.length > 0) {
          if (this.state.loading !== true) this.setState({loading: true});
        }
        if (data.historyButtonLoader.length === 0) {
          if (this.state.loading !== false) this.setState({loading: false});
        }
      },
    });
  }

  componentWillUnmount() {
    if (this.observableQuery && this.observableQuery.unsubscribe) this.observableQuery.unsubscribe();
  }

  render() {
    const {classes} = this.props;

    if (this.state.loading) {
      return <CircularProgress size={14} className={classes.fabProgress} />;
    }
    return null;
  }
}

Loader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loader);
