/** @format */

const writeOpen = ({blob, exportType, exportName, doneCallback, fileName}) => {
  let newFileName = 'data';
  let MIMEType = '';
  if (exportType === 'PDF') {
    newFileName = `${fileName + exportName}.pdf`;
    MIMEType = 'application/pdf';
  } else {
    newFileName = `${fileName + exportName}.xlsx`;
    MIMEType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }
  newFileName = newFileName.replace(':', '-');

  if (document.URL.indexOf('file://') === -1) {
    // web browser

    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', newFileName);

    document.body.appendChild(link);
    doneCallback();
    link.click();
  } else {
    // eslint-disable-next-line
    window.requestFileSystem(
      // eslint-disable-next-line
      LocalFileSystem.PERSISTENT,
      0,
      function(fs) {
        fs.root.getFile(
          newFileName,
          {create: true, exclusive: false},
          function(fileEntry) {
            fileEntry.name = newFileName;
            fileEntry.fullPath = '/' + newFileName;
            writeFile(fs, fileEntry, new Blob([blob]), MIMEType);
            doneCallback();
          },
          error => {
            console.log('getFile error', error);
          }
        );
      },
      error => {
        console.log('requestFileSystem error', error);
      }
    );

    const writeFile = (fs, fileEntry, dataObj, MIMEType) => {
      // Create a FileWriter object for our FileEntry (log.txt).
      fileEntry.createWriter(function(fileWriter) {
        fileWriter.onwriteend = function() {
          // eslint-disable-next-line
          cordova.plugins.fileOpener2.open(fs.root.nativeURL + newFileName, MIMEType, {
            error: function() {
              alert('Tento typ souboru nelze otevřít.');
            },
            success: function(success) {
              console.log(success);
            },
          });
        };

        fileWriter.onerror = function(e) {
          console.log('Failed file write: ' + e.toString());
        };

        // If data object is not passed in,
        // create a new Blob instead.
        if (!dataObj) {
          dataObj = new Blob(['some file data'], {type: 'text/plain'});
        }

        fileWriter.write(dataObj);
      });
    };
  }
};

export default writeOpen;
