/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {SET_PASSWORD} from '../../../apollo/mutation/SetPassword';
import {withApollo} from '@apollo/client/react/hoc';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
  },
});

class PasswordChange extends React.Component {
  state = {
    newPassword: '',
    newPasswordRetry: '',
    error: null,
    passwordHasBeenChanged: false,
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleClick = () => {
    if (this.state.newPassword.length < 7) {
      this.setState({error: 'Minimální délka hesla je 7 znaků.'});
    } else if (this.state.newPassword !== this.state.newPasswordRetry) {
      this.setState({error: 'Zadaná hesla se neshodují.'});
    } else {
      this.setState({error: null});
      //token is not neccesary because of auth is sent bearer token in the header
      this.props.client
        .mutate({
          mutation: SET_PASSWORD,
          variables: {
            newPassword: this.state.newPassword,
            token: null,
          },
        })
        .then(({data, error, loading}) => {
          if (loading) return null;
          if (error) console.log(error);
          if (!data) return null;
          //console.log(data);

          if (data.setPassword.length > 15) {
            localStorage.setItem('accessToken', data.setPassword);
            this.setState({error: null, passwordHasBeenChanged: true});
            return;
          }

          if (data) {
            this.setState({error: 'Nastala neznámá chyba.'});
          }
        });
    }
  };

  isNewPassError = () => {
    if (this.state.newPassword.length > 0 && this.state.newPassword.length < 7) {
      return true;
    }
    return false;
  };
  isNewPassLabelText = () => {
    if (this.state.newPassword.length > 0 && this.state.newPassword.length < 7) {
      return 'Slabé heslo';
    }
    return 'Nové heslo';
  };

  isRetryLabelText = () => {
    if (this.state.newPassword !== this.state.newPasswordRetry && this.state.newPasswordRetry.length > 0) {
      return 'Hesla se neshodují';
    }
    return 'Nové heslo znovu';
  };

  isRetryError = () => {
    if (this.state.newPassword !== this.state.newPasswordRetry && this.state.newPasswordRetry.length > 0) {
      return true;
    }
    return false;
  };

  render() {
    const {classes} = this.props;

    if (this.state.passwordHasBeenChanged) {
      return <div>Heslo bylo úspěšně změněno.</div>;
    }

    return (
      <div style={{display: 'flex', flexWrap: 'wrap'}}>
        {this.state.error ? (
          <div style={{flex: '0 0 100%', fontSize: '1.6rem', color: 'red'}}>{this.state.error}</div>
        ) : (
          ''
        )}
        <form className={classes.container} noValidate autoComplete="off">
          <TextField
            error={this.isNewPassError()}
            id="newPassword"
            name="newPassword"
            label={this.isNewPassLabelText()}
            className={classes.textField}
            type="password"
            margin="normal"
            variant="outlined"
            onChange={this.handleChange('newPassword')}
            value={this.state.newPassword}
          />

          <TextField
            error={this.isRetryError()}
            id="newPasswordRetry"
            name="newPasswordRetry"
            label={this.isRetryLabelText()}
            className={classes.textField}
            type="password"
            onChange={this.handleChange('newPasswordRetry')}
            margin="normal"
            variant="outlined"
          />
          <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClick}>
            Změnit
          </Button>
        </form>
      </div>
    );
  }
}

PasswordChange.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withApollo(PasswordChange));
