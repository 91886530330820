/** @format */

import React from 'react';
import Dygraph from 'dygraphs';
import {FUEL_QUERY} from '../../../apollo/query/FuelQuery';
import {Query} from '@apollo/client/react/components';

class FuelBody extends React.Component {
  state = {
    startUtime: 0,
    stopUtime: 0,
  };

  render() {
    return (
      <div className={this.props.className}>
        <div className="body__graphic">
          <Query
            query={FUEL_QUERY}
            variables={{assetId: this.props.assetsArr[this.props.activeAsset].id}}
            onCompleted={this.dataHandler.bind(this)}>
            {() => {
              return <div>loading...</div>;
            }}
          </Query>
        </div>
      </div>
    );
  }

  dataHandler({fuelRead}) {
    this.rerenderGraph(fuelRead);
  }

  UNSAFE_componentWillUpdate() {
    if (this.dygraph) {
      this.dygraph.destroy();
      this.dygraph = null;
    }
  }

  rerenderGraph(graphqlData) {
    let dataArr = graphqlData.map(x => {
      //console.log();
      let d = new Date(0);
      d.setUTCSeconds(x.utime);
      return [d, parseFloat(x.level)];
    });

    this.dygraph = new Dygraph(document.getElementsByClassName('body__graphic')[0], dataArr, {
      labels: ['utime', 'hladina paliva %'],
      animatedZooms: true,
      ylabel: 'Hladina (%)',
      underlayCallback: function () {
        //var bottom_left = g.toDomCoords(highlight_start, -20);
        //var top_right = g.toDomCoords(highlight_end, +20);
        //var left = bottom_left[0];
        //var right = top_right[0];
        //canvas.fillStyle = "rgba(255, 255, 102, 1.0)";
        //canvas.fillRect(left, area.y, right - left, area.h);
      },
    });
  }
}

export default FuelBody;
