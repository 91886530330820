/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import SwapCalls from '@material-ui/icons/SwapCalls';
import Loader from './Loader';
import {HISTORY_POLYLINES_SETTING_WITH_TRIGGERED} from '../../../apollo/query/AssetQuery';
import {MODAL_EVENT} from '../../../apollo/query/ModalEvent';
import RefreshRounded from '@material-ui/icons/RefreshRounded';
import {withApollo} from '@apollo/client/react/hoc';
import {Query} from '@apollo/client/react/components';
import cloneDeep from 'lodash/cloneDeep';
import 'moment/locale/cs';
import moment from 'moment';

moment.locale('cs');

var now = moment();

const styles = theme => ({
  button: {
    margin: '6px 3px 3px',
    height: '3rem',
    right: 0,
    backgroundColor: 'rgba(0,0,0,.8)',
    color: 'white',
    padding: '0 1.5rem 0 0',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,1)',
    },
  },
  buttonRefresh: {
    margin: '3px',
    height: '3.5rem',
    padding: '.6rem',
    minWidth: 'unset',
    right: 0,
    backgroundImage: 'linear-gradient(#00A0E3, #B0CB1F)',
    color: 'white',
    zIndex: '1',
    '&:before': {
      backgroundImage: 'linear-gradient(#0091ce, #a4bd1c)',
      content: "''",
      opacity: '0',
      display: 'block',
      position: 'absolute',
      left: '0',
      right: '0',
      top: '0',
      transition: 'all .2s',
      bottom: '0',
      borderRadius: '10rem',
      zIndex: '-1',
    },
    '&:hover': {
      '&:before': {
        opacity: '1',
      },
    },
  },
  buttonRed: {
    backgroundColor: '#E53935',
    padding: '0px 10px',
    '&:hover': {
      backgroundColor: '#F44336',
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
    backgroundImage: 'linear-gradient(#00A0E3, #B0CB1F)',
    borderRadius: '10rem',
    padding: '.5rem',
    width: '3rem',
    height: '3rem',
    marginLeft: '-1px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    paddingLeft: '16px',
    paddingRight: '16px',
    border: '0',
    '&:focus': {
      outline: '0',
    },
  },
  textField: {
    marginLeft: 0,
    marginRight: 0,
    width: 250,
  },
  menuItem: {
    textTransform: 'uppercase',
  },
});

class HistoryButton extends React.Component {
  state = {
    historyStart: now.format('YYYY-MM-DD') + 'T00:00',
    historyStop: now.format('YYYY-MM-DD') + 'T23:59',
  };

  handleHistoryButton({startUtime, stopUtime, historyOn}) {
    //write values to the store
    this.props.client.query({query: HISTORY_POLYLINES_SETTING_WITH_TRIGGERED}).then(({data, error}) => {
      const newData = cloneDeep(data);

      //initialize empty array (this discard all setted polylines)
      newData.historyPolylinesSetting = [{__typename: 'HistoryPolylinesSetting'}];
      newData.historyPolylinesSetting[0].startUtime = startUtime;
      newData.historyPolylinesSetting[0].stopUtime = stopUtime;
      newData.historyPolylinesSetting[0].active = historyOn;

      // check if any card is trigged
      let index = -1;

      if (data && data.assetsInMem && data.assetsInMem.length > 0)
        index = data.assetsInMem.findIndex(y => y.trigged === true);
      if (index === -1 && historyOn === true) {
        //open an asset
        newData.assetsInMem[0].trigged = true;
        newData.historyPolylinesSetting[0].assetId = newData.assetsInMem[0].id;
      } else if (historyOn === true) {
        newData.historyPolylinesSetting[0].assetId = newData.assetsInMem[index].id;
      }

      if (historyOn === false) {
        newData.historyPolylinesSetting = [];
      }

      this.props.client.writeQuery({
        query: HISTORY_POLYLINES_SETTING_WITH_TRIGGERED,
        data: newData,
      });
    });
  }

  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  historyOff = () => {
    this.setState({historyOn: false});
    this.handleHistoryButton({
      startUtime: moment(now.format('YYYY-MM-DD') + 'T00:00').unix(),
      stopUtime: moment(now.format('YYYY-MM-DD') + 'T23:59').unix(),
      historyOn: false,
    });
  };

  historyOn = () => {
    let historyStop;

    if (this.state.historyStop !== 0) {
      historyStop = moment(this.state.historyStop).unix();
    } else {
      historyStop = 0;
    }

    //check validity
    if (moment(this.state.historyStart).unix() >= historyStop) {
      this.props.client.writeQuery({
        query: MODAL_EVENT,
        data: {
          modalsDriver: {
            id: 0,
            type: 'smallError',
            title: '',
            text: 'Nesprávné datum!',
            rand: Math.random(),
            __typename: 'ModalEvent',
          },
        },
      });
      return false;
    }

    //check length
    if (historyStop - moment(this.state.historyStart).unix() > 259200) {
      this.props.client.writeQuery({
        query: MODAL_EVENT,
        data: {
          modalsDriver: {
            id: 0,
            type: 'smallError',
            title: '',
            rand: Math.random(),
            text: 'Maximální zobrazený rozsah může být 72h.',
            __typename: 'ModalEvent',
          },
        },
      });
      return false;
    }

    this.handleHistoryButton({
      startUtime: moment(this.state.historyStart).unix(),
      stopUtime: historyStop,
      historyOn: true,
    });

    this.setState({anchorEl: null, historyOn: true});
  };

  datepickerHandler = e => {
    if (e.target.id === 'startTime') {
      this.setState({historyStart: e.target.value});
    }

    if (e.target.id === 'stopTime') {
      this.setState({historyStop: e.target.value});
    }
  };

  render() {
    const {classes} = this.props;
    const {anchorEl} = this.state;

    return (
      <Query query={HISTORY_POLYLINES_SETTING_WITH_TRIGGERED}>
        {({data}) => {
          let opened = false;
          if (data && data.historyPolylinesSetting && data.historyPolylinesSetting.length > 0) opened = true;

          return (
            <div className="map-container__history-button-box">
              {opened ? (
                <Fab
                  variant="extended"
                  className={[classes.button, classes.buttonRed].join(' ')}
                  onClick={this.historyOff}>
                  <Loader client={this.props.client} />
                  Vypnout historii
                </Fab>
              ) : (
                <Fab
                  variant="extended"
                  aria-owns={anchorEl ? 'historie' : undefined}
                  aria-haspopup="true"
                  className={classes.button}
                  onClick={this.handleClick}>
                  <SwapCalls className={classes.extendedIcon} />
                  Záznam trasy
                </Fab>
              )}

              <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
                <form className={classes.container} noValidate>
                  <TextField
                    id="startTime"
                    onChange={this.datepickerHandler}
                    label="Od"
                    type="datetime-local"
                    defaultValue={this.state.historyStart}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="stopTime"
                    onChange={this.datepickerHandler}
                    label="Do"
                    type="datetime-local"
                    defaultValue={this.state.historyStop}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </form>

                <MenuItem className={classes.menuItem} onClick={this.historyOn}>
                  zobrazit
                </MenuItem>
              </Menu>

              <Fab
                variant="extended"
                className={classes.buttonRefresh}
                // eslint-disable-next-line
                onClick={() => location.reload()}
                title="Znovu načíst aplikaci">
                <RefreshRounded />
              </Fab>
            </div>
          );
        }}
      </Query>
    );
  }
}

HistoryButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withApollo(HistoryButton));
