/** @format */

import React from 'react';
import Header from './Header';
import {FUEL_METERING} from '../../../apollo/query/FuelQuery';
import {Query} from '@apollo/client/react/components';
import {ALL_ASSETS_TRIGGED} from '../../../apollo/query/AssetQuery';
import FuelBody from './FuelBody';
import {SIDE_NAVS} from '../../../apollo/query/SideNav';

class FuelPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeAsset: 0,
    };
  }

  menuClickHandler(activeAsset) {
    this.setState({activeAsset});
  }

  render() {
    return (
      <Query query={SIDE_NAVS}>
        {({data}) => {
          let navSetting = data.sideNavs.find(x => x.name === 'fuelPage');

          return (
            <div
              className="side-nav"
              style={{
                flexBasis: navSetting.width,
                minWidth: navSetting.minWidth,
                ...this.props.style,
              }}>
              <Query query={ALL_ASSETS_TRIGGED}>
                {({error, data}) => {
                  let dataAssets = data;
                  if (error) {
                    console.log(error.message);
                    return `Error!`;
                  }

                  return (
                    <Query query={FUEL_METERING}>
                      {({error, data}) => {
                        if (data && data.fuelMetering && data.fuelMetering.length > 0) {
                          //concat array from mem with fuel metering array
                          let arr = data.fuelMetering.map(x => {
                            const mem = dataAssets.assetsInMem.filter(y => y.id === x.id);
                            if (mem.length === 1) {
                              x = {...x, ...mem[0]};
                            }
                            return x;
                          });

                          if (error) {
                            console.log(error.message);
                            return `Error!`;
                          }

                          return (
                            <div className="fuel-container">
                              <Header
                                activeAsset={this.state.activeAsset}
                                menuClickHandler={this.menuClickHandler.bind(this)}
                                assetsArr={arr}
                                className="header"
                              />

                              <FuelBody activeAsset={this.state.activeAsset} assetsArr={arr} className="body" />
                            </div>
                          );
                        }

                        return null;
                      }}
                    </Query>
                  );
                }}
              </Query>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default FuelPage;
