/** @format */

import {gql} from '@apollo/client';

const GET_LOCATIONS = gql`
  query COMPANY_LOCATIONS($driverId: String, $locationId: ID) {
    getLocations(driverId: $driverId, locationId: $locationId) {
      _id
      name
      companyId
      workHoursExcludedDrivers
      geoJSON
      __typename
    }
  }
`;

const MUTATE_LOCATION = gql`
  mutation MUTATE_LOCATION($locationInput: LocationInput!) {
    mutateLocation(locationInput: $locationInput) {
      _id
      name
      companyId
      workHoursExcludedDrivers
      geoJSON
      __typename
    }
  }
`;

const GET_LOCATIONS_WORKING_HOURS = gql`
  query COMPANY_LOCATIONS($driverId: String, $locationId: ID) {
    getLocations(driverId: $driverId, locationId: $locationId) {
      _id
      name
      workHoursExcludedDrivers
      __typename
    }
  }
`;

export {GET_LOCATIONS, MUTATE_LOCATION, GET_LOCATIONS_WORKING_HOURS};
