/**
 * /*eslint-disable
 *
 * @format
 */

import React from 'react';
import {ResponsiveLine} from '@nivo/line';
//import last from 'lodash/last';
import 'moment/locale/cs';
import moment from 'moment';

moment.locale('cs');
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const MyResponsiveLine = ({data, loading}) => {
  let minimum = 1;
  let maximum = 14;

  if (loading) return <p>Načítám data ze serveru.</p>;
  if (!data || !data.voltages || data.voltages.length < 1) return <p>Pro dané období nejsou k dispozici žádná data.</p>;

  let lastUtime = 0;

  let dataFiltered = data.voltages.filter(x => {
    let isOk = x.utime - lastUtime > 180;
    if (isOk) lastUtime = x.utime;
    return isOk;
  });

  const redTresholdVoltage = 11500;
  let dataPrepRed = [];
  let dataPrepGreen = [];
  const dataCompleted = [];
  let last = {};

  dataFiltered.forEach(x => {
    if (x.value / 1000 > maximum) maximum = x.value / 1000;
    if (x.value / 1000 < minimum) minimum = x.value / 1000;

    // red lines
    if (x.value < redTresholdVoltage) {
      if (last.type === 'green') {
        // finish green line
        dataPrepGreen.push({
          x: moment.unix(x.utime - (x.utime - last.obj.utime) / 2).toDate(),
          y: redTresholdVoltage / 1000,
        });
        dataCompleted.push({
          id: dataCompleted.length,
          data: dataPrepGreen,
          color: 'hsl(146, 100%, 39%)',
        });
        dataPrepGreen = [];
        dataPrepRed.push({
          x: moment.unix(x.utime - (x.utime - last.obj.utime) / 2).toDate(),
          y: redTresholdVoltage / 1000,
        });
      }

      dataPrepRed.push({x: moment.unix(x.utime).toDate(), y: x.value / 1000});
      last = {type: 'red', obj: {x: moment.unix(x.utime).toDate(), y: x.value / 1000, utime: x.utime}};
    }

    // green lines
    if (x.value >= redTresholdVoltage) {
      if (last.type === 'red') {
        // finish green line
        dataPrepRed.push({
          x: moment.unix(x.utime - (x.utime - last.obj.utime) / 2).toDate(),
          y: redTresholdVoltage / 1000,
        });
        dataCompleted.push({
          id: dataCompleted.length,
          data: dataPrepRed,
          color: 'hsl(6, 100%, 50%)',
        });
        dataPrepRed = [];
        dataPrepGreen.push({
          x: moment.unix(x.utime - (x.utime - last.obj.utime) / 2).toDate(),
          y: redTresholdVoltage / 1000,
        });
      }

      dataPrepGreen.push({x: moment.unix(x.utime).toDate(), y: x.value / 1000});
      last = {type: 'green', obj: {x: moment.unix(x.utime).toDate(), y: x.value / 1000, utime: x.utime}};
    }
  });

  if (dataPrepRed.length > 0) {
    dataCompleted.push({
      id: dataCompleted.length,
      data: dataPrepRed,
      color: 'hsl(6, 100%, 50%)',
    });
  }

  if (dataPrepGreen.length > 0) {
    dataCompleted.push({
      id: dataCompleted.length,
      data: dataPrepGreen,
      color: 'hsl(146, 100%, 39%)',
    });
  }

  return (
    <ResponsiveLine
      data={dataCompleted}
      margin={{top: 10, right: 20, bottom: 80, left: 50}}
      xScale={{type: 'time', format: 'native'}}
      yScale={{type: 'linear', stacked: false, min: minimum - 1, max: maximum + 1}}
      axisBottom={{
        format: '%H:%M %d.%m',
        tickValues: 'every 4 hours',
        //legend: `${moment(dataPrep[0].x).format('DD-MM-YYYY HH:mm')} až ${moment(last(dataPrep).x).format(
        //  'DD-MM-YYYY HH:mm'
        //)}`,
        legendPosition: 'middle',
        legendOffset: 35,
        tickRotation: 70,
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Napětí [V]',
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      xFormat={val => {
        return moment(val).format('DD.MM.YYYY HH:mm');
      }}
      yFormat={val => {
        return `${val} V`;
      }}
      animate={false}
      isInteractive={true}
      colors={d => d.color}
      lineWidth={6}
      pointSize={5}
      pointLabel="y"
      pointColor={{theme: 'background'}}
      pointBorderWidth={2}
      pointBorderColor={{from: 'serieColor'}}
      useMesh={true}
    />
  );
};

export default MyResponsiveLine;
