/** @format */
import './../../../node_modules/leaflet-geosearch/dist/geosearch.css';
import './../../../node_modules/leaflet-draw/dist/leaflet.draw.css';

import React from 'react';
import L from 'leaflet';
import 'leaflet-draw';
import drawLocales from 'leaflet-draw-locales';
import * as GeoSearch from 'leaflet-geosearch';
import Button from '@material-ui/core/Button';

drawLocales('cs');

class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this._map = {};
    this.state = {
      drawnItems: false,
    };
    this._edited = props.edited;
  }

  save = () => {
    this.props.saveCallback({geoJSON: this._drawnItems.toGeoJSON()});
  };

  render() {
    return (
      <div style={{width: '80vw', height: '80vh'}}>
        <div id="locations_map_box"></div>
        {this.state.drawnItems ? (
          <div style={{position: 'absolute', bottom: '5vh', right: '5vw', zIndex: 888}}>
            <Button variant="contained" color="primary" onClick={this.save}>
              Uložit místo
            </Button>
          </div>
        ) : null}
      </div>
    );
  }

  componentDidMount() {
    this._map = L.map('locations_map_box').setView([49.22884, 17.424733], 7);

    // reenable drawing after finding location
    this._map.on('geosearch/showlocation', () => {
      this._map.dragging.enable();
    });

    L.tileLayer('https://api.maptiler.com/maps/streets/256/{z}/{x}/{y}@2x.png?key=ExTYaGoiNx9KfngsC2Wd', {
      attribution:
        '<a href="https://www.maptiler.com/license/maps/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
      maxZoom: 20,
      id: 'mapbox.streets.locations',
    }).addTo(this._map);

    this._drawnItems = new L.FeatureGroup();

    console.log(this._edited);
    if (this._edited && this._edited.geoJSON) {
      this._drawnItems.addLayer(L.geoJSON(this._edited.geoJSON));
      this._map.fitBounds(this._drawnItems.getBounds());
      this.setState({drawnItems: true});
    }

    this._map.addLayer(this._drawnItems);

    let drawControl = new L.Control.Draw({
      draw: {
        polyline: false,
        marker: false,
        circle: false,
        circlemarker: false,
      },
      edit: {
        featureGroup: this._drawnItems,
      },
    });
    this._map.addControl(drawControl);

    const search = new GeoSearch.GeoSearchControl({
      provider: new GeoSearch.OpenStreetMapProvider(),
      autoClose: true,
      searchLabel: 'Zadejte adresu',
      notFoundMessage: 'Adresa nenalezena',
    });

    this._map.on(L.Draw.Event.CREATED, e => {
      this._drawnItems.addLayer(e.layer);
    });

    // hide save button when no layers
    this._drawnItems.on('layerremove', () => {
      if (this._drawnItems.getLayers().length === 0) {
        this.setState({drawnItems: false});
      }
    });

    this._drawnItems.on('layeradd', () => {
      console.log(this._drawnItems.toGeoJSON());
      if (this._drawnItems.getLayers().length > 0) {
        this.setState({drawnItems: true});
      }
    });

    this._map.addControl(search);
  }

  componentWillUnmount() {
    if (this._map) {
      this._map.remove();
      this._map = null;
      this._drawnItems = null;
    }
  }
}

export default MapContainer;
