/** @format */

import React, {useEffect} from 'react';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {TachometerIcon} from '../../../../components/Icons';
import Button from '@material-ui/core/Button';
import {FunctionComponent} from 'react';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {ASSET_MUTATION} from '../../../../apollo/mutation/AssetMutation';
import {useMutation} from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import {green} from '@material-ui/core/colors';
import 'moment/locale/cs';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    cardRoot: {
      minWidth: 150,
      minHeight: 150,
    },
    saveButton: {justifyContent: 'center'},
    cardContent: {
      fontSize: 14,
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

const TachoPopover: FunctionComponent<{assetInMem: any}> = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [tacho, setTacho] = React.useState<number>(props.assetInMem.totalKm);
  const [mutateTacho, {loading}] = useMutation(ASSET_MUTATION, {
    onCompleted: () => {
      setAnchorEl(null);
    },
    update(cache, {data: {mutateAsset}}) {
      cache.modify({
        id: cache.identify({id: mutateAsset.id, __typename: 'AssetInMem'}),
        fields: {
          totalKm() {
            return mutateAsset.totalKm;
          },
        },
      });
    },
  });

  const lastMonth = moment().subtract(1, 'months').startOf('day');

  const [selectedDate, setDate] = React.useState<MaterialUiPickersDate>(lastMonth);

  const [checked, setChecked] = React.useState<boolean>(false);

  useEffect(() => {
    if (Boolean(anchorEl) === false) {
      setTacho(props.assetInMem.totalKm);
    }
  }, [props.assetInMem.totalKm, anchorEl]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTachoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTacho(parseInt(event.target.value));
  };

  const handleButtonClick = () => {
    mutateTacho({
      variables: {
        asset: {
          id: props.assetInMem.id,
          totalKm: tacho,
          logBookCorrectionUtime: checked && selectedDate ? selectedDate.startOf('day').unix() : null,
        },
      },
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div className="asset-card__info-row" onClick={e => handleClick(e)}>
        <TachometerIcon />
        <p>
          {props.assetInMem.totalKmCanBus > 0
            ? 'Tacho CanBus: ' + props.assetInMem.totalKmCanBus
            : 'Stav tacho: ' + tacho}
          &nbsp;km
        </p>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Card className={classes.cardRoot}>
          <CardContent className={classes.cardContent}>
            <Typography color="textSecondary" gutterBottom>
              Korekce tachometru:
            </Typography>
            {props.assetInMem.totalKmCanBus > 0 ? (
              <Typography>Tachometr je vyčítán z řídící jednotky, nelze dělat jeho korekce.</Typography>
            ) : (
              <React.Fragment>
                <FormGroup row>
                  <TextField
                    id="standard-number"
                    label="Počet"
                    type="number"
                    value={tacho}
                    onChange={handleTachoChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Km</InputAdornment>,
                    }}
                  />
                </FormGroup>
                <FormGroup row>
                  <FormControlLabel
                    control={<Switch checked={checked} onChange={() => setChecked(!checked)} name="logCorrection" />}
                    label="Provést korekci knihy jízd"
                  />
                </FormGroup>
                {checked ? (
                  <FormGroup row>
                    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Korekce jízd k datu"
                        format="DD.MM.YYYY"
                        value={selectedDate}
                        onChange={newDate => setDate(newDate)}
                        KeyboardButtonProps={{
                          'aria-label': 'změnit datum',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormGroup>
                ) : null}
                <FormGroup row className={classes.saveButton}>
                  <div className={classes.wrapper}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.saveButton}
                      disabled={loading}
                      onClick={handleButtonClick}>
                      Provést
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </FormGroup>
              </React.Fragment>
            )}
          </CardContent>
        </Card>
      </Popover>
    </div>
  );
};

export default TachoPopover;
