/** @format */

import React from 'react';
import { ALL_ASSETS_MEM_ID } from '../../../apollo/query/AssetQuery';
import { Query } from '@apollo/client/react/components';
import AssetCard from './AssetCard/AssetCard';
import AssetGroup from './AssetGroup/AssetGroup';
import { useReactiveVar } from '@apollo/client';
import { groupsEnabledVar } from '../../../apollo/client';

const AssetCards = () => {
  const groupsEnabled = useReactiveVar(groupsEnabledVar);
  return (
    groupsEnabled === true ? <AssetGroup /> : <Query query={ALL_ASSETS_MEM_ID}>
      {({ data }) => {
        if (typeof data !== undefined && data.assetsInMem && data.assetsInMem.length > 0) {
          return data.assetsInMem.map(x => <AssetCard key={x.id} assetId={x.id} />);
        }

        return null;
      }}
    </Query>
  );
};

export default React.memo(AssetCards);

