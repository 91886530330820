/** @format */

import React, {Component} from 'react';
import {Logo} from '../components/Icons';
import Btn from '../components/Btn';
import {Link} from 'react-router-dom';
import ResposiveDialog from './ResponsiveDialog';
import {PASSWORD_RESET, CHECK_STATUS, CONFIRM_LINK} from '../apollo/query/PasswordReset';
import {Query, ApolloConsumer} from '@apollo/client/react/components';
import FinalResetForm from './FinalResetForm';
import ConfirmDialog from './ConfirmDialog';

class PasswordResetApollo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorForm: false,
      loadingProgress: false,
      key: Math.random(),
      waitingForConfirm: false,
      confirmDialog: false,
      graphQLToken: '',
      validToken: '',
    };
  }

  handleChange(event) {
    const {name, value} = event.target;
    this.setState({[name]: value});
  }

  handleClick() {
    // send email with confirmation link
    this.props.apolloClient
      .query({query: PASSWORD_RESET, variables: {email: this.state.email}})
      .then(({data, error, loading}) => {
        if (loading) return null;
        if (error) console.log(error);
        if (!data) return null;

        if (data.passwordReset.status === 'emailNotFound') {
          this.setState({
            errorForm: 'Zadaný email neexistuje.',
            key: Math.random(),
            graphQLToken: '',
            loadingProgress: false,
          });
          return null;
        }

        if (data.passwordReset.status === 'waiting' && data.passwordReset.token) {
          this.setState({
            errorForm: false,
            waitingForConfirm: true,
            graphQLToken: data.passwordReset.token,
            key: Math.random(),
            loadingProgress: true,
            confirmDialog: false,
          });
        }
      });
  }

  hideError(event) {
    event.preventDefault();
    this.setState({email: '', errorForm: false});
  }

  componentDidMount() {
    // is this confirmation link or not?
    if (this.props.match.params.token) {
      this.setState({confirmDialog: true});
      // confirm token from email
      this.props.apolloClient
        .query({
          query: CONFIRM_LINK,
          variables: {emailToken: this.props.match.params.token},
        })
        .then(({data, error, loading}) => {
          if (loading) return null;
          if (error) console.log(error);
          if (!data) return null;

          if (data.confirmPasswordReset.status === 'linkIsNotValid') {
            this.setState({
              errorForm: 'Vypršela platnost odkazu.',
              key: Math.random(),
              graphQLToken: '',
              loadingProgress: false,
              confirmDialog: false,
            });
            return null;
          }

          if (data.confirmPasswordReset.status === 'tokenAlreadyUsed') {
            this.setState({
              errorForm: 'Heslo bylo již resetováno. Odkaz je neplatný.',
              key: Math.random(),
              graphQLToken: '',
              loadingProgress: false,
              confirmDialog: false,
            });
            return null;
          }

          if (data.confirmPasswordReset.status === 'valid' && data.confirmPasswordReset.token) {
            this.setState({validToken: data.confirmPasswordReset.token});
          }
        });
    }
  }

  pollingHandler(data) {
    if (data && data.passwordResetStatus && data.passwordResetStatus.status === 'valid') {
      this.setState({validToken: data.passwordResetStatus.token});
    }
  }

  goBackWithUsedToken(error) {
    this.setState({
      errorForm: error,
      validToken: '',
      key: Math.random(),
      graphQLToken: '',
      loadingProgress: false,
      confirmDialog: false,
      waitingForConfirm: false,
    });
  }

  render() {
    if (this.state.validToken !== '') {
      return <FinalResetForm token={this.state.validToken} goBackWithUsedToken={this.goBackWithUsedToken.bind(this)} />;
    }

    if (this.state.confirmDialog) {
      return <ConfirmDialog />;
    }

    return (
      <Query
        query={CHECK_STATUS}
        variables={{email: this.state.email, token: this.state.graphQLToken}}
        pollInterval={1200}
        onCompleted={this.pollingHandler.bind(this)}>
        {() => {
          return (
            <div className="login-wrapper" key={this.state.key}>
              {this.state.waitingForConfirm && <ResposiveDialog email={this.state.email} />}
              <Logo />
              <div
                key={this.state.key}
                className={'login-form center-block' + (this.state.errorForm ? ' shake login-form--border-error' : '')}>
                <h1 className="login-form--h1">Reset hesla</h1>
                <div className="input-field">
                  <input
                    id="email"
                    name="email"
                    className="input--white"
                    value={this.state.email}
                    type="email"
                    onChange={this.handleChange.bind(this)}
                    required
                  />
                  <label htmlFor="email">Email</label>
                  <label htmlFor="email" className="error">
                    zadejte platný email
                  </label>
                </div>

                <Btn
                  handleClick={this.handleClick.bind(this)}
                  btnName="Resetovat heslo"
                  loading={this.state.loadingProgress}
                />

                <br />
                <br />
                <Link className="login-form--a" to={`./login`}>
                  Zpět
                </Link>
              </div>
              {this.state.errorForm ? (
                <div
                  className="login-form login-form--error-box center-block login-form--border-error"
                  onClick={this.hideError.bind(this)}>
                  {this.state.errorForm}
                </div>
              ) : null}
            </div>
          );
        }}
      </Query>
    );
  }
}

const PasswordReset = props => (
  <ApolloConsumer>{client => <PasswordResetApollo {...props} apolloClient={client} />}</ApolloConsumer>
);

export {PasswordReset};
