/** @format */

import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, pdf } from '@react-pdf/renderer';
import Logo from './images/Logo';
import { TRAVEL_EXPENSES } from '../../apollo/query/TravelExpenses';
import client from '../../apollo/client';
var moment = require('moment-timezone');
//moment.tz.setDefault('Europe/Budapest');

Font.register({
  family: 'Roboto',
  src: process.env.PUBLIC_URL + 'roboto.regular.ttf',
});

const generatePDFTravelExpenses = async ({ driver, range }) => {
  let { data } = await client.query({ query: TRAVEL_EXPENSES, variables: { driverId: driver.id, start: range.startDate, stop: range.endDate } });
  console.log(data);
  let drives = [];
  //let trips = [];
  let borderCross = [];
  let borderCrossTable = [];
  let totalExpenses = [];

  let b = [];
  for (let i = data.travelExpenses.length - 1; i >= 0; i--) {
    let x = data.travelExpenses[i];

    for (let key = 0; key < x.data.borderCross.length; key++) {
      borderCross.push(
        <View key={`${i}-${key}`} style={{ flexDirection: 'row' }} wrap={false}>
          <View style={{ flexDirection: 'row', flexBasis: '400pt' }}>
            <View style={{ flexDirection: 'row', marginLeft: '10pt', flexBasis: '180pt' }}>
              <Text>Z {x.data.borderCross[key].from}</Text>
            </View>
            <View style={{ flexDirection: 'row', marginLeft: '10pt', flexBasis: '180pt' }}>
              <Text>do {x.data.borderCross[key].to}</Text>
            </View>
            <View style={{ flexDirection: 'row', marginLeft: '10pt', flexBasis: '150pt' }}>
              <Text>datum {x.data.borderCross[key].time}</Text>
            </View>
          </View>
        </View>
      );
    }

    if (borderCross.length > 35 || i === 0) {
      borderCrossTable.push(
        <View wrap={false}>
          <View wrap={false} style={styles.dayHeader}>
            <Text style={{ padding: '5pt', fontSize: '10pt', flexGrow: 1 }}>
              Překročení hranic a definovaných zón v období {moment(range.startDate).format('DD.MM.YYYY')} 00:00 -{' '}
              {moment(range.endDate).format('DD.MM.YYYY')} 23:59
            </Text>
          </View>
          <View wrap={false}>
            <View
              key={i}
              wrap={false}
              style={{
                flexDirection: 'column',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                borderLeft: '1pt solid black',
                borderRight: '1pt solid black',
                borderBottom: '1pt solid black',
                padding: '5pt',
                fontSize: '14pt',
              }}>
              {borderCross}
            </View>
          </View>
        </View>
      );
      borderCross = [];
    }

    let counter = 0;

    for (let key = 0; key < x.data.travelExpenses.total.length; key++) {
      if (x.data.travelExpenses.total[key].country.length === 3) {
        counter++;
        if (x.data.travelExpenses.total[key].compensation > 0) {
          let currency = x.data.travelExpenses.total[key].currency;
          let index = totalExpenses.findIndex(c => c.currency === currency);
          if (index > -1) {
            totalExpenses[index].summary += x.data.travelExpenses.total[key].compensation;
          } else {
            totalExpenses.push({ currency: currency, summary: x.data.travelExpenses.total[key].compensation });
          }
        }

        b.push(
          <View
            wrap={false}
            key={`${i}-${counter}-${key}`}
            style={{
              flexDirection: 'row',
              margin: '5pt 10pt 5pt 10pt',
              backgroundColor: 'white',
              justifyContent: 'flex-start',
              fontSize: '10pt',
            }}>
            <Text style={{ padding: '5pt', flexBasis: '90pt' }}>
              {counter === 1 ? x.date.replace('-', '.').replace('-', '.') : ''}
            </Text>
            <View style={{ flexDirection: 'column', marginLeft: '10pt', flexBasis: '100pt' }}>
              <Text>
                {x.data.travelExpenses.total[key].country === 'CZE' ? x.data.travelExpenses.total[key].duration : ''}
              </Text>
              <Text>
                {x.data.travelExpenses.total[key].country === 'CZE'
                  ? x.data.travelExpenses.total[key].compensation > 0
                    ? `${x.data.travelExpenses.total[key].compensation} CZK`
                    : '0 CZK'
                  : ''}
              </Text>
            </View>
            <View style={{ flexDirection: 'column', marginLeft: '10pt', flexBasis: '100pt' }}>
              <Text>
                {x.data.travelExpenses.total[key].country !== 'CZE' ? x.data.travelExpenses.total[key].country : ''}
              </Text>
              <Text></Text>
            </View>
            <View style={{ flexDirection: 'column', marginLeft: '10pt', flexBasis: '100pt' }}>
              <Text>
                {x.data.travelExpenses.total[key].country !== 'CZE' ? x.data.travelExpenses.total[key].tarif : ''}{' '}
                {x.data.travelExpenses.total[key].country !== 'CZE' ? x.data.travelExpenses.total[key].currency : ''}
              </Text>
              <Text>
                {x.data.travelExpenses.total[key].country !== 'CZE'
                  ? x.data.travelExpenses.total[key].applicable === true
                    ? x.data.travelExpenses.total[key].coeficient
                    : '0'
                  : ''}
              </Text>
            </View>
            <View style={{ flexDirection: 'column', marginLeft: '10pt', flexBasis: '100pt' }}>
              <Text>
                {x.data.travelExpenses.total[key].country !== 'CZE' ? x.data.travelExpenses.total[key].duration : ''}
              </Text>
              <Text>
                {x.data.travelExpenses.total[key].country !== 'CZE'
                  ? (x.data.travelExpenses.total[key].applicable === true
                    ? `${x.data.travelExpenses.total[key].compensation}`
                    : '0') + ` ${x.data.travelExpenses.total[key].currency}`
                  : ''}
              </Text>
            </View>
          </View>
        );
      }
    }
    console.log('b run', i);
    if (b.length > 17 || i === 0) {
      console.log('b push');
      drives.push(
        <View key={i} wrap={false}>
          <View style={styles.dayHeader}>
            <Text style={{ padding: '5pt', fontSize: '10pt', flexBasis: '90pt' }}>Den</Text>
            <View style={{ flexDirection: 'column', marginLeft: '10pt', flexBasis: '100pt', fontSize: '10pt' }}>
              <Text>Pobyt v tuzemsku</Text>
              <Text>Stravné v tuzemsku</Text>
            </View>
            <View style={{ flexDirection: 'column', marginLeft: '10pt', flexBasis: '100pt', fontSize: '10pt' }}>
              <Text>Pobyt v zahraničí</Text>
              <Text></Text>
            </View>
            <View style={{ flexDirection: 'column', marginLeft: '10pt', flexBasis: '100pt', fontSize: '10pt' }}>
              <Text>Tarif stravného</Text>
              <Text>Aplikovaná sazba</Text>
            </View>
            <View style={{ flexDirection: 'column', marginLeft: '10pt', flexBasis: '100pt', fontSize: '10pt' }}>
              <Text>Pobyty v zahraničí</Text>
              <Text>Stravné v zahraničí</Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'column',
              backgroundColor: 'white',
              justifyContent: 'space-between',
              borderLeft: '1pt solid black',
              borderRight: '1pt solid black',
              borderBottom: '1pt solid black',
            }}>
            {b}
          </View>
        </View>
      );
      b = [];
    }
  }

  //let total = 0;

  const Doc = () => (
    <Document title="Kniha jízd" creator="GPSON">
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.name}>{data.travelExpenses[0].companyName ? data.travelExpenses[0].companyName : ''}</Text>
          <Text style={styles.date}>
            {moment(range.startDate).format('DD.MM.YYYY')} 00:00 - {moment(range.endDate).format('DD.MM.YYYY')} 23:59
          </Text>
          <Logo style={styles.image} />
        </View>
        <View style={styles.licensePlate}>
          <Text style={styles.licensePlateText}>{driver.name}</Text>
        </View>
        <View wrap={false}>
          <View style={styles.dayHeader}>
            <Text style={{ padding: '5pt', fontSize: '10pt', flexGrow: 1 }}>Součet</Text>
          </View>
          <View
            style={{
              flexDirection: 'column',
              backgroundColor: 'white',
              justifyContent: 'space-between',
              borderLeft: '1pt solid black',
              borderRight: '1pt solid black',
              borderBottom: '1pt solid black',
              padding: '14pt',
              fontSize: '14pt',
            }}>
            {totalExpenses.map((x, key) => {
              if (x.currency === 'EUR') {
                //total += Math.round(((x.summary * 100) / 100) * 24.840);
              } else if (x.currency === 'CZK') {
                //total += Math.round(x.summary * 100) / 100;
              }
              return x.currency === 'EUR' ? (
                <Text key={key}>
                  {Math.round(x.summary * 100) / 100} {x.currency} {/*kurz ČNB dne 20.3.2022 24.840 CZK ={' '}
                  {Math.round(((x.summary * 100) / 100) * 24.840)} CZK*/}
                </Text>
              ) : (
                <Text key={key}>
                  {Math.round(x.summary * 100) / 100} {x.currency}
                </Text>
              );
            })}
            {/*<Text>Celkem: {total} CZK</Text>*/}
          </View>
        </View>
        {drives}
        {borderCrossTable}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
      </Page>
    </Document>
  );

  let blob = await pdf(<Doc />).toBlob();

  let url = window.URL.createObjectURL(blob);
  return url;
};

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    fontFamily: 'Roboto',
    padding: '12pt 12pt 19pt 12pt',
    border: '1pt solid black',
  },
  header: {
    flexDirection: 'row',
    margin: '10pt 0pt 5pt 0pt',
    flexBasis: 0.2,
    borderBottom: '1pt solid black',
    borderColor: 'black',
    justifyContent: 'space-between',
  },
  name: { backgroundColor: '#D3D3D3', padding: '5pt', flexBasis: '180pt' },
  date: { padding: '5pt' },
  image: {},
  licensePlate: { flexDirection: 'row', flexBasis: 0.2, justifyContent: 'space-between' },
  licensePlateText: { backgroundColor: 'white', padding: '5pt', flexBasis: '250pt', fontSize: '15pt' },
  dayHeader: {
    flexDirection: 'row',
    margin: '5pt 0pt 0pt 0pt',
    flexBasis: 0.2,
    backgroundColor: '#D3D3D3',
    justifyContent: 'flex-start',
    borderLeft: '1pt solid black',
    borderTop: '1pt solid black',
    borderRight: '1pt solid black',
  },
  tripRowDay: { fontSize: '10pt', flexBasis: 0.8 },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

export default generatePDFTravelExpenses;
