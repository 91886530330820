/** @format */

import React, {Component} from 'react';
import {Query} from '@apollo/client/react/components';
import {SHOW_HIDE_ALL_MARKERS} from '../../../apollo/mutation/CardMutation';
import 'simplebar';
import 'simplebar/dist/simplebar.min.css';
import {SIDE_NAVS} from '../../../apollo/query/SideNav';
import AssetCards from './AssetCards';
import {withApollo} from '@apollo/client/react/hoc';

class CollapsibleMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      markersAll: true,
    };
  }

  checkAll() {
    this.props.client.mutate({
      mutation: SHOW_HIDE_ALL_MARKERS,
      variables: {state: !this.state.markersAll},
    });
    this.setState({markersAll: !this.state.markersAll});
  }

  render() {
    return (
      <Query query={SIDE_NAVS}>
        {({data}) => {
          let navSetting = data.sideNavs.find(x => x.name === 'collapsible');
          return (
            <div
              data-simplebar
              style={{
                width: navSetting.width,
                ...this.props.style,
                height: '100%',
                pointerEvents: 'none',
              }}>
              <ul style={{width: '100%', pointerEvents: 'all'}} className={this.props.className}>
                <div className="asset-card__header-container asset-card__header-container--small">
                  <div className="asset-card__allOnOff">
                    {this.state.markersAll ? 'Vypnout všechny' : 'Zapnout všechny'}
                  </div>
                  <div className="switch toggler right">
                    <label>
                      <input defaultChecked={true} type="checkbox" onChange={this.checkAll.bind(this)} />
                      <span className="lever"></span>
                    </label>
                  </div>
                </div>
                <AssetCards collapsibleWidth={navSetting.width} />
              </ul>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default React.memo(withApollo(CollapsibleMenu));
