/** @format */

import {gql} from '@apollo/client';

const ASSET_SUBSCRIPTION = gql`
  subscription assetChange($id: String!) {
    assetChange(assetId: $id) {
      id
      totalKm
      totalKmCanBus
      speed
      ignition
      lastUtime
      address
      externalVoltage
      gsmSignal
      rpm
      visSat
      todayKm
      todayTime
      weight
      axleWeight1
      axleWeight2
      __typename
      latLngGrid {
        id
        __typename
        lat
        lng
        utime
        speed
      }
    }
  }
`;

export {ASSET_SUBSCRIPTION};
