/** @format */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import 'moment/locale/cs';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PlacesTable from './PlacesTable';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

moment.locale('cs');

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    margin: theme.spacing(2),
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  datePicker: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  pointer: {
    cursor: 'pointer',
    marginRight: theme.spacing(1),
    minHeight: '50px',
    verticalAlign: 'middle',
    lineHeight: '50px',
    userSelect: 'none',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const Places = props => {
  /* let workingHoursJson = props.driver && props.driver.workingHours ? props.driver.workingHours : {};

  let workingHoursParsed;

  try {
    workingHoursParsed = JSON.parse(workingHoursJson);
  } catch {
    workingHoursParsed = {};
  }*/

  const classes = useStyles();

  const [doneOpen, setDoneOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);

  const handleDoneClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setDoneOpen(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorOpen(false);
  };

  return (
    <div>
      <Dialog fullScreen TransitionComponent={Transition} open={props.open}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" aria-label="Close" onClick={props.handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {props.driver.name}, známá místa - nepočítat diety
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container>
          <Grid container>
            <Grid item xs={12} sm={8} lg={5} className={classes.root}>
              <PlacesTable driver={props.driver} />
            </Grid>
          </Grid>
        </Grid>
        <Snackbar open={doneOpen} autoHideDuration={3000} onClose={handleDoneClose}>
          <Alert onClose={handleDoneClose} severity="success">
            Uloženo
          </Alert>
        </Snackbar>
        <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleErrorClose}>
          <Alert onClose={handleErrorClose} severity="error">
            Při ukládání nastala chyba
          </Alert>
        </Snackbar>
      </Dialog>
    </div>
  );
};

export default Places;
