/** @format */

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Query} from '@apollo/client/react/components';
import {ALL_ASSETS_MEM} from '../../../apollo/query/AssetQuery';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {Alert} from '@material-ui/lab';
import axios from 'axios';
import writeOpen from '../../../filesystem/writeOpen';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
//import Export from '../../../exports/Export';
// date picker
import 'moment/locale/cs';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
//import Modal from '@material-ui/core/Modal';

moment.locale('cs');

const styles = theme => ({
  root: {
    display: 'block',
    width: '100%',
  },

  keyboardDatePicker: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
    flexDirection: 'row',
  },
  paper: {
    position: 'absolute',
    display: 'flex',
    width: '85%',
    height: '85%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

class TripsExport extends React.Component {
  constructor(props) {
    super(props);
    this.assetsInMem = {};

    this.state = {
      private: true,
      business: true,
      checked: {},
      selectedStart: new Date(new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0)), // 00:00
      selectedEnd: new Date(new Date().setHours(23, 59, 0, 0)), // 23:59
      alert: null,
      exportType: 'PDF',
      exportLoading: false,
      assetsInMem: [],
      toOneFile: 'manyFiles',
      fileExporterMounted: false,
    };
  }

  render() {
    const {classes} = this.props;
    /*
    if (this.state.fileExporterMounted) {
      return (
        <Modal
          open={this.state.fileExporterMounted}
          onClose={() => this.setState({fileExporterMounted: false})}
          aria-labelledby="export knihy jizd"
          aria-describedby="export knihy jizd">
          <div className={classes.paper}>
            <Export />
          </div>
        </Modal>
      );
    }*/

    return (
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="cs">
        <div className={classes.root}>
          <Typography variant="h5" gutterBottom>
            Typ jízd
          </Typography>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.business}
                  onChange={() => this.setState({business: !this.state.business})}
                  value="checkedB"
                  color="primary"
                />
              }
              label="Firemní"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.private}
                  onChange={() => this.setState({private: !this.state.private})}
                  value="checkedB"
                  color="primary"
                />
              }
              label="Soukromé"
            />
          </FormGroup>
          <Typography variant="h5" gutterBottom>
            Jednotky
          </Typography>
          <FormGroup row>
            <Query query={ALL_ASSETS_MEM}>
              {({data}) => {
                if (data && data.assetsInMem && data.assetsInMem.length > 0) {
                  return data.assetsInMem.map(x => {
                    let id = x.id;
                    this.assetsInMem[id] = {...x};
                    return (
                      <FormControlLabel
                        key={x.id}
                        control={
                          <Checkbox
                            checked={!!this.state.checked[x.id]}
                            onChange={() =>
                              this.setState({checked: {...this.state.checked, [id]: !this.state.checked[x.id]}})
                            }
                            value="checkedB"
                            color="primary"
                          />
                        }
                        label={x.name}
                      />
                    );
                  });
                }

                return null;
              }}
            </Query>
          </FormGroup>
          <Typography variant="h5" gutterBottom>
            Datum
          </Typography>
          <Grid container justify="flex-start">
            <DatePicker
              value={this.state.selectedStart}
              onChange={date => this.handleStartDateChange(date)}
              className={classes.keyboardDatePicker}
              format="DD.MM.YYYY"
              label="Začátek"
            />
            <DatePicker
              value={this.state.selectedEnd}
              onChange={date => this.handleEndDateChange(date)}
              className={classes.keyboardDatePicker}
              format="DD.MM.YYYY"
              label="Konec"
            />
          </Grid>
          <RadioGroup
            aria-label="typ"
            name="typ"
            className={classes.group}
            value={this.state.exportType}
            onChange={event => this.setState({exportType: event.target.value})}
            defaultChecked>
            <FormControlLabel value="PDF" control={<Radio />} label="PDF" labelPlacement="start" />
            <FormControlLabel value="Excel" control={<Radio />} label="Excel" labelPlacement="start" />
          </RadioGroup>
          <RadioGroup
            aria-label="typ"
            name="typ"
            className={classes.group}
            value={this.state.toOneFile}
            onChange={event => this.setState({toOneFile: event.target.value})}
            defaultChecked>
            <FormControlLabel value="oneFile" control={<Radio />} label="Do jednoho souboru" labelPlacement="start" />
            <FormControlLabel value="manyFiles" control={<Radio />} label="Každý zvlášť" labelPlacement="start" />
          </RadioGroup>
          {this.state.alert}
          <DialogActions>
            <div className={classes.placeholder}>
              <Fade
                in={this.state.exportLoading}
                style={{
                  transitionDelay: this.state.exportLoading ? '800ms' : '0ms',
                }}
                unmountOnExit>
                <CircularProgress />
              </Fade>
            </div>
            <Button variant="contained" color="primary" onClick={this.export.bind(this)} className={classes.button}>
              Exportovat
            </Button>
          </DialogActions>
        </div>
      </MuiPickersUtilsProvider>
    );
  }

  async export() {
    // check date validity
    if (this.state.selectedStart > this.state.selectedEnd) {
      this.setState({
        alert: (
          <Alert severity="error" onClick={() => this.setState({alert: null})}>
            Datum začátku musí být před koncem.
          </Alert>
        ),
      });
      return null;
    } else if (this.state.selectedStart > new Date()) {
      this.setState({
        alert: (
          <Alert severity="error" onClick={() => this.setState({alert: null})}>
            Datum začátku musí být nižší, než aktuální datum.
          </Alert>
        ),
      });
      return null;
    } else if (this.state.private === false && this.state.business === false) {
      this.setState({
        alert: (
          <Alert severity="error" onClick={() => this.setState({alert: null})}>
            Není vybrán typ jízdy!
          </Alert>
        ),
      });
      return null;
    }

    // check number of assets
    let checkedNums = [];
    for (var key in this.state.checked) {
      if (this.state.checked[key] === true) {
        checkedNums.push(key);
      }
    }
    if (checkedNums.length === 0) {
      this.setState({
        alert: (
          <Alert severity="error" onClick={() => this.setState({alert: null})}>
            Není vybrána žádná jednotka
          </Alert>
        ),
      });
      return null;
    }

    /*if (this.state.exportType === 'PDF') {
      this.setState({fileExporterMounted: true});
      return null;
    }*/
    // start exporting
    this.setState({exportLoading: true});

    if (this.state.toOneFile === 'manyFiles') {
      for (let key in this.state.checked) {
        if (this.state.checked[key] === true) {
          await new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
            axios({
              url: 'https://gql.gpson.cz:4001',
              method: 'GET',
              responseType: 'blob',
              params: {
                assetId: key,
                startUtime: moment(this.state.selectedStart).unix(),
                stopUtime: moment(this.state.selectedEnd).unix(),
                format: this.state.exportType,
                private: this.state.private,
                toOneFile: this.state.toOneFile,
                business: this.state.business,
                name: this.assetsInMem[key].name,
                sticker: this.assetsInMem[key].sticker,
              },
              timeout: 120000,
            }).then(response => {
              writeOpen({
                blob: response.data,
                exportType: this.state.exportType,
                exportName: this.state.selectedEnd,
                fileName: 'kniha_jizd_' + this.assetsInMem[key].name,
                doneCallback: resolve,
              });
            });
          });
        }
      }
    } else {
      if (this.state.checked[key] === true) {
        await new Promise((resolve, reject) => {
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
          axios({
            url: 'https://gql.gpson.cz:4001',
            method: 'GET',
            responseType: 'blob',
            params: {
              assetId: this.state.checked,
              startUtime: moment(this.state.selectedStart).unix(),
              stopUtime: moment(this.state.selectedEnd).unix(),
              format: this.state.exportType,
              private: this.state.private,
              toOneFile: this.state.toOneFile,
              business: this.state.business,
              name: this.assetsInMem[key].name,
              sticker: this.assetsInMem[key].sticker,
            },
            timeout: 120000,
          }).then(response => {
            writeOpen({
              blob: response.data,
              exportType: this.state.exportType,
              exportName: this.state.selectedEnd,
              fileName: 'kniha_jizd_' + this.assetsInMem[key].name,
              doneCallback: resolve,
            });
          });
        });
      }
    }

    this.setState({exportLoading: false});
  }

  handleStartDateChange = date => {
    this.setState({selectedStart: new Date(new Date(date).setHours(0, 0, 0, 0))});
  };

  handleEndDateChange = date => {
    this.setState({selectedEnd: new Date(new Date(date).setHours(23, 59, 0, 0))});
  };
}

export default withStyles(styles)(TripsExport);
