/** @format */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { GROUPS, MUTATE_GROUPS } from '../../../../apollo/query/Group';
import { useMutation } from '@apollo/client';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import cloneDeep from 'lodash/cloneDeep';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AddGroupDialog(props) {
  const { onClose, open, groups } = props;
  const classes = useStyles();
  const [groupName, setGroupName] = useState('');
  const [addGroup] = useMutation(MUTATE_GROUPS, { refetchQueries: [{ query: GROUPS }] });
  const [openError, setOpenError] = React.useState(false);

  const handleClose = () => {
    onClose(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  const handleSave = () => {
    if (groups && groups.length > 0 && groups.find(x => x.name === groupName)) {
      setOpenError(true);
      return;
    }

    let newGroups = cloneDeep(groups);

    if (newGroups && newGroups.length > 0) {
      newGroups.forEach(x => { delete x.__typename });
    } else {
      newGroups = [];
    }

    newGroups.push({ name: groupName, icon: '', assets: [] });
    addGroup({ variables: { groupsInput: newGroups } }).then(() => onClose(false));
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Přidat skupinu</DialogTitle>
      <form className={classes.root} noValidate autoComplete="off">
        <div>
          <TextField id="standard-basic" label="Název" value={groupName} onChange={e => setGroupName(e.target.value)} />
        </div>
        <Button variant="contained" color="secondary" onClick={handleSave}>
          Přidat
        </Button>
      </form>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error">
          Skupina s tímto názvem již existuje!
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

AddGroupDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default AddGroupDialog;
