/** @format */

import L from 'leaflet';
import {IconCarSvg} from '../../../components/Icons.js';
import {ASSET_MEM, ASSET_TEMPERATURE} from '../../../apollo/query/AssetQuery';
import {SIDE_NAVS} from '../../../apollo/query/SideNav';
import {AnimatedMarker} from './AnimatedMarker';
import {CARD_ACTION} from '../../../apollo/mutation/CardMutation';
import {SignalIconSVG, TemperatureIconSVG, HumidityIconSVG, TemperatureBattIconSVG} from '../../../components/Icons';
//SatteliteIconSVG,
class Marker {
  constructor({id, client, group, history, location}) {
    this.client = client;
    this.id = id;
    this.group = group;
    this.marker = null;
    this.isZooming = false;
    this.history = history;
    this.location = location;
    this.subscription = this.subsribe();
    this.name = '';

    // angle arrow body styling degress:style
    this.arrowStyles = {
      90: {
        left: 'auto',
        top: '-10px',
        right: '-10px',
        bottom: 'auto',
      },
      180: {
        left: 'auto',
        top: 'auto',
        right: '-10px',
        bottom: '-10px',
      },
      270: {
        left: '-10px',
        top: 'auto',
        right: 'auto',
        bottom: '-10px',
      },
      360: {
        left: '-10px',
        top: '-10px',
        right: 'auto',
        bottom: 'auto',
      },
    };
  }

  subsribe() {
    //start data subscription from the store
    return this.client
      .watchQuery({
        query: ASSET_MEM,
        variables: {id: this.id},
      })
      .subscribe(({data}) => {
        if (data && data.assetInMem) {
          //mutate when new data arrived
          if (!this.marker || this.marker === 'incomplete') {
            this.create({data});
          } else {
            this.mutate({data});
          }

          if (data && data.assetInMem && data.assetInMem.markerVisible) {
            this.handlePopup({action: 'mutate', data});
          }
        }
      });
  }

  create({data}) {
    const marker = data.assetInMem;
    this.name = marker.name;
    //if the marker doesn't have any gps positions, not able to add to the map
    if (!marker.latLngGrid || marker.latLngGrid < 1) {
      this.marker = 'incomplete';
      return null;
    }

    // default null when icons are disabled by user
    let iconCar = IconCarSvg('placeholder');
    // create marker divICON
    if (marker.iconVisible === true) {
      iconCar = IconCarSvg(marker.marker);
    }

    // default point marker, no graphic
    let html = '<div class="placeholder-map">' + iconCar + '</div>';

    if (marker.iconVisible === true) {
      // if used has set icon visibility, create icon
      html =
        '<div class="marker-map marker-map--jumping">' +
        iconCar +
        '<div class="marker-map__sticker-plate"><span class="marker-map__sticker-plate__name">' +
        marker.name +
        '</span><div class="marker-map__sticker-plate__arrow"></div></div></div>';
    }

    var myIcon = L.divIcon({
      html,
      className: 'marker-container',
    });

    // create Leaflet marker
    this.marker = new AnimatedMarker(marker.latLngGrid, {icon: myIcon}, marker.lastUtime, marker.id);

    //unbind all defaults from marker and bind custom events and toolbox
    this.bindEventsOnMarker.call(this, {data});

    //Add it to the map
    if (marker.markerVisible) {
      this.marker.addTo(this.group);

      // set the actual color
      this.mutateIconColor({data: marker});
      this.mutateAngleArrow();
    }
  }

  mutate({data}) {
    const marker = data.assetInMem;
    // check is there is no change in user setting
    if (
      marker.markerVisible === true &&
      marker.iconVisible === true &&
      this.marker._icon &&
      this.marker._icon.getElementsByClassName('placeholder-map').length > 0
    ) {
      this.deleteMarker();
      this.create({data});
    }

    if (
      marker.markerVisible === true &&
      marker.iconVisible === false &&
      this.marker._icon.getElementsByClassName('placeholder-map').length === 0
    ) {
      this.deleteMarker();
      this.create({data});
    }

    if (this.name !== marker.name && this.marker && this.marker._icon) {
      // name has been changed
      const stickerPlateDiv = this.marker._icon.getElementsByClassName('marker-map__sticker-plate__name');
      stickerPlateDiv[0].innerHTML = marker.name;
      this.name = marker.name;
    }

    // mutate visibility
    if (marker.markerVisible === false) {
      this.group.removeLayer(this.marker);

      if (this.marker.timeout) {
        clearTimeout(this.marker.timeout);
      }
      this.marker.removeMarker();
      this.marker = null;
    } else if (marker.markerVisible === true) {
      //test if marker is in map group or not, search for _map object and test if it is not only empoty object, map object should have property _container
      if (!this.marker._map || !this.marker._map._container) {
        //add this marker instance into the map
        this.marker.addTo(this.group);
      }

      //mutate marker position on the map with animation
      this.marker.mutate(marker.latLngGrid);
      this.mutateIconColor({data: marker});
    }

    // mutate angle arrow
    this.mutateAngleArrow();
  }

  // this function drive icon color on the map (green, orange, red)
  mutateIconColor({data}) {
    // test if marker is visible
    if (data.markerVisible === true && this.marker._icon) {
      //const markerDiv = this.marker._icon.getElementsByClassName('marker-map');
      //const stickerPlateDiv = this.marker._icon.getElementsByClassName('marker-map__sticker-plate');
      const markerContainer = this.marker._icon;
      let {classList} = markerContainer;
      // test if marker container is rendered
      if (classList) {
        // drive marker color when running
        if (data.ignition === true && data.speed > 0 && classList.contains('marker-container--running') === false) {
          classList.add('marker-container--running');
        } else if (data.ignition === false && classList.contains('marker-container--running') === true) {
          // set asset as red - ignition is off, timeout is necessary because marker is on the map delayed by motion animation (aprox. by 13s)
          setTimeout(() => {
            classList.remove('marker-container--running');
          }, 13000);
        }

        // drive marker color when paused
        if (data.ignition === true && data.speed === 0 && classList.contains('marker-container--paused') === false) {
          classList.add('marker-container--paused');
          classList.remove('marker-container--running');
        } else if (
          (data.ignition === false || data.speed > 0) &&
          classList.contains('marker-container--paused') === true
        ) {
          // set asset as red - ignition is off, timeout is necessary because marker is on the map delayed by motion animation (aprox. by 13s)
          classList.remove('marker-container--paused');
        }

        // drive marker color when dead
        if (
          data.lastUtime < new Date().getTime() / 1000 - 1800 &&
          classList.contains('marker-container--dead') === false
        ) {
          classList.add('marker-container--dead');
          classList.remove('marker-container--running');
        } else if (
          data.lastUtime > new Date().getTime() / 1000 - 1800 &&
          classList.contains('marker-container--dead') === true
        ) {
          // set asset as red - ignition is off, timeout is necessary because marker is on the map delayed by motion animation (aprox. by 13s)
          classList.remove('marker-container--dead');
        }
      }
    }
  }

  mutateAngleArrow() {
    if (this.marker) {
      // drive angle arrow, this arrow is displayed as CSS :after pseudolement in the div sticker-plate
      let angle = this.marker.getAngle();

      let arrow = this.marker._icon.getElementsByClassName('marker-map__sticker-plate__arrow');
      if (arrow.length > 0) {
        const keys = Object.keys(this.arrowStyles);
        keys.find(key => {
          if (key > angle) {
            arrow[0].style.left = this.arrowStyles[key].left;
            arrow[0].style.top = this.arrowStyles[key].top;
            arrow[0].style.bottom = this.arrowStyles[key].bottom;
            arrow[0].style.right = this.arrowStyles[key].right;
            arrow[0].style.webkitTransform = 'rotate(' + angle + 'deg)';
            arrow[0].style.mozTransform = 'rotate(' + angle + 'deg)';
            arrow[0].style.msTransform = 'rotate(' + angle + 'deg)';
            arrow[0].style.oTransform = 'rotate(' + angle + 'deg)';
            arrow[0].style.transform = 'rotate(' + angle + 'deg)';
            return true;
          }
          return false;
        });
      }
    }
  }

  bindEventsOnMarker({data}) {
    //disable default leaflet action
    this.marker.off('click');
    this.marker.off('touch');
    this.marker.off('mouseout');
    this.marker.off('mouseover');

    //bind custom event listeners
    this.marker.on('mouseout', this.handlePopup.bind(this, {action: 'hide', data}));
    this.marker.on('click', this.handleClick.bind(this, {action: 'clickOnMarker', data}));
    this.marker.on('touch', this.handleClick.bind(this, {action: 'clickOnMarker', data}));
    this.marker.on('mouseover', this.handlePopup.bind(this, {action: 'create', data}));
  }

  handleClick({action, data}) {
    //activate marker as triggered
    this.client.mutate({
      mutation: CARD_ACTION,
      variables: {id: data.assetInMem.id, action: 'triggedNoFit'},
    });

    if (document.documentElement.clientWidth > 900) {
      this.marker.off('mouseout');
    }
    //open or close popup window
    this.handlePopup.call(this, {action: action ? action : 'click', data});
  }

  async handlePopup({action, data}) {
    // open a side nav on mobile
    if (action === 'clickOnMarker' && this.clickExecuted === true && typeof cordova === 'object') {
      this.openSideNav.call(this);
    }

    // map is zooming, return
    if (action === 'hide') {
      this.closed = true;
      if (this.popup) {
        this.popup.closePopup();
        this.popup = null;
        this.clickExecuted = null;
      }

      return;
    } else if (action === 'create') {
      this.closed = false;
    }

    if (this.marker._isZooming === true) {
      return;
    }

    const {assetInMem} = data;
    let markerPopup;

    //perform only if a tooltip is visible
    if (
      (action === 'mutate' && this.popup) ||
      action === 'create' ||
      action === 'click' ||
      action === 'clickOnMarker'
    ) {
      //init popup content only if is or will be open
      let temperatures = '';

      if (assetInMem && assetInMem.modules && !assetInMem.modules.search('temperature') && this.client) {
        await this.client
          .query({
            query: ASSET_TEMPERATURE,
            variables: {assetId: assetInMem.id},
            fetchPolicy: 'network-only',
          })
          .then(x => {
            if (x && x.data && x.data.temperatures) {
              temperatures =
                "<div class='line'>" + TemperatureIconSVG + ' ' + x.data.temperatures[0].temperature + ' °C</div>';

              if (x.data.temperatures[0].humidity && x.data.temperatures[0].humidity > -1) {
                temperatures +=
                  "<div class='line'>" + HumidityIconSVG + ' ' + x.data.temperatures[0].humidity + ' %</div>';
              }

              if (x.data.temperatures[0].battery && x.data.temperatures[0].battery > -1) {
                temperatures +=
                  "<div class='line'>" + TemperatureBattIconSVG + ' ' + x.data.temperatures[0].battery + ' %</div>';
              }
            }
          });
      }

      if (this.closed === true) {
        return null;
      }

      let nameInPopup = '';

      if (assetInMem.iconVisible === false) {
        nameInPopup = `<div class='line'>${assetInMem.name}</div>`;
      }
      //${assetInMem.visSat===0||assetInMem.visSat>0?SatteliteIconSVG(assetInMem.visSat):''}
      // create newest popup contents
      markerPopup = `
      <div class='popup-box'>
        <div class='popup-box__left-content'>
          ${nameInPopup}
          <div class='line'>${assetInMem.speed} km/h</div>
          ${assetInMem.rpm > -1 ? `<div class='line'>${assetInMem.rpm} RPM</div>` : ''}
          ${temperatures}
          </div>
        <div class='popup-box__right-content'>
          <div class="signal">
            ${assetInMem.gsmSignal === 0 || assetInMem.gsmSignal > 0 ? SignalIconSVG[assetInMem.gsmSignal] : ''}
          </div>
        </div>
      </div>`;
    }

    if (action === 'create' || action === 'click' || action === 'clickOnMarker') {
      // open a new popup if not opened
      if (!this.popup) {
        //create popup
        this.popup = this.marker.bindPopup(markerPopup, {
          autoPan: false,
          closeButton: false,
          closeOnClick: false,
          autoClose: false,
          className: 'marker-map__popup',
        });
        this.popup.openPopup();
        //unbind all defaults from marker and bind custom events
        this.bindEventsOnMarker.call(this, {data});
        this.popup.setPopupContent(markerPopup);
        this.clickExecuted = null;
      } else if ((action === 'click' || action === 'clickOnMarker') && this.clickExecuted === true) {
        //close of opened
        this.popup.closePopup();
        this.popup = null;
        this.clickExecuted = null;
      }

      if (action === 'click' || action === 'clickOnMarker') {
        //set that action was trigged by mouseover
        this.clickExecuted = true;
      }
    } else if (action === 'mutate') {
      if (this.popup) {
        this.popup.setPopupContent(markerPopup);
      }
    }

    if (this.popup && this.clickExecuted) {
      //add border if toolbox is binded by a click
      this.popup._popup._wrapper.classList.add('marker-map__popup--border');
    }
  }

  isLoaded() {
    let that = this;
    return new Promise(resolve => {
      let checker = setInterval(() => {
        if (that.marker) {
          resolve('ok');
          clearInterval(checker);
        }
      }, 100);
    });
  }

  openSideNav() {
    const name = 'collapsible';
    //mutate sidenavs
    this.client.query({query: SIDE_NAVS}).then(async ({data}) => {
      if (data && data.sideNavs) {
        let newState = data.sideNavs.map(x => {
          let newItem = {...x};
          if (newItem.name === name) {
            newItem.visible = true;
          } else {
            newItem.visible = false;
          }
          return newItem;
        });

        if (newState[0].visible === true && document.documentElement.clientWidth < 900) {
          newState.forEach(x => {
            if (x.name === 'overlay') x.visible = true;
          });
        }
        //write to store
        await this.client.writeQuery({
          query: SIDE_NAVS,
          data: {sideNavs: newState},
        });
        if (this.location.pathname === `/${name}`) {
          this.history.push(`/`);
        } else {
          this.history.push(`/${name}`);
        }
      }
    });
  }

  deleteMarker() {
    if (this.group) {
      if (this.marker && this.marker !== 'incomplete') this.group.removeLayer(this.marker);
      if (this.marker && this.marker.removePolyline) this.marker.removePolyline();
      delete this.marker;
    }
  }
}

export default Marker;

/*

      if (data.ignition === true && this.marker._icon && markerDiv && markerDiv.length > 0) {
        if (!markerDiv[0].classList.contains('marker-map--running')) markerDiv[0].classList.add('marker-map--running');

        //mutate sticker plate border color
        if (stickerPlateDiv[0] && !stickerPlateDiv[0].classList.contains('marker-map__sticker-plate--running'))
          stickerPlateDiv[0].classList.add('marker-map__sticker-plate--running');

        if (!this.marker._icon.classList.contains('marker-container--running'))
          this.marker._icon.classList.add('marker-container--running');

        ////////////////////////////////////////
        // draw angle
        ///////////////////////////////////////
        //let current = this.marker.currentPosition();
        //let next = this.marker.nextPosition();
        // angle = this.angleFromCoordinate(next.lat, next.lng, current.lat, current.lng);

        //console.log('add', next.lat, next.lng, L, window.mapObject);

        /*var latlngs = [
        [next.lat, next.lng],
        [current.lat, current.lng],
      ];
      L.polyline(latlngs, {color: 'red'}).addTo(window.mapObject);*/
//window.mapObject.fitBounds(polyline.getBounds());

/*if (marker.id === '34') {
        console.log(current, next);
      }

      if (
        stickerPlateDiv &&
        stickerPlateDiv[0] &&
        stickerPlateDiv[0].childNodes &&
        stickerPlateDiv[0].childNodes[1]
      ) {
        //stickerPlateDiv[0].childNodes[1].innerHTML = angle;
      }

      ///////////////////////////////////////
    } else if (data.ignition === false && this.marker._icon && markerDiv && markerDiv.length > 0) {
      if (markerDiv[0].classList.contains('marker-map--running'))
        markerDiv[0].classList.remove('marker-map--running');
      //mutate sticker plate border color

      if (
        stickerPlateDiv[0] &&
        stickerPlateDiv[0].classList &&
        stickerPlateDiv[0].classList.contains('marker-map__sticker-plate--running')
      )
        stickerPlateDiv[0].classList.remove('marker-map__sticker-plate--running');

      if (this.marker._icon.classList.contains('marker-container--running'))
        this.marker._icon.classList.remove('marker-container--running');
    }
    */
