/** @format */

import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import MapContainer from './MapContainer';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {useMutation} from '@apollo/client';
import {GET_LOCATIONS, MUTATE_LOCATION} from '../../apollo/query/LocationQuery';
import client from '../../apollo/client';

const GeoMap = props => {
  const [geoJSON, setGeoJSON] = React.useState(false);
  const [locationName, setLocationName] = React.useState({
    error: false,
    value: props.edited && props.edited.name ? props.edited.name : '',
  });

  const saveCallback = ({geoJSON}) => {
    setGeoJSON(geoJSON);
  };

  const handleLocationChange = e => {
    setLocationName({
      error: e.target.value.length > 0 ? false : true,
      value: e.target.value,
    });
  };

  const [mutateLocation] = useMutation(MUTATE_LOCATION, {client});

  const finalSaveToDB = () => {
    if (locationName.error) return;

    let locationInput = {...props.edited};
    delete locationInput.companyId;
    delete locationInput.__typename;
    locationInput.name = locationName.value;
    locationInput.geoJSON = geoJSON;

    console.log(locationInput);
    mutateLocation({
      variables: {locationInput},
      refetchQueries: [{query: GET_LOCATIONS}],
    }).then(() => {
      // close and clean
      props.unmountCallback();
    });
  };

  return !geoJSON ? (
    <Dialog aria-labelledby="Přidat místo" maxWidth={false} onClose={props.onClose} open={props.open}>
      <DialogTitle id="Přidat místo">Přidat místo</DialogTitle>
      <MapContainer saveCallback={saveCallback} edited={props.edited} />
    </Dialog>
  ) : (
    <Dialog aria-labelledby="Uložit nové místo" maxWidth={false} onClose={props.onClose} open={props.open}>
      <DialogTitle id="Uložit nové místo">Uložit nové místo</DialogTitle>
      <DialogContent>
        <DialogContentText>Toto místo bude uloženo do vašeho adresáře.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Název"
          type="text"
          fullWidth
          value={locationName.value}
          error={locationName.error}
          helperText="Zadejte název"
          onChange={handleLocationChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.unmountCallback()} color="primary">
          Zrušit
        </Button>
        <Button onClick={finalSaveToDB} color="primary">
          Uložit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GeoMap;
