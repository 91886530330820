/** @format */

import {gql} from '@apollo/client';

const SIDE_NAVS = gql`
  query SIDE_NAVS {
    sideNavs @client
  }
`;

export {SIDE_NAVS};
