/** @format */

import React, {Component} from 'react';
import GoogleMutant from './GoogleLayer';
import L from 'leaflet';
import MarkerContainerObject from './Marker/MarkerContainerObject';
import PolylinesContainerObject from './Polylines/PolylinesContainerObject';
import HistoryButton from './MapControl/HistoryButton';
import {USER_INFO} from '../../apollo/query/UserQuery';
import {SIDE_NAVS} from '../../apollo/query/SideNav';
import {FIT_BOUNDS} from '../../apollo/query/MapQuery';
import {MODAL_EVENT} from '../../apollo/query/ModalEvent';
import {withApollo} from '@apollo/client/react/hoc';
import {Query} from '@apollo/client/react/components';
import {withRouter} from 'react-router';
import 'leaflet.locatecontrol';
import './LeafletLocalForage/leafletLocalForage';

class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.loadingAssets = [];
    window.L = L;

    this.state = {
      map: {},
      historyState: {startUtime: 0, stopUtime: 0, historyOn: false},
    };

    this.mapTiles = [];
  }

  loadGoogle() {
    return new GoogleMutant();
  }

  async fitBounds({bounds}) {
    let {pathname} = this.props.location;
    //read map offset from setting
    await this.props.client.query({query: SIDE_NAVS}).then(async ({data}) => {
      let active = data.sideNavs.find(x => `/${x.name}` === pathname);

      let offset = 0;

      if (active) {
        if (active.width) {
          // get 1rem in px
          offset = parseFloat(getComputedStyle(document.documentElement).fontSize);

          // multiplicate width by rems and get px
          offset *= parseFloat(active.width);

          // if there is not enought space for map, reset offset to 0
          if (offset > window.innerWidth * 0.7) {
            offset = 0;
          }
        }
      }

      let boundsValidated;

      if (bounds.getBounds) {
        boundsValidated = bounds.getBounds();
      } else if (bounds.length > 0) {
        let boundsFromArr = [];

        for (let i = 0; i < bounds.length; i++) {
          if (bounds[i].getBounds) {
            boundsFromArr.push(bounds[i].getBounds());
          } else {
            //it is a bounds array nested in an array
            for (let e = 0; e < bounds[i].length; e++) {
              boundsFromArr.push(bounds[i][e].getBounds());
            }
          }
          if (boundsFromArr.length > 0) boundsValidated = boundsFromArr;
        }
      } else if (bounds._northEast) {
        //test if it is a valid bounds object
        boundsValidated = bounds;
      }

      if (boundsValidated) {
        if (offset < 20) {
          offset = 20;
        }

        await this.map.fitBounds(boundsValidated, {
          paddingTopLeft: [offset, 20],
          paddingBottomRight: [20, 20],
          maxZoom: 14,
        });
      }
    });
  }

  render() {
    let {pathname} = this.props.location;
    return (
      <div className={'map-container ' + this.props.className}>
        <Query query={SIDE_NAVS}>
          {({data}) => {
            if (data && data.sideNavs.length > 0) {
              let overlay = data.sideNavs.find(x => x.name === 'overlay');
              if (overlay && pathname !== '/') {
                //overlay--active is added because visibility must be hidden for cursor and there is a mediaquery, it is visible only for mobile
                return (
                  <div
                    className={`overlay${overlay.visible === true ? ' overlay--active' : ''}`}
                    style={overlay.visible === true ? {opacity: 1} : {opacity: 0}}
                    onClick={() => this.overlayClickHandler()}
                  />
                );
              }
            }
            return null;
          }}
        </Query>
        <div id="mapid"></div>
        <HistoryButton />
      </div>
    );
  }

  componentWillUnmount() {
    //unsubscribe all subscriptions
    if (this.querySubscription && this.querySubscription.unsubscribe) this.querySubscription.unsubscribe();
    if (this.markerContainer && this.markerContainer.unsubscribe) this.markerContainer.unsubscribe();
  }

  componentDidMount() {
    // THIS IS NOT USED, default map is hard coded on line 141 //
    // read default map setting
    let defaultMap = localStorage.getItem('defaultMap');

    if (defaultMap) {
      //load user settings
      defaultMap = JSON.parse(defaultMap);
    }
    //////////////////////////

    // reset map setting to default Leaflet
    defaultMap = null;

    //watch for fitBounds requests
    this.querySubscription = this.props.client
      .watchQuery({
        query: FIT_BOUNDS,
      })
      .subscribe(({data}) => {
        //only one position
        if (data && data.fitBounds && data.fitBounds.length === 1 && data.fitBounds[0].lat !== 0) {
          let bounds = L.latLng(data.fitBounds[0]).toBounds(20);
          this.fitBounds({bounds});
        }

        //many bounds
        if (data && data.fitBounds && data.fitBounds.length > 1 && data.fitBounds[0].lat !== 0) {
          let bounds = L.latLngBounds(data.fitBounds);
          this.fitBounds({bounds});
        }

        if (data && data.fitBounds && data.fitBounds.length >= 1 && data.fitBounds[0].lat !== 0) {
          this.props.client.writeQuery({
            query: FIT_BOUNDS,
            data: {
              fitBounds: [{lat: 0, lng: 0, __typename: 'LatLng'}],
            },
          });
        }
      });

    let map = L.map('mapid', {zoomControl: false}).setView([49.097928, 17.550175], 5);
    this.map = map;

    //store global reference, this is used for market fitting from collapsible menu
    window.mapObject = this.map;

    let roadMap = L.tileLayer(
      process.env.NODE_ENV === 'development'
        ? 'https://api.maptiler.com/maps/streets/256/{z}/{x}/{y}@2x.png?key=ExTYaGoiNx9KfngsC2Wd'
        : 'https://api.maptiler.com/maps/streets/256/{z}/{x}/{y}@2x.png?key=vLeRydsZpHUAlYhfabWc',
      {
        attribution:
          '<a href="https://www.maptiler.com/license/maps/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
        maxZoom: 20,
        id: 'mapbox.streets',
        useCache: true,
      }
    );

    var baseMaps = {
      Mapa: roadMap,
    };

    let controlLayer = L.control
      .layers(
        baseMaps,
        {},
        {
          collapsed: true,
        }
      )
      .addTo(this.map);

    //set zoom control
    L.control.zoom({position: 'topright'}).addTo(this.map);

    //add default map if it is default
    if (!defaultMap || (defaultMap && defaultMap.name !== 'Satellite' && defaultMap.name !== 'Road')) {
      roadMap.addTo(this.map);
    }

    this.props.client.query({query: USER_INFO}).then(({data}) => {
      let satelliteMutant = null;
      let roadMutant = null;
      if (data && data.userInfo && data.userInfo.satApi !== null) {
        satelliteMutant = L.gridLayer.googleMutant({
          type: 'hybrid',
          satApi: data.userInfo.satApi,
        });

        controlLayer.addBaseLayer(satelliteMutant, 'Satellite');

        roadMutant = L.gridLayer.googleMutant({
          type: 'roadmap',
          satApi: data.userInfo.satApi,
        });

        controlLayer.addBaseLayer(roadMutant, 'Road');
      }
      //set default map
      if (defaultMap && defaultMap.name === 'Satellite' && satelliteMutant) {
        satelliteMutant.addTo(this.map);
      } else if (defaultMap && defaultMap.name === 'Road' && roadMutant) {
        roadMutant.addTo(this.map);
      }
    });

    L.control
      .locate({
        position: 'topright',
        flyTo: true,
        strings: {
          title: 'Ukaž moji polohu!',
        },
        setView: 'once',
        icon: 'geolocation-icon',
        onLocationError: error => this.handleGeolocationError.call(this, error),
        locateOptions: {
          watch: true,
          enableHighAccuracy: true,
        },
      })
      .addTo(this.map);

    //create new marker container
    this.markerContainer = new MarkerContainerObject({
      client: this.props.client,
      map: this.map,
      fitBounds: this.fitBounds.bind(this),
      location: this.props.location,
      history: this.props.history,
    });

    //create new polylines container
    this.polylineContainer = new PolylinesContainerObject({
      client: this.props.client,
      map: this.map,
      fitBounds: this.fitBounds.bind(this),
    });

    // listen to map change
    this.map.on('baselayerchange', function (e) {
      //write change to localStorage for next use
      localStorage.setItem('defaultMap', JSON.stringify({name: e.name}));
    });
  }

  // handle error when trying to locate error
  handleGeolocationError(error) {
    let {client} = this.props;
    let x;
    switch (error.code) {
      case 1:
        x = 'Uživatel zamítl požadavek Geolocation.';
        break;
      case 2:
        x = 'Informace o poloze je nedostupná.';
        break;
      case 3:
        x = 'Požadavek na polohu vypršel bez odpovědi.';
        break;
      case 4:
        x = 'Nastala neznámá chyba.';
        break;
      default:
        x = 'Nastala neznámá chyba.';
    }
    client.writeQuery({
      query: MODAL_EVENT,
      data: {
        modalsDriver: {
          id: 0,
          type: 'smallError',
          title: '',
          rand: Math.random(),
          text: x,
          __typename: 'ModalEvent',
        },
      },
    });
  }

  overlayClickHandler() {
    //mutate sidenavs
    this.props.client.query({query: SIDE_NAVS}).then(async ({data}) => {
      if (data && data.sideNavs) {
        let newState = data.sideNavs.map(x => {
          let newItem = {...x};
          //close all
          newItem.visible = false;
          return newItem;
        });

        //write to store
        await this.props.client.writeQuery({
          query: SIDE_NAVS,
          data: {sideNavs: newState},
        });

        if (this.props.location.pathname !== `/`) {
          this.props.history.push(`/`);
        }
      }
    });
  }
}

export default withRouter(withApollo(MapContainer));
