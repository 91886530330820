/** @format */

import {ALL_ASSETS_MEM} from './query/AssetQuery';
import {ALL_HISTORY_LOADING} from './query/HistoryButton';
import {FIT_BOUNDS} from './query/MapQuery';
import cloneDeep from 'lodash/cloneDeep';

export const typeDefs = `
  type Query {
    assetsInMem: [AssetInMem],
    assetInMem: AssetInMem,
    historyButtonLoader: [assetId: String],
    sideNavs: [SideNav],
    fitBounds: [LatLng],
    modalsDriver: ModalEvent,
    historyPolylinesSetting: [HistoryPolylinesSetting]
  }

  type HistoryPolylinesSetting {
    startUtime: Int,
    stopUtime: Int,
    assetId: String,
    active: Boolean
  }

  type AssetInMem {
    id:		String,
    name:		String,
    sticker:	String,
    totalKm:	Int,
    totalKmCanBus:	Int,
    motohours:	Int,
    marker:		String,
    speed:		Int,
    ignition:	Boolean,
    lastUtime:	String,
    address:	String,
    externalVoltage:	Int,
    gsmSignal:	Int,
    visSat: Int,
    todayKm:	Int,
    todayTime:	Int,
    companyId: String,
    latLngGrid:		[LatLngMem],
    trigged: Boolean,
    markerVisible: Boolean,
    iconVisible: Boolean,
    stopsVisible: Boolean,
  }

  //this can be [fullScreen, smallError, smallWarning, smallInfo]
  type ModalEvent {
    type: String!,
    title: String,
    rand: Float,
    text: String!
  }


  type SideNav {
    name: String!,
    width: Int!,
    minWidth: Int!,
    visible: Boolean
  }

  type LatLngMem {
    id: String,
    lat: Float!,
    lng: Float!,
    speed: Int,
    utime: Int!
  }
`;

export const resolvers = {
  Mutation: {
    mutateCard: (_, {id, action}, {cache}) => {
      const {assetsInMem} = cache.readQuery({query: ALL_ASSETS_MEM});

      const newAssets = assetsInMem.map(asset => {
        if (asset.id === id) {
          // update asset status
          if (action === 'trigged') {
            //trigger fitBounds
            if (asset.trigged === false) {
              //fit bound only if the asset has an latlng
              if (asset.latLngGrid && asset.latLngGrid.length > 0)
                cache.writeQuery({
                  query: FIT_BOUNDS,
                  data: {
                    fitBounds: [
                      {
                        ...currentPosition(asset.latLngGrid),
                        __typename: 'LatLng',
                      },
                    ],
                  },
                });
            }

            return {...asset, ...{trigged: !asset.trigged}};
          }

          // update asset status without fitting bounds
          if (action === 'triggedNoFit') {
            return {...asset, ...{trigged: true}};
          }

          if (action === 'checkBoxClicked') {
            return {...asset, ...{markerVisible: !asset.markerVisible}};
          }
        } else {
          //close all cards
          return {...asset, ...{trigged: false}};
        }
        return asset;
      });

      cache.writeQuery({
        query: ALL_ASSETS_MEM,
        data: {assetsInMem: cloneDeep(newAssets)},
      });

      return null;
    },
    showHideAllMarkers: (_, {state}, {cache}) => {
      //console.log(state);

      const {assetsInMem} = cache.readQuery({query: ALL_ASSETS_MEM});
      const newAssets = assetsInMem.map(x => {
        return {...x, ...{markerVisible: state}};
      });

      cache.writeQuery({
        query: ALL_ASSETS_MEM,
        data: {assetsInMem: cloneDeep(newAssets)},
      });

      return null;
    },
    insertAssetIntoCache: async (_, {assetInput}, {client}) => {
      console.log('read');
      const assetsPrev = await client.readQuery({
        query: ALL_ASSETS_MEM,
      });

      var newAsset = {...assetInput};

      newAsset.__typename = 'AssetInMem';

      const newAssetsArray = [...assetsPrev.assetsInMem, newAsset];
      //write new data to client.cache
      await client.writeQuery({
        query: ALL_ASSETS_MEM,
        data: {assetsInMem: cloneDeep(newAssetsArray)},
      });

      return {status: 'done', __typename: 'String'};
    },
    disableIcons: (_, {disableIcons}, {cache}) => {
      // icon change
      const {assetsInMem} = cache.readQuery({query: ALL_ASSETS_MEM});
      const newAssets = assetsInMem.map(x => {
        return {...x, ...{iconVisible: !disableIcons}};
      });
      cache.writeQuery({
        query: ALL_ASSETS_MEM,
        data: {assetsInMem: cloneDeep(newAssets)},
      });

      return null;
    },
    disableStops: (_, {disableStops}, {cache}) => {
      // stops setting change
      const {assetsInMem} = cache.readQuery({query: ALL_ASSETS_MEM});
      const newAssets = assetsInMem.map(x => {
        return {...x, ...{stopsVisible: !disableStops}};
      });
      cache.writeQuery({
        query: ALL_ASSETS_MEM,
        data: {assetsInMem: cloneDeep(newAssets)},
      });
      return null;
    },
    mutateHistoryButtonLoader: (_, {assetId, state}, {cache}) => {
      const loadingState = cache.readQuery({
        query: ALL_HISTORY_LOADING,
      });

      let {historyButtonLoader} = loadingState;
      let index = historyButtonLoader.findIndex(x => x.assetId === assetId);

      if (index === -1 && state === 'startLoading') {
        let newLoadingState = {assetId: assetId};
        newLoadingState.__typename = 'AssetIdLoader';

        let newLoadingStateArray = [...historyButtonLoader, newLoadingState];

        //write new data to cache
        cache.writeQuery({
          query: ALL_HISTORY_LOADING,
          data: {historyButtonLoader: newLoadingStateArray},
        });
      } else if (index > -1 && state === 'done') {
        let newLoadingStateArray = historyButtonLoader.filter(x => x.assetId !== assetId);

        //write new data to cache
        cache.writeQuery({
          query: ALL_HISTORY_LOADING,
          data: {historyButtonLoader: newLoadingStateArray},
        });
      }

      return {status: 'done', __typename: 'String', assetId};
    },
  },
  Query: {
    assetInMem: (obj, args, {cache}) => {
      const {assetsInMem} = cache.readQuery({query: ALL_ASSETS_MEM});
      return assetsInMem.find(asset => asset.id === args.id);
    },
  },
};

const currentPosition = latLngSeed => {
  // current time - 20 sec
  const now = new Date();
  let currentTime = now.getTime() / 1000 - 20;
  let index = 0;

  while (latLngSeed[index + 1] && latLngSeed[index].utime > currentTime) {
    index++;
  }

  //gps is actual and it is possible to animate the marker
  if (index > 0) {
    let diff = {
      lat: latLngSeed[index - 1].lat - latLngSeed[index].lat,
      lng: latLngSeed[index - 1].lng - latLngSeed[index].lng,
      utime: latLngSeed[index - 1].utime - latLngSeed[index].utime,
    };

    //calc speed
    diff.lat = diff.lat / diff.utime;
    diff.lng = diff.lng / diff.utime;

    //how far is current time
    diff.currentTimeDiff = currentTime - latLngSeed[index].utime;

    //calc position
    diff.currentLat = Number(latLngSeed[index].lat) + diff.lat * diff.currentTimeDiff;
    diff.currentLng = Number(latLngSeed[index].lng) + diff.lng * diff.currentTimeDiff;

    //return current state
    return {
      lat: diff.currentLat,
      lng: diff.currentLng,
      inMotion: true,
      utime: latLngSeed[index].utime,
    };
  }

  //return when nothing to animate (gps time is not actual)
  return {
    lat: latLngSeed[0].lat,
    lng: latLngSeed[0].lng,
    inMotion: false,
    utime: latLngSeed[index].utime,
  };
};
