/**
 * /*eslint-disable
 *
 * @format
 */

import React from 'react';
//import PropTypes from 'prop-types'
//import classNames from 'classnames'
//import CheckCircleIcon from '@material-ui/icons/CheckCircle'
//import ErrorIcon from '@material-ui/icons/Error'
//import InfoIcon from '@material-ui/icons/Info'
//import CloseIcon from '@material-ui/icons/Close'
//import green from '@material-ui/core/colors/green'
//import amber from '@material-ui/core/colors/amber'
//import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
//import WarningIcon from '@material-ui/icons/Warning'
import {withStyles} from '@material-ui/styles'; //makeStyles,

/*const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}*/

const styles2 = theme => ({
  root: {
    zIndex: 999999,
  },
  margin: {
    margin: '5px',
  },
});

class GeneralSnackbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      autoHideDuration: (() => {
        if (props.variant === 'error') {
          return null; //no autohide on error
        } else {
          return 2000;
        }
      })(),
    };
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({open: false});
  };

  render() {
    const {classes} = this.props;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={this.state.open}
        autoHideDuration={2000}
        onClose={this.handleClose}
        classes={{
          root: classes.root,
        }}>
        <SnackbarContent
          aria-describedby="client-snackbar"
          message={<span id="client-snackbar">{this.props.message}</span>}
          onClick={() => this.handleClose()}
        />
      </Snackbar>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.message !== this.props.message) {
      this.setState({
        open: true,
        autoHideDuration: (() => {
          if (this.props.variant === 'error') {
            return 2000; //no autohide on error
          } else {
            return 2000;
          }
        })(),
      });
    }
  }
}

export default withStyles(styles2)(GeneralSnackbar);
