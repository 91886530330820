/** @format */

import {ASSET_SUBSCRIPTION} from '../../apollo/subscription/AssetSubscription';
import {ASSET_MEM} from '../../apollo/query/AssetQuery';
import cloneDeep from 'lodash/cloneDeep';

/**

 * @file GPSON class Asset

 * @copyright Filip Kroca 2018 filipkroca@gmail.com

 */

class AssetLogic {
  constructor({data, client}) {
    //constructor
    this.id = data.id;
    this.client = client;

    let that = this;

    //create subscription for asset instance
    this.subscription = client
      .subscribe({
        query: ASSET_SUBSCRIPTION,
        variables: {id: data.id},
        fetchPolicy: 'no-cache',
      })
      .subscribe({
        next({data}) {
          that.update({data});
        },
        error(erro) {
          console.log(erro);
        },
      });
  }

  async update({data}) {
    let assetInput = cloneDeep(data.assetChange);
    //delete null properties
    Object.keys(assetInput).forEach(key => assetInput[key] == null && delete assetInput[key]);
    assetInput.__typename = 'AssetInMem';

    for (var i = 0; i < assetInput.latLngGrid.length; i++) {
      assetInput.latLngGrid[i].__typename = 'LatLngMem';
    }

    const {assetInMem} = await this.client.readQuery({
      query: ASSET_MEM,
      variables: {id: assetInput.id},
    });
    const newAsset = {...assetInMem, ...assetInput};

    if (newAsset.latLngGrid) {
      let newArr = [...newAsset.latLngGrid];
      delete newAsset.latLngGrid;
      newAsset.latLngGrid = newArr;
    }

    if (newAsset) {
      this.client.writeQuery({
        query: ASSET_MEM,
        data: {assetInMem: newAsset},
        variables: {id: newAsset.id},
      });
    }
  }

  unsubscribe() {
    if (this.subscription && this.subscription.unsubscribe) this.subscription.unsubscribe();
    this.subscription = null;
  }

  reSubscribe() {
    //create new subscription when waked up
    if (!this.subscription) {
      let that = this;
      //create subscription for asset instance
      this.subscription = this.client
        .subscribe({
          query: ASSET_SUBSCRIPTION,
          variables: {id: this.id},
          fetchPolicy: 'no-cache',
        })
        .subscribe({
          next({data}) {
            that.update({data});
          },
          error(erro) {
            console.log(erro);
          },
        });
    }
  }
}

export default AssetLogic;
