/** @format */

import {gql} from '@apollo/client';

const ASSET_DRIVER = gql`
  query ASSET_DRIVER($assetId: String!) {
    assetDriver(assetId: $assetId) {
      id
      assetId
      name
      phone
      email
      companyId
      tachoCard
      rfid
      __typename
    }
  }
`;

const ASSET_DRIVERS = gql`
  query ASSET_DRIVERS {
    getAssetDrivers {
      id
      assetId
      name
      phone
      email
      companyId
      tachoCard
      rfid
      __typename
    }
  }
`;

const ASSET_DRIVERS_WITH_WORKING_HOURS = gql`
  query ASSET_DRIVERS_WITH_WORKING_HOURS {
    getAssetDrivers {
      id
      assetId
      name
      phone
      email
      companyId
      tachoCard
      rfid
      workingHours
      __typename
    }
  }
`;

export {ASSET_DRIVER, ASSET_DRIVERS, ASSET_DRIVERS_WITH_WORKING_HOURS};
