/** @format */

import { gql } from '@apollo/client';

const MUTATE_TRIP_TYPE = gql`
  mutation(
    $assetId: String!
    $tripType: String!
    $startUtime: Int!
    $stopUtime: Int!
    $refetchStartUtime: Int!
    $refetchStopUtime: Int!
  ) {
    mutateTripType(
      assetId: $assetId
      tripType: $tripType
      startUtime: $startUtime
      stopUtime: $stopUtime
      refetchStartUtime: $refetchStartUtime
      refetchStopUtime: $refetchStopUtime
    ) {
      id
      startLoc
      stopLoc
      startUtime
      stopUtime
      maxSpeed
      consuption
      distance
      idle
      tripType
      __typename
    }
  }
`;

const MUTATE_TRIP_DRIVER = gql`
  mutation(
    $assetId: String!
    $startUtime: Int!
    $stopUtime: Int!
    $driver: String!
  ) {
    mutateTripDriver(
      assetId: $assetId                  
      startUtime: $startUtime
      stopUtime: $stopUtime
      driver: $driver
    ) {
      id
      driver
      __typename
    }
  }
`;

export { MUTATE_TRIP_TYPE, MUTATE_TRIP_DRIVER };
