/** @format */

import {gql} from '@apollo/client';

const FUEL_METERING = gql`
  {
    fuelMetering {
      id
      fuelMetering
      __typename
    }
  }
`;

const FUEL_QUERY = gql`
  query FUEL_QUERY($assetId: String!, $startUtime: Int, $stopUtime: Int) {
    fuelRead(assetId: $assetId, startUtime: $startUtime, stopUtime: $stopUtime) {
      level
      utime
      __typename
    }
  }
`;

export {FUEL_METERING, FUEL_QUERY};
