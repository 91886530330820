/** @format */

import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import LoginHandler from './auth';
import { WebSocketLink } from '@apollo/link-ws';
import { resolvers, typeDefs } from './resolvers';
import { onError } from '@apollo/link-error';
import { captureMessage } from '@sentry/browser';
import { SIDE_NAVS } from './query/SideNav';
import { ALL_ASSETS_MEM, HISTORY_POLYLINES_SETTING } from './query/AssetQuery';
import { ALL_HISTORY_LOADING } from './query/HistoryButton';
import { makeVar } from '@apollo/client';
let wssURL;

if (process.env.NODE_ENV === 'development') {
  wssURL = 'wss://gql.gpson.cz:3000/subscriptions';
} else {
  wssURL = 'wss://gql.gpson.cz:3000/subscriptions';
}

const cache = new InMemoryCache({
  typePolicies: {
    LatLng: {
      keyFields: false,
    },
    LatLngMem: {
      keyFields: false,
    },
    HistoryPolylinesSetting: {
      fields: {
        historyPolylinesSetting: {
          merge(existing, incoming) {
            console.log(existing, incoming);
            return [...existing, ...incoming];
          },
        },
      },
    },
    AssetInMem: {
      keyFields: ['id'],
      fields: {
        latLngGrid: {
          merge(existing, incoming, { readField }) {
            //console.log('ex:', existing);
            //console.log('inc:', incoming);
            return incoming;
          },
        },
      },
    },
    Group: {
      keyFields: ['name'],
      fields: {
        tasks: {
          merge(existing, incoming, { readField }) {
            //console.log('ex:', existing);
            //console.log('inc:', incoming);
            return incoming;
          },
        },
      },
    },
    Query: {
      fields: {
        assetInMem(existingData, { args, toReference }) {
          return existingData || toReference({ __typename: 'AssetInMem', id: args.id });
        },
        historyPolylinesSetting: {
          merge: (existing, incoming, opts) => incoming,
        },
      },
    },
  },
});

//custom login object
const loginHandler = new LoginHandler();

//config error link
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      if (message === 'Unautorized') {
        // check if user didn't change the password
        if (operation.authToken !== loginHandler.getAccessToken({ reset: true })) {
          operation.setContext({
            authToken: loginHandler.getAccessToken({ reset: true }),
          });
          return null;
        }

        loginHandler.logout({});
        return null;
      }

      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

      captureMessage(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      return null;
    });
  }

  //handle network popover
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    captureMessage(`[Network error]: ${networkError}`);
    return;
  }
});

// configure subscriptions link over websockets
const wsLink = new WebSocketLink({
  uri: wssURL,
  options: {
    reconnect: true,
  },
});

// auth - create my middleware using the applyMiddleware method from subscriptions-transport-ws
const authMiddleware = {
  applyMiddleware(options, next) {
    options.authToken = loginHandler.getAccessToken({ reset: false });
    next();
  },
};
// add the middleware to the web socket link via the Subscription Transport client
wsLink.subscriptionClient.use([authMiddleware]);

let client = new ApolloClient({
  link: ApolloLink.from([errorLink, wsLink]),
  cache,
  typeDefs,
  resolvers,
});

const writeDefaults = () => {
  console.log('write defaults');

  client.writeQuery({
    query: SIDE_NAVS,
    data: {
      sideNavs: [],
    },
  });
  client.writeQuery({
    query: ALL_ASSETS_MEM,
    data: {
      assetsInMem: [],
    },
  });
  client.writeQuery({
    query: HISTORY_POLYLINES_SETTING,
    data: {
      historyPolylinesSetting: [],
    },
  });
  client.writeQuery({
    query: ALL_HISTORY_LOADING,
    data: {
      historyButtonLoader: [],
    },
  });
};

// first init
writeDefaults();

client.onResetStore(() => {
  writeDefaults();
});

const groupsEnabledVar = makeVar(false);
groupsEnabledVar(localStorage.getItem('enabledGroups') === 'true' ? true : false);

export { loginHandler, groupsEnabledVar, client as default };
