/** @format */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import {loginHandler} from '../../../apollo/client';
import TextFieldWithSaveButton from '../../../components/TextFieldWithSaveButton';
import {SET_ALARMS} from '../../../apollo/mutation/AlarmMutation';
import {withApollo} from '@apollo/client/react/hoc';
import {ALL_ALARMS} from '../../../apollo/query/AlarmQuery';
import {ASSET_MEM} from '../../../apollo/query/AssetQuery';

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  paper: {
    padding: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  },
  h4: {
    paddingBottom: theme.spacing(2),
  },
  label: {
    paddingTop: theme.spacing(2),
  },
  armFormControlLabel: {marginLeft: theme.spacing(1), fontSize: '3rem'},
  armFormControl: {paddingTop: theme.spacing(2)},
});

const ArmSwitch = withStyles(theme => ({
  root: {transform: 'scale(1.6)'},
}))(({classes, ...props}) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

class Card extends React.Component {
  constructor(props) {
    super(props);

    if (
      props.alarmData &&
      props.alarmData.getAlarms &&
      props.alarmData.getAlarms.length > 0 &&
      props.alarmData.getAlarms[0].trigged !== true
    ) {
      this.state = {
        id: props.alarmData.getAlarms[0].id,
        hasArmedDoors: props.alarmData.getAlarms[0].hasArmedDoors,
        hasArmedMotion: props.alarmData.getAlarms[0].hasArmedMotion,
        hasArmedSignalLost: props.alarmData.getAlarms[0].hasArmedSignalLost,
        isArmed: props.alarmData.getAlarms[0].isArmed,
        email: props.alarmData.getAlarms[0].email,
        isEmailEnabled: props.alarmData.getAlarms[0].isEmailEnabled,
        smsNumber: props.alarmData.getAlarms[0].smsNumber,
        isSmsEnabled: props.alarmData.getAlarms[0].isSmsEnabled,
        error: '',
      };
    } else {
      this.state = {
        id: 0,
        hasArmedDoors: false,
        hasArmedMotion: true,
        hasArmedSignalLost: false,
        isArmed: false,
        isSmsEnabled: false,
        isEmailEnabled: true,
        email: loginHandler.getEmail(),
        smsNumber: '+420',
        error: '',
      };
    }
  }

  render() {
    let {classes} = this.props;
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h4" component="h4" className={classes.h4}>
              Nastavení alarmu {this.props.assetInMem.name}
            </Typography>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.label}>
                Spouštěče alarmu
              </FormLabel>
              <FormGroup>
                {this.props.assetInMem &&
                this.props.assetInMem.modules &&
                this.props.assetInMem.modules.search('tripAnalysis') ? (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.hasArmedDoors}
                        onChange={this.handleChange.bind(this, 'hasArmedDoors')}
                        value="otevření dvěří"
                      />
                    }
                    label="otevření dvěří"
                  />
                ) : null}
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.hasArmedMotion}
                      onChange={this.handleChange.bind(this, 'hasArmedMotion')}
                      value="pohyb"
                    />
                  }
                  label="pohyb"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.hasArmedSignalLost}
                      onChange={this.handleChange.bind(this, 'hasArmedSignalLost')}
                      value="ztráta signálu*"
                    />
                  }
                  label="ztráta signálu*"
                />
              </FormGroup>
              <FormHelperText>
                * Ztráta signálu může vyvolat plané poplachy s ohledem na výpadky telekomunikačních sítí.
              </FormHelperText>
              <FormLabel component="legend" className={classes.label}>
                Zaslat upozornění
              </FormLabel>
              <FormGroup>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.isSmsEnabled}
                          onChange={this.handleChange.bind(this, 'isSmsEnabled')}
                          value="sms"
                        />
                      }
                      label="sms"
                    />
                  </Grid>
                  {this.state.isSmsEnabled && (
                    <Grid item xs={12} sm={8}>
                      <TextFieldWithSaveButton
                        value={this.state.smsNumber}
                        callBack={this.handleSmsChange.bind(this)}
                        label="Příjemce"
                        required={true}
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.isEmailEnabled}
                          onChange={this.handleChange.bind(this, 'isEmailEnabled')}
                          value="email"
                        />
                      }
                      label="email"
                    />
                  </Grid>
                  {this.state.isEmailEnabled && (
                    <Grid item xs={12} sm={8}>
                      <TextFieldWithSaveButton
                        value={this.state.email}
                        callBack={this.handleEmailChange.bind(this)}
                        label="Příjemce"
                        required={true}
                      />
                    </Grid>
                  )}
                </Grid>
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  classes={{root: classes.armFormControl, label: classes.armFormControlLabel}}
                  control={
                    <ArmSwitch
                      checked={this.state.isArmed}
                      onChange={this.arm.bind(this)}
                      value={this.state.isArmed === true ? 'odstřežit' : 'zastřežit'}
                    />
                  }
                  label={this.state.isArmed === true ? 'odstřežit' : 'zastřežit'}
                />
              </FormGroup>
              {this.state.error}
            </FormControl>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  componentDidMount() {
    this.refetch();
  }

  refetch() {
    this.props.client
      .query({query: ALL_ALARMS, variables: {assetId: this.props.assetInMem.id}, fetchPolicy: 'network-only'})
      .then(({data}) => {
        if (data && data.getAlarms && data.getAlarms.length > 0 && data.getAlarms[0].trigged !== true) {
          this.setState({
            id: data.getAlarms[0].id,
            hasArmedDoors: data.getAlarms[0].hasArmedDoors,
            hasArmedMotion: data.getAlarms[0].hasArmedMotion,
            hasArmedSignalLost: data.getAlarms[0].hasArmedSignalLost,
            isArmed: data.getAlarms[0].isArmed,
            isSmsEnabled: data.getAlarms[0].isSmsEnabled,
            isEmailEnabled: data.getAlarms[0].isEmailEnabled,
            email: data.getAlarms[0].email,
            smsNumber: data.getAlarms[0].smsNumber,
            error: '',
          });
        }
      });
  }

  async handleEmailChange(value) {
    await this.setStateAsync({isArmed: false, email: value});
    this.save();
  }

  async handleSmsChange(value) {
    await this.setStateAsync({isArmed: false, smsNumber: value.replace(/ /g, '')});
    this.save();
  }

  async handleChange(action) {
    if (action === 'hasArmedDoors') {
      await this.setStateAsync({
        hasArmedDoors: !this.state.hasArmedDoors,
        isArmed: false,
      });
    } else if (action === 'hasArmedMotion') {
      await this.setStateAsync({
        hasArmedMotion: !this.state.hasArmedMotion,
        isArmed: false,
      });
    } else if (action === 'hasArmedSignalLost') {
      await this.setStateAsync({
        hasArmedSignalLost: !this.state.hasArmedSignalLost,
        isArmed: false,
      });
    } else if (action === 'isSmsEnabled') {
      await this.setStateAsync({isSmsEnabled: !this.state.isSmsEnabled, isArmed: false});
    } else if (action === 'isEmailEnabled') {
      await this.setStateAsync({
        isEmailEnabled: !this.state.isEmailEnabled,
        isArmed: false,
      });
    }
    this.save();
    return null;
  }

  arm() {
    // check if at leaset one of notification method is selected
    if (
      this.state.hasArmedDoors === false &&
      this.state.hasArmedMotion === false &&
      this.state.hasArmedSignalLost === false
    ) {
      this.setState({error: <FormHelperText error>Musí být vybrán alespoň jeden typ spouštěče.</FormHelperText>});
      return null;
    }

    // check if at leaset one of notification method is selected
    if (this.state.isArmed === false && this.state.isSmsEnabled === false && this.state.isEmailEnabled === false) {
      this.setState({error: <FormHelperText error>Musí být zadán alespoň jeden způsob upozornění.</FormHelperText>});
      return null;
    }

    // is sms method is checked, check the number format
    if (
      this.state.isArmed === false &&
      this.state.isSmsEnabled === true &&
      !this.state.smsNumber.match(/^\+420[0-9]{9}$/)
    ) {
      this.setState({error: <FormHelperText error>Telefonní číslo musí být ve tvaru +420XXXXXXXXX</FormHelperText>});
      return null;
    }

    // is email method is checked, check the email format
    if (
      this.state.isArmed === false &&
      this.state.isEmailEnabled === true &&
      //eslint-disable-next-line
      !this.state.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      this.setState({error: <FormHelperText error>Zadaný email nemá správný formát.</FormHelperText>});
      return null;
    }

    this.setState({isArmed: !this.state.isArmed}, () => {
      this.save();
    });
  }

  async save() {
    let latLngGrid = await new Promise(resolve =>
      this.props.client.query({query: ASSET_MEM, variables: {id: this.props.assetInMem.id}}).then(({data}) => {
        if (data && data.assetInMem) resolve(data.assetInMem.latLngGrid);
      })
    );

    this.props.client
      .mutate({
        mutation: SET_ALARMS,
        variables: {
          alarmInput: [
            {
              id: this.state.id,
              assetId: this.props.assetInMem.id,
              isArmed: this.state.isArmed,
              hasArmedDoors: this.state.hasArmedDoors,
              hasArmedMotion: this.state.hasArmedMotion,
              hasArmedSignalLost: this.state.hasArmedSignalLost,
              lastLatLng: {
                lat: latLngGrid[0].lat,
                lng: latLngGrid[0].lng,
              },
              isSmsEnabled: this.state.isSmsEnabled,
              isEmailEnabled: this.state.isEmailEnabled,
              smsNumber: this.state.smsNumber,
              email: this.state.email,
            },
          ],
        },
      })
      .then(({data}) => {
        if (data && data.setAlarms && data.setAlarms.length > 0) {
          this.setState({
            id: data.setAlarms[0].id,
            hasArmedDoors: data.setAlarms[0].hasArmedDoors,
            hasArmedMotion: data.setAlarms[0].hasArmedMotion,
            hasArmedSignalLost: data.setAlarms[0].hasArmedSignalLost,
            isArmed: data.setAlarms[0].isArmed,
            isSmsEnabled: data.setAlarms[0].isSmsEnabled,
            isEmailEnabled: data.setAlarms[0].isEmailEnabled,
            email: data.setAlarms[0].email,
            smsNumber: data.setAlarms[0].smsNumber.length > 0 ? data.setAlarms[0].smsNumber : '+420',
            error: '',
          });
        }
      });
  }

  setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve);
    });
  }
}

Card.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withApollo(Card));
