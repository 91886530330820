/** @format */

import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVert from '@material-ui/icons/MoreVert';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = active => {
    this.setState({anchorEl: null});
    this.props.menuClickHandler(active);
  };

  render() {
    const {anchorEl} = this.state;

    const button = (() => (
      <Button aria-owns={anchorEl ? 'simple-menu' : null} aria-haspopup="true" onClick={this.handleClick}>
        <div className="menu-button__name-box">
          <div className="menu-button__asset-name">{this.props.assetsArr[this.props.activeAsset].name}</div>
          <div className="menu-button__asset-sticker">{this.props.assetsArr[this.props.activeAsset].sticker}</div>
        </div>
        <div className="menu-button__icon-open">
          <MoreVert className="menu-button__icon-open__svg" />
        </div>
      </Button>
    ))();

    const items = this.props.assetsArr.map((x, key) => {
      if (x.fuelMetering !== 'false') {
        return (
          <MenuItem key={x.id} onClick={() => this.handleClose(key, x.id)}>
            {x.name}
          </MenuItem>
        );
      } else {
        return (
          <MenuItem disabled key={x.id} onClick={() => this.handleClose(key, x.id)}>
            {x.name} měření neaktivní
          </MenuItem>
        );
      }
    });

    return (
      <div className={this.props.className}>
        {/* asset menu */}
        {button}
        <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <MenuList className="log-book__menu-list">{items}</MenuList>
        </Menu>
      </div>
    );
  }
}

export default Header;
