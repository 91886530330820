/** @format */

import {gql} from '@apollo/client';

const FIT_BOUNDS = gql`
  query FIT_BOUNDS {
    fitBounds @client {
      lat
      lng
      __typename
    }
  }
`;

const GET_ADDRESS = gql`
  query GET_ADDRESS($latLng: LatLngInput!) {
    getAddress(latLng: $latLng)
  }
`;

export {FIT_BOUNDS, GET_ADDRESS};
