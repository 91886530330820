/** @format */

import React from 'react';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FormHelperText from '@material-ui/core/FormHelperText';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/styles'; //makeStyles,
import {withApollo} from '@apollo/client/react/hoc';
import {DELETE_MAINTENANCE, PUT_MAINTENANCE} from '../../../apollo/mutation/MaintenanceMutation';
// date picker
import 'moment/locale/cs';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
moment.locale('cs');

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  rootPaper: {
    padding: theme.spacing(3, 2),
    position: 'relative',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  textareaAutosize: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  keyboardDatePicker: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  tabPanel: {
    width: '100%',
    maxWidth: '600px',
  },
  fab: {
    margin: theme.spacing(1),
    alignSelf: 'flex-end',
    flexDirection: 'row',
  },
  fabDelete: {
    margin: theme.spacing(1),
    alignSelf: 'flex-end',
    flexDirection: 'row',
    color: 'white',
  },
  fabEdit: {
    margin: theme.spacing(1),
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: 'white',
  },
  fabContainer: {
    margin: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  h6: {
    marginTop: '10px',
  },
  chipWrap: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  text: {},
});

class MaintenanceTabPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: this.props.isNewTask ? this.props.isNewTask : false,
      id: this.props.data.id ? this.props.data.id : null,
      name: this.props.data.name ? this.props.data.name : '',
      selectedDate: parseInt(this.props.data.utimeAlarm) > 0 ? new Date(this.props.data.utimeAlarm * 1000) : null,
      distanceAlarm: this.props.data.distanceAlarm,
      distanceAlarmTotal: this.props.data.distanceAlarmTotal,
      taskText: this.props.data.taskText ? this.props.data.taskText : '',
      addedKm: this.props.data.distanceAlarm > 0 ? this.props.data.distanceAlarm : '',
    };
  }

  handleDelete(e) {
    e.preventDefault();
    this.props.client
      .mutate({
        mutation: DELETE_MAINTENANCE,
        variables: {id: this.props.data.id, assetId: this.props.assetId},
      })
      .then(({data, error, loading}) => {
        this.props.triggerDeleted(this.props.data.id);
      });
  }

  handleSave() {
    let utimeAlarm = '0';
    if (this.state.selectedDate && typeof this.state.selectedDate.unix === 'function') {
      utimeAlarm = this.state.selectedDate.unix().toString(10);
    }

    this.props.client
      .mutate({
        mutation: PUT_MAINTENANCE,
        variables: {
          maintenanceInput: {
            id: this.props.data.id,
            assetId: this.props.assetId,
            name: this.state.name,
            taskText: this.state.taskText,
            utimeAlarm,
            distanceAlarm: this.state.addedKm > 0 ? this.state.addedKm : 0,
            distanceAlarmTotal: this.state.distanceAlarmTotal,
          },
        },
      })
      .then(({data, error, loading}) => {
        console.log('refetch', data, error);
        this.props.refetch();
        this.setState({editing: false});
      });
  }

  handleDateChange = date => {
    this.setState({selectedDate: date});
  };

  handleDistanceAlarm = addedKm => {
    if (parseInt(addedKm.target.value) > 0) {
      this.setState({
        addedKm: parseInt(addedKm.target.value),
        distanceAlarmTotal: parseInt(addedKm.target.value) + this.props.tachoTotal,
      });
    } else {
      this.setState({addedKm: '', distanceAlarmTotal: 0});
    }
  };

  handleChange(event, newValue) {
    if (event === 'name') {
      this.setState({
        name: newValue.target.value,
      });
    } else if (event === 'taskText') {
      this.setState({
        taskText: newValue.target.value,
      });
    }
  }

  render() {
    const {classes, value} = this.props;

    let taskPaper;
    if (this.state.editing) {
      taskPaper = (
        <TabPanel className={classes.tabPanel} value={value} index={this.props.index}>
          <form className={classes.container} noValidate autoComplete="off">
            <span>{this.props.isNewTask && this.state.editing ? 'Nová připomínka' : 'Editace připomínky'}</span>
            <TextField
              //error={this.isRetryError()}
              id="Name"
              name="Name"
              label="Název"
              className={classes.textField}
              type="text"
              onChange={this.handleChange.bind(this, 'name')}
              margin="normal"
              variant="standard"
              value={this.state.name}
            />

            <TextareaAutosize
              aria-label="minimum height"
              className={classes.textareaAutosize}
              rows={3}
              placeholder="Poznámka"
              value={this.state.taskText}
              onChange={this.handleChange.bind(this, 'taskText')}
            />
            <span>Správa alarmů připomínky:</span>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="cs">
              <DatePicker
                value={this.state.selectedDate}
                onChange={date => this.handleDateChange(date)}
                className={classes.keyboardDatePicker}
                format="DD.MM.YYYY"
              />
            </MuiPickersUtilsProvider>

            <FormControl className={classes.textField}>
              <InputLabel htmlFor="standard-adornment-amount">Připomenout po ujetí vzdálenosti</InputLabel>
              <Input
                id="standard-adornment-weight"
                value={this.state.addedKm}
                onChange={this.handleDistanceAlarm.bind(this)}
                endAdornment={<InputAdornment position="start">Km</InputAdornment>}
              />
              {this.state.distanceAlarmTotal > 0 ? (
                <FormHelperText>Toto odpovídá stavu tachometru {this.state.distanceAlarmTotal}km</FormHelperText>
              ) : (
                ''
              )}
            </FormControl>
            <div className={classes.fabContainer}>
              <Fab color="primary" aria-label="uložit" className={classes.fab} onClick={this.handleSave.bind(this)}>
                <SaveIcon />
              </Fab>
              {this.props.isNewTask ? (
                ''
              ) : (
                <Fab
                  color="secondary"
                  aria-label="smazat"
                  className={classes.fabDelete}
                  onClick={this.handleDelete.bind(this)}>
                  <DeleteIcon />
                </Fab>
              )}
            </div>
          </form>
        </TabPanel>
      );
    } else {
      taskPaper = (
        <TabPanel className={classes.tabPanel} value={value} index={this.props.index}>
          <Paper className={classes.rootPaper}>
            <Fab
              color="secondary"
              aria-label="editovat"
              className={classes.fabEdit}
              onClick={() => this.setState({editing: true})}>
              <EditIcon />
            </Fab>
            <Typography
              variant="h5"
              component="h3"
              style={{marginBottom: '20px'}}
              onDoubleClick={() => this.setState({editing: true})}>
              Záznam
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              className={classes.h6}
              onDoubleClick={() => this.setState({editing: true})}>
              Název:
            </Typography>
            <p onDoubleClick={() => this.setState({editing: true})} className="maintenance__text">
              {this.state.name}
            </p>
            <Typography
              variant="h6"
              component="h6"
              className={classes.h6}
              onDoubleClick={() => this.setState({editing: true})}>
              Poznámka:
            </Typography>
            <p onDoubleClick={() => this.setState({editing: true})} className="maintenance__text">
              {this.state.taskText}
            </p>
            <Typography
              variant="h6"
              component="h6"
              className={classes.h6}
              onDoubleClick={() => this.setState({editing: true})}>
              Alarmy:
            </Typography>
            <div className={classes.chipWrap}>
              {this.props.data.utimeAlarm > 0 && (
                <Chip
                  label={`dne ${moment.unix(this.props.data.utimeAlarm).format('DD.MM.YYYY')}`}
                  color="primary"
                  onDoubleClick={() => this.setState({editing: true})}
                />
              )}

              {this.props.data.distanceAlarm > 0 && (
                <Chip
                  label={`po ujetí ${this.props.data.distanceAlarm} km`}
                  color="primary"
                  onDoubleClick={() => this.setState({editing: true})}
                />
              )}
            </div>
            <Typography
              variant="h6"
              component="h6"
              className={classes.h6}
              onDoubleClick={() => this.setState({editing: true})}>
              Vytvořeno:
            </Typography>
            <p onDoubleClick={() => this.setState({editing: true})} className="maintenance__text">
              {`dne ${moment.unix(this.props.data.created).format('DD.MM.YYYY HH:mm')}`}
            </p>
          </Paper>
        </TabPanel>
      );
    }

    return taskPaper;
  }
}

export default withStyles(styles)(withApollo(MaintenanceTabPanel));
