/** @format */

import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MySettingsBody from './MySettingsBody';
import Slide from '@material-ui/core/Slide';

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const MySettings = props => {
  const [open, setOpen] = React.useState(false);

  /* function handleClickOpen() {
     setOpen(true);
   }*/

  function handleClose() {
    console.log(open);
    setOpen(false);
  }
  return (
    <div>
      <Dialog
        fullScreen
        open={props.location.pathname === '/my-settings/'}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <AppBar className={props.classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={() => props.history.push('/')} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={props.classes.flex}>
              Můj účet
            </Typography>
          </Toolbar>
        </AppBar>
        <MySettingsBody />
      </Dialog>
    </div>
  );
};

MySettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(MySettings));
