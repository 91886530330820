/** @format */

import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withApollo} from '@apollo/client/react/hoc';
import {MUTATE_TRIP_TYPE} from '../../../../apollo/mutation/LogBookMutation';
import TripTypeChangeDialog from '../TripTypeChangeDialog/TripTypeChangeDialog';

const styles = {
  root: {
    textTransform: 'none',
    color: '#fefefe',
    fontSize: '1.7rem',
    padding: '0px 8px',
  },
};

class TripTypeSelector extends React.Component {
  state = {
    anchorEl: null,
    tripTypeChangeDialogOpen: false,
  };

  handleClick = event => {
    event.stopPropagation();
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = (event, newTripType) => {
    event.stopPropagation();
    if (newTripType === 'firemní' || newTripType === 'soukromá') {
      this.props.client.mutate({
        mutation: MUTATE_TRIP_TYPE,
        variables: {
          tripType: newTripType,
          assetId: this.props.assetId,
          startUtime: parseInt(this.props.startUtime),
          stopUtime: parseInt(this.props.stopUtime),
          refetchStartUtime: parseInt(this.props.lastStartUtime),
          refetchStopUtime: parseInt(this.props.lastStopUtime),
        },
      });
    }
    this.setState({anchorEl: null});
  };

  render() {
    const {anchorEl} = this.state;
    const {classes, className} = this.props;

    return (
      <div>
        <Button
          className={classNames(classes.root, className)}
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}>
          {this.props.default}
        </Button>
        <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <MenuItem onClick={event => this.handleClose(event, 'firemní')}>firemní</MenuItem>
          <MenuItem onClick={event => this.handleClose(event, 'soukromá')}>soukromá</MenuItem>
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              this.setState({anchorEl: null, tripTypeChangeDialogOpen: true});
            }}>
            změnit hromadně
          </MenuItem>
        </Menu>
        <TripTypeChangeDialog
          isOpen={this.state.tripTypeChangeDialogOpen}
          allTrips={this.state.tripTypeChangeDialogOpen === true ? this.props.allTrips : null}
          assetId={this.props.assetId}
          lastStartUtime={this.props.lastStartUtime}
          lastStopUtime={this.props.lastStopUtime}
          handleClose={() => {
            this.setState({tripTypeChangeDialogOpen: false});
          }}
        />
      </div>
    );
  }
}

TripTypeSelector.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(withApollo(TripTypeSelector));
