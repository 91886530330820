/** @format */

import {gql} from '@apollo/client';

const DRIVER_MUTATION = gql`
  mutation DRIVER_MUTATION($assetDriverInput: AssetDriverInput!) {
    mutateAssetDriver(assetDriverInput: $assetDriverInput) {
      id
      assetId
      name
      phone
      email
      companyId
      tachoCard
      rfid
      workingHours
      __typename
    }
  }
`;

export {DRIVER_MUTATION};
