/** @format */

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import {MUTATE_TRIP_TYPE} from '../../../../apollo/mutation/LogBookMutation';
import {withApollo} from '@apollo/client/react/hoc';
import 'moment/locale/cs';
import moment from 'moment';

moment.locale('cs');

const ResponsiveDialog = props => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = ({newTripType, startUtime, stopUtime}) => {
    if (newTripType === 'firemní' || newTripType === 'soukromá') {
      props.client.mutate({
        mutation: MUTATE_TRIP_TYPE,
        variables: {
          tripType: newTripType,
          assetId: props.assetId,
          startUtime: parseInt(startUtime),
          stopUtime: parseInt(stopUtime),
          refetchStartUtime: parseInt(props.lastStartUtime),
          refetchStopUtime: parseInt(props.lastStopUtime),
        },
      });
    }
  };

  let trips = null;

  if (props.isOpen === true) {
    trips = props.allTrips.logBookTrips.map((x, key) => {
      return (
        <FormGroup row key={key}>
          <FormControlLabel
            control={
              <Checkbox
                checked={x.tripType === 'firemní'}
                onChange={() =>
                  handleChange({
                    newTripType: x.tripType === 'firemní' ? 'soukromá' : 'firemní',
                    startUtime: x.startUtime,
                    stopUtime: x.stopUtime,
                  })
                }
              />
            }
            labelPlacement="start"
            label={moment.unix(x.startUtime).format('DD.MM.YYYY HH:mm') + ` ${x.tripType}`}
          />
        </FormGroup>
      );
    });
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.isOpen}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{'Hromadná změna typu jízd'}</DialogTitle>
      <Grid container justify="center" wrap="nowrap" direction="column" alignItems="center">
        {trips}
      </Grid>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary" autoFocus>
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withApollo(ResponsiveDialog);
