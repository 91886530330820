/** @format */

import L from 'leaflet';
import localforage from 'localforage';
import * as DomEvent from 'leaflet/src/dom/DomEvent';
import * as Util from 'leaflet/src/core/Util';
import {extendPrototype} from 'localforage-startswith';
import PouchDB from 'pouchdb';

import worker from 'workerize-loader!./CleanDBWorker'; // eslint-disable-line import/no-webpack-loader-syntax

extendPrototype(localforage);

// HTMLCanvasElement.toBlob() polyfill
// copy-pasted off https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value: function (callback, type, quality) {
      var dataURL = this.toDataURL(type, quality).split(',')[1];
      setTimeout(function () {
        var binStr = atob(dataURL),
          len = binStr.length,
          arr = new Uint8Array(len);

        for (var i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        callback(new Blob([arr], {type: type || 'image/png'}));
      });
    },
  });
}

// 🍂option cacheMaxAge: Number = 22*24*3600
// Maximum age of the cache, in seconds
//L.TileLayer.prototype.options.cacheMaxAge = 60 * 24 * 3600;
L.TileLayer.prototype.options.cacheMaxAge = 60 * 24 * 3600;

// 🍂option cacheMaxDocs: Number = 3500
// Maximum size of the cache, number of cached tiles
L.TileLayer.prototype.options.cacheMaxDocs = 3500;

L.TileLayer.addInitHook(function () {
  this._cleanDBWorker = worker();

  const oldDb = new PouchDB('offline-tiles', {skip_setup: true});
  oldDb
    .destroy()
    .then(function (response) {
      // success
    })
    .catch(function (err) {
      console.log(err);
    });

  const oldDb2 = new PouchDB('map-cache', {skip_setup: true});
  oldDb2
    .destroy()
    .then(function (response) {
      // success
    })
    .catch(function (err) {
      console.log(err);
    });
});

// include method redefines existing properties/methods or adds new
// see Leaflet documentation
L.TileLayer.include({
  _cleanDBHandler: null,
  _cleanDB: function () {
    this._cleanDBWorker.cleanDB({cacheMaxDocs: this.options.cacheMaxDocs, cacheMaxAge: this.options.cacheMaxAge});
  },
  // Overwrites L.TileLayer.prototype.createTile
  createTile: function (coords, done) {
    var tile = document.createElement('img');
    DomEvent.on(tile, 'error', Util.bind(this._tileOnError, this, done, tile));

    if (this.options.crossOrigin) {
      tile.crossOrigin = '';
    }

    /*
		 Alt tag is *set to empty string to keep screen readers from reading URL and for compliance reasons
		 http://www.w3.org/TR/WCAG20-TECHS/H67
		 */
    tile.alt = '';

    /*
		 Set role="presentation" to force screen readers to ignore this
		 https://www.w3.org/TR/wai-aria/roles#textalternativecomputation
		*/
    tile.setAttribute('role', 'presentation');

    var tileUrl = this.getTileUrl(coords);
    performance.mark(`start ${tileUrl}`);

    if (this.options.useCache) {
      const key = `${coords.x};${coords.y};${coords.z}`;

      localforage
        .getItem(`blob_${key}`)
        .then(doc => {
          performance.measure('binary loaded from db', `start ${tileUrl}`);
          this._onCacheHit(tile, doc, done, key);
        })
        .catch(err => {
          tile.onload = L.bind(this._saveTile, this, tile, coords, done);
          tile.crossOrigin = 'Anonymous';
          tile.src = tileUrl;
        });

      // reset counter
      clearTimeout(this._cleanDBHandler);
      this._cleanDBHandler = setTimeout(this._cleanDB.bind(this), 8000);
    } else {
      // Fall back to standard behaviour
      DomEvent.on(tile, 'load', Util.bind(this._tileOnLoad, this, done, tile));
      tile.src = tileUrl;
    }

    return tile;
  },
  _onCacheHit: function (tile, doc, done, key) {
    const url = URL.createObjectURL(doc);

    this._cleanDBWorker.updateLastUtime({key});
    tile.src = url;
    DomEvent.on(tile, 'load', Util.bind(this._tileOnLoad, this, done, tile));
  },
  // Async'ly saves the tile as a PouchDB attachment
  // Will run the done() callback (if any) when finished.
  _saveTile: function (tile, coords, done) {
    var canvas = document.createElement('canvas');
    canvas.width = tile.naturalWidth || tile.width;
    canvas.height = tile.naturalHeight || tile.height;

    var context = canvas.getContext('2d');
    context.drawImage(tile, 0, 0);

    const key = `${coords.x};${coords.y};${coords.z}`;
    //var tileUrl = this.getTileUrl(coords);

    canvas.toBlob(async function (blob) {
      //  performance.measure('saving started', `start ${tileUrl}`);
      performance.mark(`start saving ${key}`);
      localforage.setItem(`blob_${key}`, blob);
      localforage.setItem(`last_${key}`, Math.round(Date.now() / 1000));
      localforage.setItem(`cached_${key}`, Math.round(Date.now() / 1000));
      done();
    });
  },
});
