/** @format */

import {gql} from '@apollo/client';

const SET_PASSWORD = gql`
  mutation($newPassword: String!, $token: String) {
    setPassword(newPassword: $newPassword, token: $token)
  }
`;

export {SET_PASSWORD};
