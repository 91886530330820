/** @format */

import React from 'react';
import {withApollo} from '@apollo/client/react/hoc';
import {Query} from '@apollo/client/react/components';
import FuelPage from './FuelPage/FuelPage';
import Transition from 'react-transition-group/Transition';
import Splitter from './Splitter';
import CollapsibleMenu from './CollapsibleMenu/CollapsibleMenu';
import LogBook from './LogBook/LogBook';
import {SIDE_NAVS} from '../../apollo/query/SideNav';
import {withRouter} from 'react-router';

class SideNavs extends React.Component {
  render() {
    let {pathname} = this.props.location;

    return (
      <Query query={SIDE_NAVS}>
        {({data}) => {
          const nav = data.sideNavs.map((x, key) => {
            switch (x.name) {
              case 'collapsible':
                return (
                  <div className="full-width-layer full-width-layer--upper" key={key}>
                    <Transition in={pathname === '/collapsible'} timeout={{enter: 0, exit: 300}}>
                      {state => (
                        <React.Fragment>
                          <CollapsibleMenu
                            className="side-nav"
                            style={{
                              ...defaultStyle,
                              ...transitionStyles[state],
                            }}
                          />
                          <Splitter nav={x.name} />
                        </React.Fragment>
                      )}
                    </Transition>
                  </div>
                );

              case 'logBook':
                return (
                  <div className="full-width-layer full-width-layer--upper" key={key}>
                    <Transition in={pathname === '/logBook'} timeout={{enter: 0, exit: 300}}>
                      {state => (
                        <React.Fragment>
                          <LogBook
                            className="side-nav"
                            style={{
                              ...defaultStyle,
                              ...transitionStyles[state],
                            }}
                          />
                          <Splitter nav={x.name} />
                        </React.Fragment>
                      )}
                    </Transition>
                  </div>
                );

              case 'fuelPage':
                return (
                  <div className="full-width-layer full-width-layer--upper" key={key}>
                    <Transition in={pathname === '/fuelPage'} timeout={{enter: 0, exit: 300}} unmountOnExit key={key}>
                      {state => (
                        <React.Fragment>
                          <FuelPage
                            className="side-nav"
                            style={{
                              ...defaultStyle,
                              ...transitionStyles[state],
                            }}
                          />
                          <Splitter nav={x.name} />
                        </React.Fragment>
                      )}
                    </Transition>
                  </div>
                );
              default:
                return null;
            }
          });

          return nav ? nav : null;
        }}
      </Query>
    );
  }
}

const duration = 300;

const defaultStyle = {
  transition: `transform ${duration}ms ease-in`,
  willChange: `transform`,
  transform: `translateX(-100%)`,
  visibility: `visible`,
};

const transitionStyles = {
  entered: {transform: `translateX(0%)`},
  exiting: {transform: `translateX(-100%)`},
};

export default React.memo(withRouter(withApollo(SideNavs)));
