/** @format */

import React, { useState } from 'react';
import { GROUPS } from '../../../../apollo/query/Group';
import { useQuery } from '@apollo/client';
import { ALL_ASSETS_MEM_ID } from '../../../../apollo/query/AssetQuery';
import AssetCard from '../AssetCard/AssetCard';


const GroupCard = (group, key, handleOpen, active) => {
  return (
    <li className="group-card" key={key + group.name}>
      <div className={`group-card__header-container ${active === true ? ' group-card__header-container--trigged' : ''}`} onClick={() => handleOpen(key)}>
        <div className="group-card__name">
          {group.name}
        </div>
      </div>
    </li>
  );
};

let Group = ({ cards }) => {
  const { data, loading } = useQuery(GROUPS);
  const res = useQuery(ALL_ASSETS_MEM_ID);

  const [openedGroup, setOpen] = useState(-1);

  const handleOpen = (index) => {
    if (openedGroup === index) {
      setOpen(-1);
    } else {
      setOpen(index);
    }
  }


  if (loading) return null;

  if (data) {
    let groups = [...data.groups];

    const assetsWithoutGroup = res.data.assetsInMem.filter((value) => {
      for (const element of data.groups) {
        if (element.assets.indexOf(value.id) > -1) {
          return false;
        }
      }
      return true;
    });



    groups.push({ name: 'nezařazeno', assets: assetsWithoutGroup.map(x => x.id) });


    let inGroup = groups.map((x, key) => {
      let activeCards = [];
      let allCards = [GroupCard(x, key, handleOpen, openedGroup === key)];
      if (key === openedGroup) {
        activeCards = res.data.assetsInMem.filter((value) => x.assets.indexOf(value.id) !== -1);
      }
      allCards.push(activeCards.map(x => <AssetCard key={x.id} assetId={x.id} />));
      return allCards;
    });
    return inGroup;
  }

  return null;
};

export default Group;
