/** @format */

import React from 'react';
import { SpeedometerIcon, RoadIcon, FinishIcon, StartFlag } from '../../../../components/Icons';
import LocalCafe from '@material-ui/icons/LocalCafe';
import LocalGasStation from '@material-ui/icons/LocalGasStation';
import AccessTime from '@material-ui/icons/AccessTime';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { SIDE_NAVS } from '../../../../apollo/query/SideNav';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TripTypeSelector from './TripTypeSelector';
import { HISTORY_POLYLINES_SETTING } from '../../../../apollo/query/AssetQuery';
import { Query } from '@apollo/client/react/components';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import 'moment/locale/cs';
import moment from 'moment';

moment.locale('cs');

const styles = {
  root: {
    padding: '12px 5px 12px 0px',
    width: '10px',
    height: '10px',
    color: 'white',
    margin: '0px',
  },
  checkbox: {
    padding: '5px 10px 5px 10px',
    width: '10px',
    height: '10px',
    color: 'white',
    margin: '0px 10px 0px 0px',
  },
};

class TripRow extends React.Component {
  //action should be used because of asynchronymouse (state is beeing changed before the fetch is done)
  async handleClickOnRow(e, assetId, startUtime, stopUtime, action) {
    e.preventDefault();

    this.props.client.query({ query: HISTORY_POLYLINES_SETTING }).then(({ data }) => {
      const newData = { ...data };

      //disable this polyline if it is going to be disabled (state active if going to be false)
      newData.historyPolylinesSetting = data.historyPolylinesSetting.filter(
        x => !(x.startUtime === parseInt(startUtime) && action === false)
      );

      //push new polyline into the store
      if (action === true) {
        newData.historyPolylinesSetting.push({
          __typename: 'HistoryPolylinesSetting',
          startUtime: parseInt(startUtime),
          stopUtime: parseInt(stopUtime),
          active: true,
          assetId: assetId,
        });
      }

      this.props.client.writeQuery({
        query: HISTORY_POLYLINES_SETTING,
        data: newData,
      });
    });

    //on mobile hide all navs
    if (document.documentElement.clientWidth < 600) {
      await this.props.client.query({ query: SIDE_NAVS }).then(async ({ data }) => {
        if (data && data.sideNavs) {
          let newState = data.sideNavs.map(x => {
            let newItem = { ...x };

            newItem.visible = false;

            return newItem;
          });

          //write to store
          await this.props.client.writeQuery({
            query: SIDE_NAVS,
            data: { sideNavs: newState },
          });

          this.props.history.push(`/`);
          return null;
        }
      });
    }

    return null;
  }

  render() {
    const tripSeconds = this.props.x.stopUtime - this.props.x.startUtime;
    let tripMins = Math.floor(tripSeconds / 60);
    let tripHours = '';

    if (tripMins % 60 > 0) {
      tripHours = Math.floor(tripMins / 60);
      tripMins -= tripHours * 60;
    }

    const tripTotalTime = (tripHours !== '' && tripHours !== 0 ? `${tripHours}h ` : '') + tripMins + 'min';

    const momentStart = moment.unix(this.props.x.startUtime);
    const momentStop = moment.unix(this.props.x.stopUtime);

    return (
      <Query query={HISTORY_POLYLINES_SETTING}>
        {({ data }) => {
          let active = false;
          if (data && data.historyPolylinesSetting && data.historyPolylinesSetting.length > 0) {
            data.historyPolylinesSetting.map(x => {
              if (
                x.startUtime === parseInt(this.props.x.startUtime) &&
                x.assetId === this.props.assetId &&
                x.stopUtime === parseInt(this.props.x.stopUtime)
              ) {
                active = true;
              }
              return null;
            });
          }
          let driverName = 'řidič nepřiřazen';
          let index = this.props.assetDrivers.findIndex(x => x.id === this.props.x.driver);
          if (index > -1) {
            driverName = this.props.assetDrivers[index].name;
          }

          return (
            <div
              className={'day-body__row' + (active ? ' day-body__row--active' : '')}
              onClick={e =>
                this.handleClickOnRow(e, this.props.assetId, this.props.x.startUtime, this.props.x.stopUtime, !active)
              }>
              <div className="address">
                <div className="address__start-stop">
                  <div className="number">{this.props.index}</div>
                  <div className="address__start-stop--header start">
                    <StartFlag />

                    <span className="bold">{momentStart.format('HH:mm')}</span>
                    <span className="normal">Začátek</span>
                  </div>
                  <p>{this.props.x.startLoc}</p>
                </div>
                <div className="address__start-stop">
                  <div className="address__start-stop--header stop">
                    <FinishIcon />
                    <span className="bold">{momentStop.format('HH:mm')}</span>
                    <span className="normal">Konec</span>
                  </div>
                  <p>{this.props.x.stopLoc}</p>


                  {this.props.width > 530 && (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <FormControlLabel
                        onClick={e =>
                          this.props.handleCheckTripRow({
                            e,
                            arrayStartStopUtime: new Array({
                              startUtime: this.props.x.startUtime,
                              stopUtime: this.props.x.stopUtime,
                            }),
                          })
                        }
                        control={<Checkbox checked={this.props.isChecked} className={this.props.classes.checkbox} />}
                        className={this.props.classes.root}
                        label="zaškrttnout"
                      />
                      <div>
                        {driverName}
                      </div>
                      <div>
                        {driverName}
                      </div>
                    </div>
                  )}


                </div>
              </div>
              <div className="info-tile-container">
                <div className="info-tile-container__tile">
                  <div className="header">
                    <RoadIcon />
                    <span>Vzdálenost</span>
                  </div>{' '}
                  {Number(this.props.x.distance / 1000).toFixed(1)} km
                </div>

                <div className="info-tile-container__tile">
                  <div className="header">
                    <SpeedometerIcon />
                    <span>Max. rychlost</span>
                  </div>
                  {this.props.x.maxSpeed} km/h
                </div>

                <div className="info-tile-container__tile">
                  <div className="header">
                    <LocalCafe />
                    <span>Prostoj</span>
                  </div>{' '}
                  {Number(this.props.x.idle / 60).toFixed(0)} min
                </div>

                <div className="info-tile-container__tile">
                  <div className="header">
                    <LocalGasStation />
                    <span>Spotřeba</span>
                  </div>
                  <div className="consumRow">
                    {this.props.x.consuption} l <span className="units">/ 100km</span>
                  </div>
                </div>

                <div className="info-tile-container__tile">
                  <div className="header">
                    <RoadIcon />
                    <span>Typ jízdy</span>
                  </div>
                  <TripTypeSelector
                    default={this.props.x.tripType}
                    assetId={this.props.assetId}
                    startUtime={this.props.x.startUtime}
                    stopUtime={this.props.x.stopUtime}
                    lastStartUtime={this.props.lastStartUtime}
                    lastStopUtime={this.props.lastStopUtime}
                    allTrips={this.props.allTrips}
                  />
                </div>

                <div className="info-tile-container__tile">
                  <div className="header">
                    <AccessTime />
                    <span>Celková doba</span>
                  </div>{' '}
                  {tripTotalTime}
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

TripRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(withApollo(TripRow)));
