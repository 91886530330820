/**
 * /*eslint-disable
 *
 * @format
 */

import React from 'react';
import {ResponsiveLine} from '@nivo/line';
import last from 'lodash/last';
import 'moment/locale/cs';
import moment from 'moment';

moment.locale('cs');
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const MyResponsiveLine = ({data /* see data tab */}) => {
  if (!data || !data.ecoData) return <p>Načítám</p>;
  if (data.ecoData.length < 1)
    return <p>Akceleraci nelze vizualizovat, za toto období nejsou k dispozici žádná data.</p>;

  let lastUtime = 0;
  let dataFiltered = data.ecoData.filter(x => {
    let isOk = x.utime - lastUtime > 0;
    if (isOk) lastUtime = x.utime;
    return isOk;
  });

  const dataPrep = dataFiltered.map(x => {
    return {
      //format("YYYY-MM-DDTHH:mm")
      x: moment.unix(x.utime).toDate(),
      y: Math.abs(x.x) + Math.abs(x.y) + Math.abs(x.z) - 1000,
    };
  });

  console.log(dataPrep);

  /* const dataPrepHum = dataFiltered.map(x => {
        
        return {
            "x": moment.unix(x.utime).format("HH:mm"),
            "y": x.humidity
        }
    });*/

  const dataTest = [
    {
      id: 'Akcelerace osa X',
      color: 'hsl(25, 70%, 50%)',
      data: dataPrep,
    },
  ];

  return (
    <ResponsiveLine
      data={dataTest}
      margin={{top: 50, right: 110, bottom: 50, left: 60}}
      xScale={{type: 'time', format: 'native'}}
      yScale={{type: 'linear', stacked: true, min: 'auto', max: 'auto'}}
      axisBottom={{
        format: '%H:%M',
        tickValues: 'every 4 hours',
        legend: `${moment(dataPrep[0].x).format('DD-MM-YYYY HH:mm')} až ${moment(last(dataPrep).x).format(
          'DD-MM-YYYY HH:mm'
        )}`,
        legendPosition: 'middle',
        legendOffset: 46,
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Akcelerace [mG]',
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      xFormat={val => {
        return moment(val).format('DD.MM.YYYY HH:mm');
      }}
      yFormat={val => {
        return `${val} mG`;
      }}
      animate={false}
      isInteractive={true}
      colors={{scheme: 'nivo'}}
      lineWidth={6}
      pointSize={5}
      pointLabel="y"
      pointColor={{theme: 'background'}}
      pointBorderWidth={2}
      pointBorderColor={{from: 'serieColor'}}
      useMesh={true}
    />
  );
};

export default MyResponsiveLine;
