/** @format */

import {gql} from '@apollo/client';

const ALL_ALARMS = gql`
  query ALL_ALARMS($assetId: String!) {
    getAlarms(assetId: $assetId) {
      id
      assetId
      isArmed
      hasArmedDoors
      hasArmedSignalLost
      hasArmedMotion
      lastLatLng {
        lat
        lng
      }
      smsNumber
      isSmsEnabled
      email
      isEmailEnabled
      created
      trigged
      __typename
    }
  }
`;

export {ALL_ALARMS};
