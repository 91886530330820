/** @format */

import {gql} from '@apollo/client';

const ASSET_MUTATION = gql`
  mutation ASSET_MUTATION($asset: AssetInput!) {
    mutateAsset(asset: $asset) {
      id
      name
      sticker
      totalKm
      motohours
    }
  }
`;

export {ASSET_MUTATION};
