/** @format */

import React from 'react';
import {MODAL_EVENT} from '../../apollo/query/ModalEvent';
//import FullScreenModal from './FullScreenModal/FullScreenModal';
import GeneralSnackbar from './GeneralSnackbar';
import {Query} from '@apollo/client/react/components';

class DialogsDriver extends React.Component {
  //!This component is exclusively handled only by Apollo Link State!

  state = {
    modal: null,
    snackBar: {
      message: '',
      variant: null, //success, error, info, warning
    },
  };

  handleDialogUnmount() {
    this.setState({modal: null});
  }

  render() {
    /* in store are stored
        type
        title
        text
        */
    //type can be [fullScreen, smallError, smallSuccess, smallWarning, smallInfo]

    //example of full screen modal
    /*this.props.client.writeQuery({
                    query:MODAL_EVENT, data: { 
                        modalsDriver: {
                            id: 0,
                            type: 'fullScreen', 
                            title: 'Není vybrána žádná jednotka',
                            text: 'Pro zobrazení historie klikněte alespoň na jednu kartu v menu, nebo na ikonu na mapě.', 
                            __typename: 'ModalEvent'
                        }
                    }
                });*/
    return (
      <Query query={MODAL_EVENT}>
        {({data}) => {
          if (data && data.modalsDriver && data.modalsDriver.text) {
            let {modalsDriver} = data;

            if (modalsDriver.type === 'fullScreen') {
              //return <FullScreenModal key={modalsDriver.rand} title={modalsDriver.title} text={modalsDriver.text} handleUnmount={this.handleDialogUnmount.bind(this)} />;
            } else if (modalsDriver.type === 'smallError') {
              return <GeneralSnackbar key={modalsDriver.rand} message={modalsDriver.text} variant="error" />;
            } else if (modalsDriver.type === 'smallWarning') {
              return <GeneralSnackbar key={modalsDriver.rand} message={modalsDriver.text} variant="warning" />;
            } else if (modalsDriver.type === 'smallSuccess') {
              return <GeneralSnackbar key={modalsDriver.rand} message={modalsDriver.text} variant="success" />;
            }
          }

          return null;
        }}
      </Query>
    );
  }
}

export default DialogsDriver;
