/** @format */

import React from 'react';
import MaterialTable from 'material-table';
import PlaceDialog from './PlaceDialog';
import {GET_LOCATIONS_WORKING_HOURS, MUTATE_LOCATION} from '../../../../apollo/query/LocationQuery';
import {useQuery} from '@apollo/client';
import client from '../../../../apollo/client';
import {useMutation} from '@apollo/client';

export default function PlacesTable(props) {
  const {data, loading} = useQuery(GET_LOCATIONS_WORKING_HOURS, {client});
  const [mutateLocation] = useMutation(MUTATE_LOCATION, {client});

  let columns = [{title: 'Název místa', field: 'name'}];

  let tableData = [];

  if (data && data.getLocations) {
    data.getLocations.forEach(x => {
      if (x.workHoursExcludedDrivers && x.workHoursExcludedDrivers.find(y => y === props.driver.id)) {
        tableData.push({name: x.name, _id: x._id, workHoursExcludedDrivers: x.workHoursExcludedDrivers});
      }
    });
  }

  if (loading) {
    tableData.push({name: 'Načítám data...', _id: 'Načítám data...'});
  }

  const handleDelete = (oldData, callback) => {
    let newData = {};
    newData._id = oldData._id;
    newData.workHoursExcludedDrivers = oldData.workHoursExcludedDrivers.filter(x => x !== props.driver.id);

    console.log('newData', newData);
    mutateLocation({
      variables: {locationInput: newData},
      refetchQueries: [{query: GET_LOCATIONS_WORKING_HOURS}],
    }).then(() => {
      // close and clean
      callback();
      //props.unmountCallback();
    });
  };

  const [placeDialogOpen, openPlaceDialog] = React.useState(false);

  const localization = {
    pagination: {
      labelDisplayedRows: '{from}-{to} z {count}',
      labelRowsSelect: 'řádků',
      labelRowsPerPage: 'Řádků na stránku',
    },
    toolbar: {
      nRowsSelected: '{0} řádků vybráno',
      searchTooltip: 'Hledat',
      searchPlaceholder: 'Hledat',
    },
    header: {
      actions: 'Akce',
    },
    body: {
      emptyDataSourceMessage: 'Žádné záznamy',
      filterRow: {
        filterTooltip: 'Filtr',
      },
      editTooltip: 'Editovat',
      editRow: {
        deleteText: 'Opravdu chcete tento řádek smazat?',
      },
      addTooltip: 'Přidat',
      deleteTooltip: 'Smazat',
    },
  };

  return placeDialogOpen ? (
    <PlaceDialog open={placeDialogOpen} onClose={() => openPlaceDialog(false)} driver={props.driver} />
  ) : (
    <MaterialTable
      title="Místa vyloučená z cestovních náhrad"
      columns={columns}
      data={tableData}
      localization={localization}
      actions={[
        {
          icon: 'add',
          tooltip: 'Přidat místo',
          isFreeAction: true,
          onClick: () => openPlaceDialog(true),
        },
      ]}
      editable={
        loading
          ? {}
          : {
              onRowDelete: oldData =>
                new Promise(resolve => {
                  handleDelete(oldData, resolve);
                }),
            }
      }
      options={{
        rowStyle: {
          paddingLeft: '24px',
        },
        headerStyle: {
          paddingLeft: '24px',
        },
      }}
    />
  );
}
