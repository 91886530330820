/** @format */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {MuiPickersUtilsProvider, KeyboardTimePicker} from '@material-ui/pickers';
import 'moment/locale/cs';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {DRIVER_MUTATION} from '../../../../apollo/mutation/DriverMutation';
import client from '../../../../apollo/client';
import {useMutation} from '@apollo/client';
import {ASSET_DRIVERS} from '../../../../apollo/query/AssetDriverQuery';
import FormGroup from '@material-ui/core/FormGroup';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

moment.locale('cs');

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    margin: theme.spacing(2),
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  datePicker: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  pointer: {
    cursor: 'pointer',
    marginRight: theme.spacing(1),
    minHeight: '50px',
    verticalAlign: 'middle',
    lineHeight: '50px',
    userSelect: 'none',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const WorkingHoursForm = props => {
  let workingHoursJson = props.driver && props.driver.workingHours ? props.driver.workingHours : {};

  let workingHoursParsed;

  try {
    workingHoursParsed = JSON.parse(workingHoursJson);
  } catch {
    workingHoursParsed = {};
  }

  const didMount = React.useRef(false);
  const [evenOdd, setEvenOdd] = React.useState(
    workingHoursParsed && workingHoursParsed.evenOdd ? workingHoursParsed.evenOdd : false
  );
  const classes = useStyles();
  const [oddDayMap, setOddDayMap] = React.useState(
    workingHoursParsed && workingHoursParsed.odd
      ? workingHoursParsed.odd
      : [
          {
            name: 'Pondělí',
            from: '2014-08-18T06:00:00',
            to: '2014-08-18T15:00:00',
            active: true,
          },
          {
            name: 'Úterý',
            from: '2014-08-18T06:00:00',
            to: '2014-08-18T15:00:00',
            active: true,
          },
          {
            name: 'Středa',
            from: '2014-08-18T06:00:00',
            to: '2014-08-18T15:00:00',
            active: true,
          },
          {
            name: 'Čtvrtek',
            from: '2014-08-18T06:00:00',
            to: '2014-08-18T15:00:00',
            active: true,
          },
          {
            name: 'Pátek',
            from: '2014-08-18T06:00:00',
            to: '2014-08-18T15:00:00',
            active: true,
          },
          {
            name: 'Sobota',
            from: '2014-08-18T06:00:00',
            to: '2014-08-18T15:00:00',
            active: false,
          },
          {
            name: 'Neděle',
            from: '2014-08-18T06:00:00',
            to: '2014-08-18T15:00:00',
            active: false,
          },
        ]
  );

  const [evenDayMap, setEvenDayMap] = React.useState(
    workingHoursParsed && workingHoursParsed.even
      ? workingHoursParsed.even
      : [
          {
            name: 'Pondělí',
            from: '2014-08-18T06:00:00',
            to: '2014-08-18T15:00:00',
            active: true,
          },
          {
            name: 'Úterý',
            from: '2014-08-18T06:00:00',
            to: '2014-08-18T15:00:00',
            active: true,
          },
          {
            name: 'Středa',
            from: '2014-08-18T06:00:00',
            to: '2014-08-18T15:00:00',
            active: true,
          },
          {
            name: 'Čtvrtek',
            from: '2014-08-18T06:00:00',
            to: '2014-08-18T15:00:00',
            active: true,
          },
          {
            name: 'Pátek',
            from: '2014-08-18T06:00:00',
            to: '2014-08-18T15:00:00',
            active: true,
          },
          {
            name: 'Sobota',
            from: '2014-08-18T06:00:00',
            to: '2014-08-18T15:00:00',
            active: false,
          },
          {
            name: 'Neděle',
            from: '2014-08-18T06:00:00',
            to: '2014-08-18T15:00:00',
            active: false,
          },
        ]
  );
  const [fixedWorkingHours, setFixedWorkingHours] = React.useState(
    workingHoursParsed && workingHoursParsed.fixedWorkingHours ? workingHoursParsed.fixedWorkingHours : false
  );

  const [doneOpen, setDoneOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);

  const [mutateDriver] = useMutation(DRIVER_MUTATION, {client});

  const handleDoneClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setDoneOpen(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorOpen(false);
  };

  React.useEffect(() => {
    if (didMount.current) {
      let newDriver = {...props.driver};
      delete newDriver.companyId;
      delete newDriver.__typename;

      newDriver.workingHours = JSON.stringify({
        evenOdd: evenOdd,
        even: evenDayMap,
        odd: oddDayMap,
        fixedWorkingHours: fixedWorkingHours,
      });
      newDriver.id ? (newDriver.action = 'update') : (newDriver.action = 'insert');

      mutateDriver({
        variables: {assetDriverInput: newDriver},
        refetchQueries: [{query: ASSET_DRIVERS}],
      }).then(({error}) => {
        if (error) setErrorOpen(true);
        if (!error) setDoneOpen(true);
      });
    } else {
      didMount.current = true;
    }
    // eslint-disable-next-line
  }, [evenDayMap, oddDayMap, fixedWorkingHours, evenOdd]);

  const handleDateChange = ({evenOdd, fromTo, key}) => {
    // function factory
    return function (date) {
      if (evenOdd === 'even') {
        if (fromTo === 'from') {
          let newEven = [...evenDayMap];
          newEven[key].from = date;
          setEvenDayMap(newEven);
        } else if (fromTo === 'to') {
          let newEven = [...evenDayMap];
          newEven[key].to = date;
          setEvenDayMap(newEven);
        }
      } else if (evenOdd === 'odd') {
        if (fromTo === 'from') {
          let newOdd = [...oddDayMap];
          newOdd[key].from = date;
          setEvenDayMap(newOdd);
        } else if (fromTo === 'to') {
          let newOdd = [...oddDayMap];
          newOdd[key].to = date;
          setEvenDayMap(newOdd);
        }
      }
    };
  };

  const handleFixedWorkingHours = checked => {
    // function factory

    setFixedWorkingHours(checked);
  };

  const mutateRowActive = ({key, evenOdd}) => {
    if (evenOdd === 'even') {
      let newEven = [...evenDayMap];
      newEven[key].active = !newEven[key].active;
      setEvenDayMap(newEven);
    } else {
      let newOdd = [...oddDayMap];
      newOdd[key].active = !newOdd[key].active;
      setOddDayMap(newOdd);
    }
    //setSelectedDate(date);
  };

  const handleChangeEvenOdd = event => {
    setEvenOdd(event.target.checked);
  };

  return (
    <div>
      <Dialog fullScreen TransitionComponent={Transition} open={props.open}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" aria-label="Close" onClick={props.handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Pracovní doba
            </Typography>
          </Toolbar>
        </AppBar>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="cs">
          <Grid container>
            <Grid container>
              <Grid item xs={12} sm={8} lg={5}>
                <Card variant="outlined" className={classes.root}>
                  <CardContent>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!fixedWorkingHours}
                            name="variableWorkingHours"
                            onChange={event => handleFixedWorkingHours(!event.target.checked)}
                          />
                        }
                        label="Počítat ráno od první jízdy"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={fixedWorkingHours}
                            name="fixedWorkingHours"
                            onChange={event => handleFixedWorkingHours(event.target.checked)}
                          />
                        }
                        label="Počítat dle zadané pracovní doby"
                      />
                    </FormGroup>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8} lg={5}>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <FormControlLabel
                    control={<Switch checked={evenOdd} onChange={handleChangeEvenOdd} name="checkedA" />}
                    label={evenOdd ? 'Sudý týden' : 'Aktivovat sudý / lichý týden'}
                  />
                  {evenDayMap.map((x, key) => {
                    return (
                      <Grid container key={key} alignItems="center" alignContent="center">
                        <Grid
                          item
                          xs={2}
                          onClick={() => mutateRowActive({key, evenOdd: 'even'})}
                          className={classes.pointer}
                          style={x.active ? {} : {textDecoration: 'line-through'}}>
                          <span title={x.active ? 'vypnout' : 'zapnout'}>{x.name}</span>
                        </Grid>

                        <Grid item xs={4}>
                          <KeyboardTimePicker
                            margin="normal"
                            label="Od"
                            value={new Date(x.from)}
                            onChange={handleDateChange({evenOdd: 'even', fromTo: 'from', key})}
                            ampm={false}
                            KeyboardButtonProps={{
                              'aria-label': 'Od',
                            }}
                            className={classes.datePicker}
                            style={x.active ? {} : {visibility: 'hidden'}}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <KeyboardTimePicker
                            margin="normal"
                            label="Do"
                            value={new Date(x.to)}
                            onChange={handleDateChange({evenOdd: 'even', fromTo: 'to', key})}
                            ampm={false}
                            KeyboardButtonProps={{
                              'aria-label': 'Do',
                            }}
                            className={classes.datePicker}
                            style={x.active ? {} : {visibility: 'hidden'}}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </CardContent>
              </Card>
              {evenOdd ? (
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <Typography>Lichý týden</Typography>
                    {oddDayMap.map((x, key) => {
                      return (
                        <Grid container key={key} alignItems="center" alignContent="center">
                          <Grid
                            item
                            xs={2}
                            onClick={() => mutateRowActive({key, evenOdd: 'odd'})}
                            className={classes.pointer}
                            style={x.active ? {} : {textDecoration: 'line-through'}}>
                            {x.name}
                          </Grid>

                          <Grid item xs={4}>
                            <KeyboardTimePicker
                              margin="normal"
                              label="Od"
                              value={new Date(x.from)}
                              onChange={handleDateChange({evenOdd: 'odd', fromTo: 'from', key})}
                              ampm={false}
                              KeyboardButtonProps={{
                                'aria-label': 'Od',
                              }}
                              className={classes.datePicker}
                              style={x.active ? {} : {visibility: 'hidden'}}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <KeyboardTimePicker
                              margin="normal"
                              label="Do"
                              value={new Date(x.to)}
                              onChange={handleDateChange({evenOdd: 'odd', fromTo: 'to', key})}
                              ampm={false}
                              KeyboardButtonProps={{
                                'aria-label': 'Do',
                              }}
                              className={classes.datePicker}
                              style={x.active ? {} : {visibility: 'hidden'}}
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                  </CardContent>
                </Card>
              ) : null}
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        <Snackbar open={doneOpen} autoHideDuration={3000} onClose={handleDoneClose}>
          <Alert onClose={handleDoneClose} severity="success">
            Uloženo
          </Alert>
        </Snackbar>
        <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleErrorClose}>
          <Alert onClose={handleErrorClose} severity="error">
            Při ukládání nastala chyba
          </Alert>
        </Snackbar>
      </Dialog>
    </div>
  );
};

export default WorkingHoursForm;
