/** @format */

import React from 'react';
import {IconCar} from '../../../../components/Icons';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withApollo} from '@apollo/client/react/hoc';
import {ASSET_MUTATION} from '../../../../apollo/mutation/AssetMutation';
import {ASSET_MEM} from '../../../../apollo/query/AssetQuery';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {withStyles} from '@material-ui/styles';

const styles = theme => ({
  saveButton: {
    padding: '0',
    minWidth: '20px',
    margin: '0',
    height: '2rem',
    marginLeft: '-1px',
  },
  nameInput: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '2rem',
    padding: '0',
  },
  progress: {
    color: 'white',
    width: '15px !important',
    height: '15px !important',
  },
});

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameIsEditing: false,
      isLoading: false,
      nameOriginal: props.assetInMem.name,
      stickerIsEditing: false,
      stickerOriginal: props.assetInMem.sticker,
    };
  }

  handleClickAway() {
    const assetInMem = {
      ...this.props.assetInMem,
      name: this.state.nameOriginal,
      sticker: this.state.stickerOriginal,
    };
    this.props.client.writeQuery({
      query: ASSET_MEM,
      data: {assetInMem},
    });
    this.setState({
      nameIsEditing: false,
      stickerIsEditing: false,
    });
  }

  handleNameEditing() {
    this.setState({
      nameIsEditing: !this.state.nameIsEditing,
    });
  }

  handleStickerEditing() {
    this.setState({
      stickerIsEditing: !this.state.stickerIsEditing,
    });
  }

  handleNameClick(e) {
    e.preventDefault();
    if (this.state.nameIsEditing === false && this.state.stickerIsEditing === false) {
      this.props.handleClick({
        variables: {
          id: this.props.assetInMem.id,
          action: 'trigged',
        },
      });
    }
  }

  handleStickerClick(e) {
    e.preventDefault();
    if (this.state.nameIsEditing === false && this.state.stickerIsEditing === false) {
      this.props.handleClick({
        variables: {
          id: this.props.assetInMem.id,
          action: 'trigged',
        },
      });
    }
  }

  handleNameChange(e) {
    const assetInMem = {...this.props.assetInMem, name: e.target.value};
    this.props.client.writeQuery({
      query: ASSET_MEM,
      data: {assetInMem},
    });
  }

  handleStickerChange(e) {
    const assetInMem = {...this.props.assetInMem, sticker: e.target.value};
    this.props.client.writeQuery({
      query: ASSET_MEM,
      data: {assetInMem},
    });
  }

  handleSaveName() {
    this.setState({
      isLoading: true,
    });
    let that = this;

    this.props.client
      .mutate({
        mutation: ASSET_MUTATION,
        variables: {
          asset: {
            id: this.props.assetInMem.id,
            name: this.props.assetInMem.name,
          },
        },
      })
      .then(() => {
        that.setState({
          nameIsEditing: false,
          isLoading: false,
          nameOriginal: this.props.assetInMem.name,
        });
      });
    //setLoading(true);
  }

  handleSaveSticker() {
    this.setState({
      isLoading: true,
    });
    let that = this;

    this.props.client
      .mutate({
        mutation: ASSET_MUTATION,
        variables: {
          asset: {
            id: this.props.assetInMem.id,
            sticker: this.props.assetInMem.sticker,
          },
        },
      })
      .then(() => {
        that.setState({
          stickerIsEditing: false,
          isLoading: false,
          stickerOriginal: this.props.assetInMem.sticker,
        });
      });
    //setLoading(true);
  }

  render() {
    const {classes} = this.props;

    // select the color of ignition indicator
    let playOrNot =
      this.props.assetInMem.ignition === true ? (
        <span className="asset-card__icon-play" title="v pohybu"></span>
      ) : (
        <span className="asset-card__icon-stop" title="není v pohybu"></span>
      );

    if (this.props.assetInMem.lastUtime < new Date().getTime() / 1000 - 1800) {
      playOrNot = <span className="asset-card__icon-dead" title="nedostupné"></span>;
    }

    return (
      <div
        className={
          'asset-card__header-container' + (this.props.trigged === true ? ' asset-card__header-container--trigged' : '')
        }>
        <div className="asset-card--iconContainer" onClick={this.handleNameClick.bind(this)}>
          <IconCar
            iconType={this.props.assetInMem.marker}
            iconColor={this.props.assetInMem.color}
            className="asset-icon asset-icon--medium asset-card--icon"
          />
        </div>
        <div className="asset-card__name" onClick={this.handleNameClick.bind(this)}>
          <div style={{cursor: 'text'}} onDoubleClick={this.handleNameEditing.bind(this)}>
            {this.state.nameIsEditing ? (
              <ClickAwayListener onClickAway={this.handleClickAway.bind(this)}>
                <div className={classes.nameInput}>
                  <input
                    type="text"
                    value={this.props.assetInMem.name}
                    margin="normal"
                    id="input-editing"
                    onChange={this.handleNameChange.bind(this)}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        this.handleSaveName();
                      }
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.saveButton}
                    onClick={this.handleSaveName.bind(this)}>
                    {this.state.isLoading ? <CircularProgress className={classes.progress} /> : <CheckIcon />}
                  </Button>
                </div>
              </ClickAwayListener>
            ) : (
              this.props.assetInMem.name
            )}
          </div>
          <div
            className="asset-card__sticker"
            style={{cursor: 'text'}}
            onDoubleClick={this.handleStickerEditing.bind(this)}>
            {this.state.stickerIsEditing ? (
              <ClickAwayListener onClickAway={this.handleClickAway.bind(this)}>
                <div className={classes.nameInput}>
                  <input
                    type="text"
                    value={this.props.assetInMem.sticker}
                    margin="normal"
                    id="input-editing"
                    onChange={this.handleStickerChange.bind(this)}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        this.handleSaveName();
                      }
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.saveButton}
                    onClick={this.handleSaveSticker.bind(this)}>
                    {this.state.isLoading ? <CircularProgress className={classes.progress} /> : <CheckIcon />}
                  </Button>
                </div>
              </ClickAwayListener>
            ) : (
              this.props.assetInMem.sticker
            )}
          </div>
        </div>
        <div className="asset-card__info-box" onClick={this.handleNameClick.bind(this)}>
          <div className="asset-card__info-box-row"></div>
          <div className="asset-card__info-box-row">
            {playOrNot}
            <p>{this.props.assetInMem.speed >= 0 ? this.props.assetInMem.speed + ' km/h' : ''}</p>
          </div>
        </div>
        <div
          className={
            'switch toggler right' + (this.props.trigged === true ? ' asset-card__header-toggler--trigged' : '')
          }>
          <label>
            <input
              checked={this.props.markerVisible}
              type="checkbox"
              onChange={() =>
                this.props.handleClick({
                  variables: {
                    id: this.props.assetInMem.id,
                    action: 'checkBoxClicked',
                  },
                })
              }
            />
            <span className="lever"></span>
          </label>
        </div>
      </div>
    );
  }
}

export default withApollo(withStyles(styles)(Header));
