/** @format */

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

const styles = theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
});

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class DriverExports extends React.Component {
  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        <TabPanel>
          <Typography variant="subtitle1" component="h3">
            Export řidičů
          </Typography>
        </TabPanel>
      </div>
    );
  }
}

export default withStyles(styles)(DriverExports);
