/** @format */

import React from 'react';
import DatePickers from './DatePickers';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withApollo} from '@apollo/client/react/hoc';
import Fade from '@material-ui/core/Fade';
import {ASSET_MEM} from '../../../../apollo/query/AssetQuery';
import axios from 'axios';
import writeOpen from '../../../../filesystem/writeOpen';
import 'moment/locale/cs';
import moment from 'moment';

moment.locale('cs');

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');

var now = moment();
var yesterday = moment.unix(now.unix() - 86400);
var sevenDays = moment.unix(now.unix() - 604800);

let momentsArr = [
  {
    text: 'Dnes',
    startmom: now.format('YYYY-MM-DD') + 'T00:00',
    stopmom: now.format('YYYY-MM-DD') + 'T23:59',
  },
  {
    text: 'Včera',
    startmom: yesterday.format('YYYY-MM-DD') + 'T00:00',
    stopmom: yesterday.format('YYYY-MM-DD') + 'T23:59',
  },
  {
    text: '7 dní',
    startmom: sevenDays.format('YYYY-MM-DD') + 'T00:00',
    stopmom: now.format('YYYY-MM-DD') + 'T23:59',
  },
  {text: 'Vybrat datum', startmom: 0, stopmom: 0},
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

class DateForm extends React.Component {
  //!!!it is index of time from momentsArr NO index of ASSET!!!
  state = {
    selectedIndex: 0,
    start: now.format('YYYY-MM-DD') + 'T00:00',
    stop: now.format('YYYY-MM-DD') + 'T23:59',
    exportStart: now.format('YYYY-MM-DD') + 'T00:00',
    exportStop: now.format('YYYY-MM-DD') + 'T23:59',
    exportModal: false,
    exportType: 'PDF',
    exportLoading: false,
  };

  handleExportButton = () => {
    this.setState({exportModal: !this.state.exportModal});
  };

  handleFormChangeViaMainButtons(index) {
    // handle button today, yesterday, 7 days and do callback to logbook

    // choose date
    if (index === 3) {
      // reset date picker
      this.setState({
        start: now.format('YYYY-MM-DD') + 'T00:00',
        stop: now.format('YYYY-MM-DD') + 'T23:59',
        exportStart: now.format('YYYY-MM-DD') + 'T00:00',
        exportStop: now.format('YYYY-MM-DD') + 'T23:59',
      });
      return null;
    }

    momentsArr = [
      {
        text: 'Dnes',
        startmom: now.format('YYYY-MM-DD') + 'T00:00',
        stopmom: now.format('YYYY-MM-DD') + 'T23:59',
      },
      {
        text: 'Včera',
        startmom: yesterday.format('YYYY-MM-DD') + 'T00:00',
        stopmom: yesterday.format('YYYY-MM-DD') + 'T23:59',
      },
      {
        text: '7 dní',
        startmom: sevenDays.format('YYYY-MM-DD') + 'T00:00',
        stopmom: now.format('YYYY-MM-DD') + 'T23:59',
      },
      {text: 'Vybrat datum', startmom: 0, stopmom: 0},
    ];

    this.props.handleDate(moment(momentsArr[index].startmom).unix(), moment(momentsArr[index].stopmom).unix());

    this.setState({
      start: momentsArr[index].startmom,
      stop: momentsArr[index].stopmom,
      exportStart: momentsArr[index].startmom,
      exportStop: momentsArr[index].stopmom,
    });
  }

  handleButtonClick(selectedIndex) {
    this.handleFormChangeViaMainButtons(selectedIndex);
    this.setState({selectedIndex});
  }

  componentDidMount() {
    //set initial date
    const startMoments = moment(this.state.start).unix();
    const stopMoments = moment(this.state.stop).unix();
    this.props.handleDate(startMoments, stopMoments);
  }

  handleDatePickerClick(event) {
    //handle date change from calendar
    if (event.target.name === 'start') {
      const startMoments = moment(event.target.value).unix();
      const stopMoments = moment(this.state.stop).unix();

      this.props.handleDate(startMoments, stopMoments);
      this.setState({
        start: event.target.value,
        exportStart: event.target.value,
      });
    }

    if (event.target.name === 'stop') {
      const startMoments = moment(this.state.start).unix();
      const stopMoments = moment(event.target.value).unix();

      this.props.handleDate(startMoments, stopMoments);

      this.setState({
        stop: event.target.value,
        exportStop: event.target.value,
      });
    }
  }

  handleDatePickerExportClick(event) {
    //handle date change from calendar
    if (event.target.name === 'start') {
      this.setState({exportStart: event.target.value});
    }

    if (event.target.name === 'stop') {
      this.setState({exportStop: event.target.value});
    }
  }

  handleChangeExportType = event => {
    this.setState({exportType: event.target.value});
  };

  render() {
    const {classes} = this.props;
    const {exportLoading} = this.state;

    return (
      <div className="date-selector-container">
        <div className="header">
          Vyberte datum
          <Button variant="contained" className={classes.button} onClick={this.handleExportButton}>
            Exportovat
          </Button>
          <Dialog
            open={this.state.exportModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleExportButton}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle id="alert-dialog-slide-title">{'Exportovat knihu jízd'}</DialogTitle>
            <DialogContent>
              Od{' '}
              <DatePickers
                name="start"
                defaultValue={this.state.exportStart}
                handleDatePickerClick={this.handleDatePickerExportClick.bind(this)}
              />
              Do{' '}
              <DatePickers
                name="stop"
                defaultValue={this.state.exportStop}
                handleDatePickerClick={this.handleDatePickerExportClick.bind(this)}
              />
              Formát
              <RadioGroup
                aria-label="export"
                name="export"
                className={classes.group}
                value={this.state.exportType}
                onChange={this.handleChangeExportType}
                defaultChecked>
                <FormControlLabel value="PDF" control={<Radio />} label="PDF" labelPlacement="start" />
                <FormControlLabel value="Excel" control={<Radio />} label="Excel" labelPlacement="start" />
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <div className={classes.placeholder}>
                <Fade
                  in={exportLoading}
                  style={{
                    transitionDelay: exportLoading ? '800ms' : '0ms',
                  }}
                  unmountOnExit>
                  <CircularProgress />
                </Fade>
              </div>
              <Button onClick={this.downloadExport} color="primary">
                Exportovat
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        {momentsArr.map((x, key) => (
          <div
            onClick={() => this.handleButtonClick(key)}
            className={'button' + (key === this.state.selectedIndex ? ' button--active' : '')}
            key={key}>
            {x.text}
          </div>
        ))}

        {this.state.selectedIndex === 3 && (
          <div className="date-picker">
            <div className="date-picker__box">
              Od{' '}
              <DatePickers
                name="start"
                defaultValue={this.state.start}
                handleDatePickerClick={this.handleDatePickerClick.bind(this)}
              />
            </div>
            <div className="date-picker__box">
              Do{' '}
              <DatePickers
                name="stop"
                defaultValue={this.state.stop}
                handleDatePickerClick={this.handleDatePickerClick.bind(this)}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  doneCallback() {
    this.setState({exportLoading: false});
  }

  downloadExport = () => {
    this.setState({exportLoading: true});

    this.props.client
      .query({
        query: ASSET_MEM,
        variables: {
          id: this.props.assetId,
        },
      })
      .then(({data}) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
        axios({
          url: 'https://gql.gpson.cz:4000',
          method: 'GET',
          responseType: 'blob',
          params: {
            assetId: this.props.assetId,
            startUtime: moment(this.state.exportStart).unix(),
            stopUtime: moment(this.state.exportStop).unix(),
            format: this.state.exportType,
            name: data.assetInMem.name,
            sticker: data.assetInMem.sticker,
          },
        }).then(response => {
          writeOpen({
            blob: response.data,
            exportType: this.state.exportType,
            exportName: this.state.exportStop,
            fileName: 'kniha_jizd_',
            doneCallback: this.doneCallback.bind(this),
          });
        });
      });
  };
}

DateForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  button: {
    margin: '15px',
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
    flexDirection: 'row',
  },
  formControl: {
    margin: theme.spacing(1) * 3,
  },
  root: {
    display: 'flex',
  },
  placeholder: {
    height: 40,
  },
});

export default withStyles(styles)(withApollo(DateForm));
