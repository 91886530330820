/** @format */

import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import {ASSET_TEMPERATURE} from '../../../apollo/query/AssetQuery';
import writeOpen from '../../../filesystem/writeOpen';
import {ASSET_MEM} from '../../../apollo/query/AssetQuery';
import axios from 'axios';
import Graph from './Graph';
import {withApollo} from '@apollo/client/react/hoc';

import {Query} from '@apollo/client/react/components';
import 'moment/locale/cs';
import moment from 'moment';

moment.locale('cs');

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    width: '100%',
    margin: '0 20px',
    fontSize: '1,2rem',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const Menu = function MenuListComposition(props) {
  const now = moment();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [activeAssetId, setActive] = React.useState(false);
  const [exporting, setExporting] = React.useState(false);

  const [selectedDate, setSelectedDate] = React.useState({
    start: now.format('YYYY-MM-DDT00:00'),
    stop: now.format('YYYY-MM-DDT23:59'),
  });

  const [activeDate, setActiveDate] = React.useState({
    startUtime: moment(now.format('YYYY-MM-DDT00:00')).unix(),
    stopUtime: moment(now.format('YYYY-MM-DDT23:59')).unix(),
  });

  const anchorRef = React.useRef(null);

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event, id) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    if (id && id > 0) {
      //set active asset id
      setActive(id);
    }

    setOpen(false);
  }

  const handleDateChange = e => {
    if (e.target.id === 'dateStart') setSelectedDate({start: e.target.value, stop: selectedDate.stop});
    if (e.target.id === 'dateStop') setSelectedDate({start: selectedDate.start, stop: e.target.value});
    return;
  };

  const handleButtonLoad = () => {
    setActiveDate({
      startUtime: moment(selectedDate.start).unix(),
      stopUtime: moment(selectedDate.stop).unix(),
    });
    return;
  };

  const handleButtonExport = ({assetId, format}) => {
    setActiveDate({
      startUtime: moment(selectedDate.start).unix(),
      stopUtime: moment(selectedDate.stop).unix(),
    });

    setExporting(true);

    props.client
      .query({
        query: ASSET_MEM,
        variables: {
          id: assetId,
        },
      })
      .then(({data}) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
        axios({
          url: 'https://gql.gpson.cz:4000',
          method: 'GET',
          responseType: 'blob',
          params: {
            assetId: assetId,
            exportType: 'temperatureLog',
            startUtime: moment(selectedDate.start).unix(),
            stopUtime: moment(selectedDate.stop).unix(),
            format,
            name: data.assetInMem.name,
            sticker: data.assetInMem.sticker,
          },
        }).then(response => {
          writeOpen({
            blob: response.data,
            exportType: format,
            exportName: selectedDate.stop,
            fileName: 'datalog_temperatures_',
            doneCallback: () => setExporting(false),
          });
        });
      });
    return;
  };

  let filtered = props.data.filter(x => x.modules.search('temperature') !== -1);
  let assetId = filtered[0].id;
  //set default select name
  let buttonName = filtered[0].name;
  let menuItems = filtered.map(x => {
    //rename button, because this asset is in active selection
    if (activeAssetId === x.id) {
      assetId = x.id;
      buttonName = x.name;
    }
    return (
      <MenuItem onClick={event => handleClose(event, x.id)} key={x.id}>
        {x.name}
      </MenuItem>
    );
  });

  return (
    <div className={classes.root}>
      <span style={{paddingLeft: '8px'}}>Výběr: </span>
      <div className="temperatures__menu-button-box">
        <Button
          ref={anchorRef}
          aria-controls="menu-list-grow"
          aria-haspopup="true"
          onClick={handleToggle}
          className="temperatures__menu-button">
          {buttonName}
        </Button>
      </div>
      <Popper
        style={{zIndex: 9, maxHeight: '50%', overflowY: 'auto'}}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal>
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>{menuItems}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <form className={classes.container} noValidate>
        <TextField
          id="dateStart"
          label="Vyberte začátek záznamu"
          type="datetime-local"
          value={selectedDate.start}
          className={classes.textField}
          onChange={handleDateChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="dateStop"
          label="Vyberte konec záznamu"
          type="datetime-local"
          value={selectedDate.stop}
          className={classes.textField}
          onChange={handleDateChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button onClick={handleButtonLoad} variant="contained" color="primary" className={classes.button}>
          načíst
        </Button>
        <Button
          onClick={() => handleButtonExport({assetId, format: 'Excel'})}
          variant="contained"
          color="primary"
          className={classes.button}>
          {exporting ? 'export probíhá' : 'exportovat EXCEL'}
        </Button>
        <Button
          onClick={() => handleButtonExport({assetId, format: 'PDF'})}
          variant="contained"
          color="primary"
          className={classes.button}>
          {exporting ? 'export probíhá' : 'exportovat PDF'}
        </Button>
      </form>
      <div style={{height: '300px', width: '100%'}}>
        <Query
          query={ASSET_TEMPERATURE}
          fetchPolicy="network-only"
          pollInterval={180000}
          variables={{
            assetId: activeAssetId ? activeAssetId : filtered[0].id,
            startUtime: activeDate.startUtime,
            stopUtime: activeDate.stopUtime,
          }}>
          {({data, loading}) => {
            if (activeDate.startUtime >= activeDate.stopUtime) {
              return <span>Zkontrolujte datumy, začátek záznamu je nastaven na dříve než konec.</span>;
            }
            return <Graph data={data} loading={loading} />;
          }}
        </Query>
      </div>
    </div>
  );
};

export default withApollo(Menu);
