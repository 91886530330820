/** @format */

import { gql } from '@apollo/client';

const LOG_BOOK_TRIPS = gql`
  query LOG_BOOK_TRIPS($assetId: String!, $startUtime: Int!, $stopUtime: Int!) {
    logBookTrips(assetId: $assetId, startUtime: $startUtime, stopUtime: $stopUtime) {
      id
      startLoc
      stopLoc
      startUtime
      stopUtime
      maxSpeed
      consuption
      distance
      idle
      tripType
      driver
      __typename
    }
  }
`;

const ASSET_ROUTE = gql`
  query ASSET_ROUTE($assetId: String!, $startUtime: Int!, $stopUtime: Int!) {
    assetRoute(assetId: $assetId, startUtime: $startUtime, stopUtime: $stopUtime) {
      route {
        lat
        lng
        utime
        speed
        ignition
      }
    }
    assetInMem(id: $assetId) @client {
      stopsVisible
    }
  }
`;

const ASSET_ROUTE_UPDATES = gql`
  query ASSET_ROUTE_UPDATES($id: String!) {
    assetInMem(id: $id) @client {
      id
      __typename
      stopsVisible
      latLngGrid {
        id
        __typename
        lat
        lng
        speed
        utime
      }
    }
  }
`;

export { LOG_BOOK_TRIPS, ASSET_ROUTE, ASSET_ROUTE_UPDATES };
