/** @format */

import {gql} from '@apollo/client';

//Write this query to the store to open a new modal via DialogsDriver component

//type can be [fullScreen, smallError, smallWarning, smallInfo]
const MODAL_EVENT = gql`
  query MODAL_EVENT {
    modalsDriver @client {
      id
      type
      title
      text
      rand
      __typename
    }
  }
`;

export {MODAL_EVENT};
