/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import GeoMap from '../../../../components/GeoMap/GeoMap';
import {GET_LOCATIONS, MUTATE_LOCATION} from '../../../../apollo/query/LocationQuery';
import {useMutation} from '@apollo/client';
import {useQuery} from '@apollo/client';
import client from '../../../../apollo/client';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles({
  root: {
    paddingRight: '96px',
  },
});

const PlaceDialog = props => {
  const classes = useStyles();
  const [addEditPlaceOpen, openAddEditPlace] = React.useState(false);
  const {onClose, selectedValue, open} = props;
  const [mutateLocation] = useMutation(MUTATE_LOCATION, {client});
  const {data, loading} = useQuery(GET_LOCATIONS, {client});

  console.log(data);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = x => {
    let locationInput = {...x};
    delete locationInput.companyId;
    delete locationInput.__typename;

    console.log(locationInput.workHoursExcludedDrivers);

    if (locationInput && locationInput.workHoursExcludedDrivers && locationInput.workHoursExcludedDrivers[0] !== null) {
      locationInput.workHoursExcludedDrivers = [...locationInput.workHoursExcludedDrivers];
      if (!locationInput.workHoursExcludedDrivers.find(x => x === props.driver.id)) {
        locationInput.workHoursExcludedDrivers.push(props.driver.id);
      }
    } else {
      locationInput.workHoursExcludedDrivers = [props.driver.id];
    }
    //onClose(value);
    mutateLocation({
      variables: {locationInput},
      refetchQueries: [{query: GET_LOCATIONS}],
    }).then(() => {
      // close and clean
      props.onClose();
      //props.unmountCallback();
    });
  };

  const handleDeleteClick = ({_id}) => {
    mutateLocation({
      variables: {locationInput: {_id}},
      refetchQueries: [{query: GET_LOCATIONS}],
    }).then(() => {
      // close and clean
      //props.unmountCallback();
    });
  };

  return !addEditPlaceOpen ? (
    <Dialog onClose={handleClose} aria-labelledby="vybrat-z-adresare" open={open}>
      <DialogTitle id="vybrat-z-adresare">Vybrat z adresáře</DialogTitle>
      <List>
        {data && data.getLocations
          ? data.getLocations.map(x =>
              x ? (
                <ListItem button onClick={() => handleListItemClick(x)} key={x._id} classes={{root: classes.root}}>
                  <ListItemText primary={x.name} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteClick({_id: x._id})}>
                      <DeleteIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="edit" onClick={() => openAddEditPlace(x)}>
                      <EditIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ) : null
            )
          : null}

        {loading ? (
          <ListItem>
            <ListItemText primary="načítám...." />
          </ListItem>
        ) : null}

        <ListItem autoFocus button onClick={() => openAddEditPlace({})}>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Přidat místo" />
        </ListItem>
      </List>
    </Dialog>
  ) : (
    <GeoMap
      open={addEditPlaceOpen ? true : false}
      edited={addEditPlaceOpen}
      onClose={() => openAddEditPlace(false)}
      unmountCallback={() => openAddEditPlace(false)}
    />
  );
};

PlaceDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default PlaceDialog;
