/** @format */

import {gql} from '@apollo/client';

const MAINTENANCE = gql`
  query MAINTENANCE($assetId: String!) {
    maintenance(assetId: $assetId) {
      id
      name
      taskText
      utimeAlarm
      distanceAlarm
      distanceAlarmTotal
      created
      __typename
    }
  }
`;

export {MAINTENANCE};
