/** @format */

import {gql} from '@apollo/client';

const ALL_HISTORY_LOADING = gql`
  {
    historyButtonLoader @client {
      assetId
      __typename
    }
  }
`;

export {ALL_HISTORY_LOADING};
