/** @format */

import React from 'react';
import {Query} from '@apollo/client/react/components';
import {ASSET_DRIVERS_WITH_WORKING_HOURS} from '../../../apollo/query/AssetDriverQuery';
import DriversList from './DriversList/DriversList';

class DriversBody extends React.Component {
  render() {
    return (
      <Query query={ASSET_DRIVERS_WITH_WORKING_HOURS}>
        {({data}) => {
          if (data && data.getAssetDrivers) {
            return <DriversList drivers={data.getAssetDrivers} />;
          }
          return null;
        }}
      </Query>
    );
  }
}

export default DriversBody;
