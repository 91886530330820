/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TripsExport from './TripsExport';
import DriversExport from './DriversExport';

function TabPanel(props) {
  const {children, value, index, classes, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabBox}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    flexBasis: '25%',
  },
  tabBox: {
    flexBasis: '75%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function ExportsBody() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Exporty"
        className={classes.tabs}>
        <Tab label="Jízdy" {...a11yProps(0)} />
        {/*  <Tab label="Řidiči" {...a11yProps(1)} />
        <Tab label="Item Three" {...a11yProps(2)} />
        <Tab label="Item Four" {...a11yProps(3)} />
        <Tab label="Item Five" {...a11yProps(4)} />
        <Tab label="Item Six" {...a11yProps(5)} />
  <Tab label="Item Seven" {...a11yProps(6)} />*/}
      </Tabs>
      <TabPanel value={value} index={0} classes={classes}>
        <TripsExport />
      </TabPanel>
      <TabPanel value={value} index={1} classes={classes}>
        <DriversExport />
      </TabPanel>
    </div>
  );
}
