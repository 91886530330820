/** @format */

import React, { useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GROUPS, MUTATE_GROUPS } from '../../../../apollo/query/Group';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Button, Typography } from '@material-ui/core';
import AddGroupDialog from './AddGroupDialog';
import { FixedSizeList } from 'react-window';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import cloneDeep from 'lodash/cloneDeep';
import { useMutation } from '@apollo/client';
import GroupTransferList from './GroupTransferList';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    width: '100%',
    '& > *': {
      margin: theme.spacing(1),
    },
    minHeight: 440,
  },
  control: {
    padding: theme.spacing(2),
  },
  fabDelete: {
    margin: theme.spacing(1),
    alignSelf: 'flex-end',
    flexDirection: 'row',
    color: 'white',
  },
  rowFabDelete: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

function renderRow(props) {
  const {
    index,
    style,
    data: { groups, onButtonClick },
  } = props;
  return (
    <ListItem button style={style} key={index} onClick={() => onButtonClick({ rowId: index })}>
      <ListItemText primary={groups[index].name} />
    </ListItem>
  );
}


renderRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
};

const GroupForm = () => {
  const inputEl = useRef(null);
  const { loading, error, data } = useQuery(GROUPS);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [activeGroup, setActiveGroup] = useState(0);
  const [mutateGroup] = useMutation(MUTATE_GROUPS, { refetchQueries: [{ query: GROUPS }] });
  const classes = useStyles();

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = value => {
    setOpenDialog(false);
  };

  const onButtonClick = ({ rowId }) => {
    setActiveGroup(rowId);
    // `current` points to the mounted text input element
    //inputEl.current.scrollToItem(16);
  };

  const handleRemove = () => {

    let newGroups = cloneDeep(data.groups);

    newGroups.splice(activeGroup, 1);

    if (newGroups && newGroups.length > 0) {
      newGroups.forEach(x => { delete x.__typename });
    }

    mutateGroup({ variables: { groupsInput: newGroups } });

  };

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <React.Fragment>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={10} sm={3} lg={2}>
          <Paper className={classes.paper}>
            <Button variant="contained" color="secondary" onClick={handleClickOpenDialog}>
              Přidat skupinu
            </Button>
            <FixedSizeList
              ref={inputEl}
              height={360}
              width={'100%'}
              itemSize={46}
              itemCount={data.groups.length}
              itemData={{ groups: data.groups, onButtonClick }}>
              {renderRow}
            </FixedSizeList>
          </Paper>
        </Grid>
        <Grid item xs={11} sm={8} lg={6}>
          <Paper className={classes.paper}>
            {(data.groups && data.groups.length > 0) ? <React.Fragment><Typography variant="h4" component="h4" className={classes.h4}>
              Skupina {data.groups[activeGroup].name}
            </Typography>
              <GroupTransferList groups={data.groups} activeGroup={activeGroup} />
              <Grid container spacing={2}>
                <Grid item xs={11} sm={11} lg={11}>
                  <div className={classes.rowFabDelete}>
                    <Fab color="secondary" aria-label="smazat" className={classes.fabDelete} onClick={handleRemove}>
                      <DeleteIcon />
                    </Fab>
                  </div>
                </Grid>
              </Grid></React.Fragment> : <Typography variant="h4" component="h4" className={classes.h4} onClick={handleClickOpenDialog}>
              Vytvořte první skupinu
            </Typography>}
          </Paper>
        </Grid>
      </Grid>
      <AddGroupDialog open={openDialog} onClose={handleCloseDialog} groups={data.groups} />
    </React.Fragment>
  );
};

export default GroupForm;
