/** @format */

import React from 'react';
import {withApollo} from '@apollo/client/react/hoc';
import {SIDE_NAVS} from '../../apollo/query/SideNav';

class Splitter extends React.Component {
  constructor(props) {
    super(props);

    this.bindedMouseUp = this.onMouseUp.bind(this);
    this.bindedMouseDown = this.onMouseDown.bind(this);
    this.bindedMouseMove = this.onMouseMove.bind(this);
    this.lastX = 0;
  }

  onMouseDown = event => {
    event.preventDefault();
    window.addEventListener('mouseup', this.bindedMouseUp);
    window.addEventListener('mousemove', this.bindedMouseMove);
  };

  onMouseUp(event) {
    event.preventDefault();
    window.removeEventListener('mouseup', this.bindedMouseUp);
    window.removeEventListener('mousemove', this.bindedMouseMove);
  }

  onMouseMove = event => {
    event.preventDefault();

    if (Math.abs(event.clientX - this.lastX) < 50) {
      //mutate sidenavs
      this.props.client.query({query: SIDE_NAVS}).then(async ({data}) => {
        if (data && data.sideNavs) {
          let newState = data.sideNavs.map(x => {
            let newItem = {...x};
            if (newItem.name === this.props.nav) {
              newItem.width = parseFloat(newItem.width);

              //get 1rem in px
              let rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
              let diff = (event.clientX - this.lastX) / rem;

              newItem.width += diff;

              //finish new width with units
              newItem.width = newItem.width + 'rem';
            }

            return newItem;
          });

          //write to store
          await this.props.client.writeQuery({
            query: SIDE_NAVS,
            data: {sideNavs: newState},
          });
          this.lastX = event.clientX;

          //write setting to local storage
          localStorage.setItem('sideNavs', JSON.stringify(newState));
        }
      });
    } else {
      this.lastX = event.clientX;
    }

    //this.props.handleResizer({ x:event.clientX,y:event.clientY, nav: this.props.name });
  };

  componentDidMount() {
    if (this.node) {
      this.node.addEventListener('mousedown', this.bindedMouseDown);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.bindedMouseUp);
    window.removeEventListener('mousemove', this.bindedMouseMove);
    if (this.node) {
      this.node.removeEventListener('mousedown', this.bindedMouseDown);
    }
  }

  render() {
    return <div ref={node => (this.node = node)} className="splitter" style={this.props.style}></div>;
  }
}

export default withApollo(Splitter);
