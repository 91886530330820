/** @format */

import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TripRow from './TripRow';
import { CarRideIcon } from '../../../../components/Icons';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    backgroundColor: 'rgba(0,0,0,.6)',
    borderRadius: '10rem',
    margin: '.5rem .5rem 0 .5rem',
    boxShadow: 'none',
    transition: 'border-radius .1s',
    transitionDelay: '.5s',
  },
  expanded: {
    minHeight: '46px !important',
    borderRadius: '2.5rem 2.5rem 0 0',
    marginBottom: '0',
    transition: 'none',
  },
  content: {
    margin: '0 !important',
  },
});

const useStylesCheckBox = makeStyles({
  root: {
    padding: '5px',
  },
});

function DayBlock(props) {
  let [checkedAll, setCheckAll] = useState(false);

  const classes = useStyles();
  const classesCheckBox = useStylesCheckBox();

  let numberText;

  if (props.trips.length === 1) {
    numberText = 'záznam';
  } else if (props.trips.length < 5 && props.trips.length > 0) {
    numberText = 'záznamy';
  } else {
    numberText = 'záznamů';
  }

  let drivers = [];
  let dayKm = 0;

  for (let i = 0; i < props.trips.length; i++) {
    let driverName = 'řidič nepřiřazen';
    let index = props.assetDrivers.findIndex(x => x.id === props.trips[i].driver);
    if (index > -1) {
      driverName = props.assetDrivers[index].name;
    }
    let indexExists = drivers.findIndex(x => x === driverName);
    if (indexExists === -1) {
      drivers.push(driverName);
    }

    dayKm = dayKm + Number(props.trips[i].distance);
  }


  const handleCheckAll = e => {
    e.preventDefault();
    e.stopPropagation();

    let arrayTrips = [];
    for (let i = 0; i < props.trips.length; i++) {
      arrayTrips.push({
        startUtime: props.trips[i].startUtime,
        stopUtime: props.trips[i].stopUtime,
      });
    }
    props.handleCheckTripRow({ e, arrayStartStopUtime: arrayTrips, forceState: !checkedAll });

    setCheckAll(!checkedAll);
  };
  // calc current menu width
  let width = parseFloat(getComputedStyle(document.documentElement).fontSize) * props.logBookWidth.replace('rem', '');
  return (
    <div className="day-header--underline day-header--white">
      <Accordion className="day-header day-header--white">
        <AccordionSummary classes={classes} expandIcon={<ExpandMoreIcon className="day-header--white" />}>
          <div className="day-header--white day-header__p">
            <div className="day-header__icon">
              <CarRideIcon />
            </div>
            {width > 530 && (
              <FormControlLabel
                onClick={e => handleCheckAll(e)}
                control={
                  <Checkbox
                    checked={checkedAll}
                    value="secondary"
                    color="primary"
                    inputProps={{ 'aria-label': 'zaškrtnout vše' }}
                  />
                }
                classes={classesCheckBox}
                label="zaškrtnout vše"
              />
            )}
            {width > 630 && (<span className="day-header__drivers" >{drivers.map((x, key) => <div key={key}>{x}</div>)}</span>)}
            {width > 740 && (
              <span className="day-header__date">{Number(dayKm / 1000).toFixed(1)} km</span>
            )}
            <span className="day-header__date">{props.moment.format('DD.MM.YYYY')}</span>
            <div className="day-header__trips-block">
              <div className="day-header__trips-block__count">{props.trips.length}</div>
              <div className="day-header__trips-block__text">{numberText}</div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="day-body">
          {props.trips.map((x, key) => {
            //return trip row component
            return (
              <TripRow
                assetId={props.assetId}
                x={x}
                key={key}
                arrayId={key}
                index={key + 1}
                closeAllOnSmall={props.closeAllOnSmall}
                lastStartUtime={props.lastStartUtime}
                lastStopUtime={props.lastStopUtime}
                allTrips={props.allTrips}
                isChecked={!!props.checkedTripRows[`${x.startUtime}.${x.stopUtime}`]}
                handleCheckTripRow={props.handleCheckTripRow}
                width={width}
                assetDrivers={props.assetDrivers}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default DayBlock;
