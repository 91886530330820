/** @format */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {MAINTENANCE} from '../../../apollo/query/MaintenanceQuery';
import {Query} from '@apollo/client/react/components';
import MaintenanceTabPanel from './MaintenanceTabPanel';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function InnerTable(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [deleted, triggerDeleted] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handletriggerDeleted = deleted => {
    setValue(0);
    triggerDeleted(deleted);
  };

  //const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
  //const [selectedDate, setSelectedDate] = React.useState(null);

  //const [newTaskName, setNewTaskName] = React.useState('Přidat novou');

  //const handleDateChange = date => {
  //setSelectedDate(date);
  //};

  //const handleSetNewTaskName = event => {
  // setNewTaskName(event.target.value);
  //};

  return (
    <Query query={MAINTENANCE} variables={{assetId: props.assetId}} key={deleted} fetchPolicy="no-cache">
      {({loading, data, refetch}) => {
        if (loading) return <div>Načítám....</div>;

        const refetchReset = () => {
          setValue(0);
          refetch();
        };

        let tabsArr = [];
        let tabsPanelArr = [];
        if (data.maintenance && data.maintenance.length > 0) {
          // create element for every task
          data.maintenance.forEach((x, key) => {
            tabsArr.push(<Tab label={x.name} key={x.id} {...a11yProps(key)} />);
            tabsPanelArr.push(
              <MaintenanceTabPanel
                data={x}
                key={x.id}
                index={key}
                value={value}
                assetId={props.assetId}
                refetch={refetchReset}
                tachoTotal={props.tachoTotal}
                triggerDeleted={handletriggerDeleted}
              />
            );
          });
        }

        // push standard add a new task form
        tabsArr.push(<Tab label={'Přidat novou'} key={tabsArr.length} {...a11yProps(tabsArr.length)} />);
        tabsPanelArr.push(
          <MaintenanceTabPanel
            data={tabsPanelArr.length}
            key={tabsPanelArr.length}
            index={tabsPanelArr.length}
            newForm={true}
            value={value}
            assetId={props.assetId}
            tachoTotal={props.tachoTotal}
            refetch={refetchReset}
            isNewTask={true}
          />
        );

        return (
          <div className={classes.root}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs}>
              {tabsArr}
            </Tabs>
            {tabsPanelArr}
          </div>
        );
      }}
    </Query>
  );
}
