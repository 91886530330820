/** @format */

import {gql} from '@apollo/client';

const ALL_ASSETS = gql`
  {
    allAssets {
      __typename
      id
      name
      sticker
      totalKm
      totalKmCanBus
      motohours
      marker
      speed
      ignition
      lastUtime
      address
      externalVoltage
      gsmSignal
      visSat
      rpm
      todayKm
      todayTime
      companyId
      fuelPercent
      latLngGrid {
        id
        __typename
        lat
        lng
        speed
        utime
      }
      modules
      weight
      axleWeight1
      axleWeight2
    }
  }
`;

const ALL_ASSETS_MEM = gql`
  {
    assetsInMem @client {
      id
      name
      sticker
      totalKm
      totalKmCanBus
      motohours
      marker
      speed
      ignition
      lastUtime
      companyId
      address
      externalVoltage
      gsmSignal
      visSat
      rpm
      todayKm
      todayTime
      fuelPercent
      latLngGrid {
        id
        __typename
        lat
        lng
        speed
        utime
      }
      markerVisible
      iconVisible
      stopsVisible
      trigged
      modules
      weight
      axleWeight1
      axleWeight2
      __typename
    }
  }
`;

const ALL_ASSETS_MEM_STATIC = gql`
  {
    assetsInMem @client {
      id
      name
      sticker
      modules
      __typename
    }
  }
`;

const ALL_ASSETS_MEM_LIGHT = gql`
  {
    assetsInMem @client {
      id
      name
      sticker
      modules
      companyId
      __typename
    }
  }
`;

const ALL_ASSETS_TRIGGED = gql`
  {
    assetsInMem @client {
      id
      trigged
      name
      sticker
      marker
      __typename
    }
  }
`;

const ALL_ASSETS_MEM_ID = gql`
  {
    assetsInMem @client {
      id
      markerVisible
      iconVisible
      stopsVisible
      __typename
    }
  }
`;

const ALL_ASSETS_MEM_ONLY_ID = gql`
  {
    assetsInMem @client {
      id
      __typename
    }
  }
`;

const ALL_MODULES = gql`
  {
    assetsInMem @client {
      id
      name
      modules
    }
  }
`;

const ASSET_TEMPERATURE = gql`
  query ASSET_TEMPERATURE($assetId: String!, $startUtime: Int, $stopUtime: Int) {
    temperatures(assetId: $assetId, startUtime: $startUtime, stopUtime: $stopUtime) {
      utime
      temperature
      humidity
      battery
      __typename
    }
  }
`;

const ASSET_VOLTAGE = gql`
  query ASSET_VOLTAGE($assetId: String!, $startUtime: Int, $stopUtime: Int) {
    voltages(assetId: $assetId, startUtime: $startUtime, stopUtime: $stopUtime) {
      utime
      value
      __typename
    }
  }
`;

const ASSET_FUEL = gql`
  query ASSET_FUEL($assetId: String!, $startUtime: Int, $stopUtime: Int) {
    fuels(assetId: $assetId, startUtime: $startUtime, stopUtime: $stopUtime) {
      utime
      value
      __typename
    }
  }
`;

const ASSET_MEM = gql`
  query ASSET_MEM($id: String!) {
    assetInMem(id: $id) @client {
      id
      name
      sticker
      totalKm
      totalKmCanBus
      motohours
      marker
      speed
      ignition
      lastUtime
      address
      externalVoltage
      gsmSignal
      visSat
      rpm
      weight
      axleWeight1
      axleWeight2
      todayKm
      todayTime
      fuelPercent
      latLngGrid {
        id
        __typename
        lat
        lng
        speed
        utime
      }
      markerVisible
      iconVisible
      stopsVisible
      trigged
      modules
      __typename
    }
  }
`;

const ASSET_HISTORY = gql`
  query ASSET_HISTORY($id: String!, $startUtime: String!, $stopUtime: String!) {
    assetHistory(assetId: $id, startUtime: $startUtime, stopUtime: $stopUtime) {
      lat
      lng
      utime
      speed
      ignition
      __typename
    }
  }
`;

const HISTORY_POLYLINES_SETTING = gql`
  query HISTORY_POLYLINES_SETTING {
    historyPolylinesSetting @client {
      startUtime
      stopUtime
      active
      assetId
      __typename
    }
  }
`;

const HISTORY_POLYLINES_SETTING_WITH_TRIGGERED = gql`
  query HISTORY_POLYLINES_SETTING_WITH_TRIGGERED {
    assetsInMem @client {
      id
      trigged
      __typename
    }
    historyPolylinesSetting @client {
      startUtime
      stopUtime
      active
      assetId
      __typename
    }
  }
`;

export {
  ALL_ASSETS_MEM,
  ALL_ASSETS_MEM_STATIC,
  HISTORY_POLYLINES_SETTING,
  ALL_MODULES,
  HISTORY_POLYLINES_SETTING_WITH_TRIGGERED,
  ALL_ASSETS_MEM_ONLY_ID,
  ALL_ASSETS,
  ASSET_MEM,
  ALL_ASSETS_MEM_ID,
  ALL_ASSETS_TRIGGED,
  ASSET_HISTORY,
  ASSET_TEMPERATURE,
  ASSET_VOLTAGE,
  ASSET_FUEL,
  ALL_ASSETS_MEM_LIGHT,
};
