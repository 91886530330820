/** @format */

import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {FixedSizeList} from 'react-window';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
//import DriverForm from '../DriverForm/DriverForm';
import {Typography, Button} from '@material-ui/core';
import {addDays} from 'date-fns';
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {cs} from 'date-fns/locale';
import Export from '../../../../exports/Export';
import {withRouter} from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: 410,
  },
  addDriverItem: {
    margin: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  h4: {
    paddingBottom: theme.spacing(2),
  },
}));

function renderRow(props) {
  const {
    index,
    style,
    data: {drivers, onButtonClick},
  } = props;
  return (
    <ListItem button style={style} key={index} onClick={() => onButtonClick({rowId: index})}>
      <ListItemText primary={drivers[index].name} />
    </ListItem>
  );
}

renderRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
};

const VirtualizedList = props => {
  const inputEl = useRef(null);
  const classes = useStyles();
  const [activeDriver, setActiveDriver] = useState(0);
  const [drivers, setdrivers] = useState([...props.drivers]);

  const [state, setState] = useState({
    range1: {
      startDate: addDays(new Date(), -9),
      endDate: addDays(new Date(), -2),
      key: 'range1',
    },
  });

  const keys = Object.keys(state);
  const stateArray = keys.map(x => state[x]);

  const onButtonClick = ({rowId}) => {
    setActiveDriver(rowId);
    // `current` points to the mounted text input element
    //inputEl.current.scrollToItem(16);
  };

  React.useEffect(() => {
    setdrivers([...props.drivers]);
  }, [props.drivers]);

  const handleGenerateClick = () => {
    props.history.push(`/travel-expenses/export/`);
  };

  const handleExportClose = () => {
    props.history.push(`/travel-expenses/`);
  };

  console.log(props.location.pathname);
  return (
    <div className={classes.root}>
      {props.location.pathname === '/travel-expenses/export/' ? (
        <Export handleClose={handleExportClose} driver={drivers[activeDriver]} range={state.range1} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={10} sm={3} lg={2}>
            <Paper className={classes.paper}>
              <Typography>Řidiči:</Typography>
              <FixedSizeList
                ref={inputEl}
                height={360}
                width={'100%'}
                itemSize={46}
                itemCount={drivers.length}
                itemData={{drivers: drivers, onButtonClick}}
                scrollToItem={10}>
                {renderRow}
              </FixedSizeList>
            </Paper>
          </Grid>
          <Grid item xs={11} sm={7} lg={5}>
            <Paper className={classes.paper}>
              <Typography variant="h4" component="h4" className={classes.h4}>
                Diety řidiče {drivers[activeDriver].name}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={12}>
                  <DateRange locale={cs} onChange={item => setState({...state, ...item})} ranges={stateArray} />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Button variant="contained" color="primary" onClick={handleGenerateClick}>
                    Generovat
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default withRouter(VirtualizedList);
