/** @format */

import Marker from './Marker';
import {ALL_ASSETS_MEM_ONLY_ID} from '../../../apollo/query/AssetQuery';
import L from 'leaflet';

var t0 = performance.now();

class MarkerContainerObject {
  constructor({client, map, fitBounds, history, location}) {
    this.client = client;
    this.map = map;
    this.fitBounds = fitBounds;
    this.history = history;
    this.location = location;

    this.markersLGroup = new L.featureGroup();
    this.markers = [];

    //init markers
    this.init();

    // add markers
    if (this.markersLGroup) {
      this.markersLGroup.addTo(this.map);
    }
  }

  //init markers
  init() {
    let that = this;
    //wait until assets will be loaded into the cache
    this.subscription = this.client
      .watchQuery({
        query: ALL_ASSETS_MEM_ONLY_ID,
      })
      .subscribe(({data}) => {
        // detect if it is init or redraw, if init fire fit bounds
        let fireFitBound = that.markers.length === 0;

        for (let i = 0; i < that.markers.length; i++) {
          that.markers[i].deleteMarker();
        }

        //marker array isn't inicialized yet
        if (that.markers.length === 0 && data.assetsInMem && data.assetsInMem.length > 0) {
          //map markers object into array
          that.markers = [];
          that.markers = data.assetsInMem.map(x => {
            return new Marker({
              id: x.id,
              client: that.client,
              group: this.markersLGroup,
              history: this.history,
              location: this.location,
            });
          });

          let promises = [];
          for (let i = 0; i < that.markers.length; i++) {
            promises.push(that.markers[i].isLoaded());
          }

          //when all markers are loaded, fit map bounds on them!
          Promise.all(promises).then(() => {
            if (fireFitBound === true) {
              this.fitBounds({bounds: this.markersLGroup});
              var t1 = performance.now();
              console.log('write to store took ' + (t1 - t0) + ' milliseconds.');
            }
          });
        }
      });
  }

  unsubscribe() {
    if (this.subscription && this.subscription.unsubscribe) this.subscription.unsubscribe();
    //delete all markers
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].deleteMarker();
    }
  }
}

export default MarkerContainerObject;
