/** @format */

// eslint-disable-next-line
import React, { Component } from 'react';
import AssetMenu from './AssetMenu';
import Body from './Body';
import { ALL_ASSETS_TRIGGED } from '../../../apollo/query/AssetQuery';
import { Query } from '@apollo/client/react/components';
import { SIDE_NAVS } from '../../../apollo/query/SideNav';
import { withApollo } from '@apollo/client/react/hoc';
import { ASSET_DRIVERS } from '../../../apollo/query/AssetDriverQuery';

class LogBook extends Component {
  render() {
    return (
      <Query query={ASSET_DRIVERS}>
        {({ data }) => {
          let assetDrivers = [];
          if (data && data.getAssetDrivers) {
            assetDrivers = [...data.getAssetDrivers];
          }
          return (
            <Query query={SIDE_NAVS}>
              {({ data }) => {
                let navSetting = data.sideNavs.find(x => x.name === 'logBook');
                return (
                  <Query query={ALL_ASSETS_TRIGGED}>
                    {({ error, data }) => {
                      if (error) {
                        console.log(error.message);
                        return `Error!`;
                      }

                      let activeAsset = null;

                      if (data && data.assetsInMem && data.assetsInMem.length > 0) {
                        activeAsset = data.assetsInMem[0].id;
                        data.assetsInMem.map(x => {
                          if (x.trigged === true) {
                            activeAsset = x.id;
                          }
                          return null;
                        });
                      }

                      // return logBook only if some asset has been selected
                      if (activeAsset) {
                        return (
                          <div
                            style={{
                              flexBasis: navSetting.width,
                              minWidth: navSetting.minWidth,
                              ...this.props.style,
                            }}
                            className="side-nav">
                            <div className="log-book__asset-selection">
                              <AssetMenu
                                assets={data.assetsInMem}
                                assetId={activeAsset}
                                className="log-book__asset-selection__button-box"
                              />
                            </div>
                            <Body
                              assetId={activeAsset}
                              assets={data.assetsInMem}
                              assetDrivers={assetDrivers}
                              logBookWidth={navSetting.width}
                              closeAllOnSmall={this.props.closeAllOnSmall}
                            />
                          </div>
                        );
                      }

                      return null;
                    }}
                  </Query>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

export default React.memo(withApollo(LogBook));
