/** @format */

import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TravelExpensesBody from './TravelExpensesBody';

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const TravelExpenses = props => {
  return (
    <div>
      <Dialog fullScreen open={props.location.pathname.includes('/travel-expenses/')} TransitionComponent={Transition}>
        <AppBar className={props.classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={() => props.history.push('/')} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={props.classes.flex}>
              Řidiči
            </Typography>
          </Toolbar>
        </AppBar>
        <TravelExpensesBody />
      </Dialog>
    </div>
  );
};

TravelExpenses.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(TravelExpenses));
