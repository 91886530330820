/** @format */

import React from 'react';
import PDFTravelExpenses from './PDF/PDFTravelExpenses';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const Export = ({classes, handleClose, driver, range}) => {
  console.log('rerender export', driver, range);
  return (
    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
      <Dialog fullScreen open={true} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Export
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
          <PDFTravelExpenses driver={driver} range={range}></PDFTravelExpenses>
        </div>
      </Dialog>
    </div>
  );
};

Export.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(Export);
